import { createApp } from 'vue';
import ViewUIPlus from 'view-ui-plus';
import App from './App.vue';
// import 'view-ui-plus/dist/styles/viewuiplus.css';
import './mytheme/index.less';
import router from './router';
import { createPinia } from 'pinia'
import { VueSignalR } from '@quangdao/vue-signalr';
import configs from '@/service/configs'
import utils from '@/libs/util'
import gtool from './components/index';
import VueUeditorWrap from 'vue-ueditor-wrap';
import Cache from '@/libs/cache';



const app = createApp(App);

app.config.globalProperties.static = process.env.BASE_URL + "/static";

app.use(createPinia())
    .use(ViewUIPlus, {
        table: {
            size: 'small'
        }
    }).use(router)
    .use(VueSignalR, { url: configs.baseurl + 'chathub' })
    .use(gtool)
    .use(VueUeditorWrap)
    .directive('download', {
        inserted: (el, binding) => {
            el.style.cssText = 'cursor: pointer;color:#5cadff;'
            el.addEventListener('click', () => {
                let link = document.createElement('a')
                let _name = el.getAttribute("name");
                let url = binding.value;
                if (typeof binding.value == "object") {
                    _name = binding.value.filename;
                    url = binding.value.fileval;
                }
                else {
                    _name = el.getAttribute("name");
                    url = binding.value;
                }
                // 这里是将url转成blob地址，
                fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
                    link.href = URL.createObjectURL(blob)
                    link.download = _name;
                    document.body.appendChild(link)
                    link.click()
                })
            })
        }
    })
    .mount('#app');




router.beforeEach(async (to, from, next) => {
    const title = (to.meta.title ? to.meta.title : '') + '-工作平台'
    document.title = title;
    // ViewUIPlus.LoadingBar.start();

    if (to.path != from.path) {
        ViewUIPlus.Spin.show();
    }

    next();
})


router.afterEach((to,from) => {
    // ViewUIPlus.LoadingBar.finish();
    //记录面包屑
    if (to.meta.bread == 0 || to.meta.bread == 1) {
        Cache.delete("breadpath");
    }

    if (to.meta.bread == 1 || to.meta.bread == 2) {
        Cache.push("breadpath", {
            path: to.path,
            params: to.params,
            query: to.query,
            meta: to.meta
        });
    }
    if (from.path!="/" || to.path.indexOf("webmodel")>-1) {
        setTimeout(() => {
            ViewUIPlus.Spin.hide();
        }, 500);
    }
});


