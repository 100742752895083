<style lang="scss" scoped>
    @import "./style.scss";
</style>
<template>
     <div class="paywaybox">
        <Divider orientation="left">汇款明细</Divider>
        <Alert type="warning" show-icon>
            <template slot="desc">
                支付方式的选择除了告知"支付方式"外,还会计算出订单每笔应付款项和支付时间。<br/>
                如选项中的内容没能满足个性化需求,可选择自定义编辑支付方式。
            </template>
        </Alert>
        <div v-if="!custompaywaystate">
            <div class="title" style="padding-bottom:10px;">
                <Button  type="text" style="float:right;" v-if="!disabled" @click="custompaywaystate=true">自定义</Button>
            </div><br/>
            <Alert show-icon v-if="paywaydetail.length==0">请选择支付方式</Alert>
            <Alert type="success" show-icon v-for="(d,i) in paywaydetail" v-else :key="'paywaydetail'+i">第{{(i+1)}}笔款项:<b>{{d.price}}元</b>,应在<b>{{d.phaseName+d.beforeOrAfterName}}</b>支付<br>备注:{{d.remark}}</Alert>
            
            <div v-if="paywaysum>0" style="width:100%;text-align:right;padding-right:10px;font-weight:bold;margin-bottom:20px;">共计金额:{{paywaysum}}元</div>
            <div v-if="paywaysum>0 && paywaysum!=price">
                <Alert type="error" show-icon>应汇款总额不等于销售业绩</Alert>
            </div>
            <div v-if="!ismatch">
                <Alert type="error" show-icon>项目类型和汇款规则不匹配</Alert>
            </div>
        </div>
        <div v-else>
            <div v-for="(d,i) in paywaydetail" :key="'payway'+i" class="addbox">
                <div class="closebtn"><Icon type="md-close" size="16" @click="delPaywayPhase(i)"/></div>
                <div style="margin-left:25px;margin-bottom:20px;font-weight:bold;">第{{i+1}}笔款项</div>
           
                <Form :model="d" :label-width="60" ref="formItem2">
                    <FormItem label="阶段" v-if="extendflag==0" style="width:100%;">
                        <Select v-model="d.phase" style="width:100%;">
                            <Option v-for="item in phase1" :value="item.value" :key="item.value">{{ item.label }}</Option>
                        </Select>
                    </FormItem>

                    <FormItem label="阶段" v-if="extendflag==1" style="width:100%;">
                        <Select v-model="d.phase"  style="width:100%;">
                            <Option v-for="item in phase2" :value="item.value" :key="item.value">{{ item.label }}</Option>
                        </Select>
                    </FormItem>

                    <!-- <FormItem label="付款时间">
                        <Select v-model="d.beforeOrAfter" style="width:200px">
                            <Option :value="0" :key="0">之前</Option>
                            <Option :value="1" :key="1">之后</Option>
                        </Select>
                    </FormItem> -->

                    <FormItem label="类型">
                        <RadioGroup v-model="d.flag">
                            <Radio :label="0">百分比</Radio>
                            <Radio :label="1">金额</Radio>
                        </RadioGroup>
                    </FormItem>

                    <FormItem :label="d.flag==0?'百分比':'金额'">
                        <InputNumber v-model="d.num" style="width:90px;"></InputNumber>&nbsp;&nbsp;&nbsp;&nbsp;{{d.flag==0?'%':'元'}}
                    </FormItem>

                    <FormItem label="备注" style="width:100%;">
                        <Input type="textarea" v-model="d.remark" placeholder="请输入"  style="width:100%;"></Input>
                    </FormItem>
                </Form>
            </div>
            <div style="width:100%;height:60px;text-align:right;">
                <Button  @click="custompaywaystate=false">取消</Button>
                <Button type="success" @click="addPaywayPhase">新增回款项</Button>
                <Button type="primary" @click="paywayphaseok">完成自定义</Button>
            </div>
        </div>
        <br class="clear"/>
    </div>
</template>

<script>
// import utils from '@/libs/util';
import { useUserStore } from "@/store/user/user";
import {reactive,toRefs} from "vue";

export default {
  name: 'AutoRemittance',
  setup() {
    const userstore = useUserStore();
    const state = reactive({
      user:userstore.user
    });
    return {
      ...toRefs(state),
      userstore
    }
  },
  props:[
        'paywayId',
        'price',
        'value',
        'disabled',
        'projectTask',
        'designKindId',
        'taskKindId',
        'kind'
  ],
  data () {
    return {
        phase1:[{
            value:"0",
            label:"发货"
        },{
            value:"2",
            label:"签收"
        }],
        phase2:[],
        paywaysum:0,
        orderphaseid:"0",
        payway:"",
        paywaydetail:[],
        custompaywaystate:false,
        extendflag:"",
        ismatch:true,
        sendbeforepay:false,
        expressId:""
    }
  },
//   computed:{
//     ...mapGetters([
//         'result',
//         'user'
//     ])
//   },
  mounted(){
      this.value0 = this.value;
      
        
  },
  methods:{
    // ...mapActions([
    //     'getpaywaydetail',
    //     'getpaywaydetailbydesignkindid',
    //     'addorderphase',
    //     'editorderphase',
    //     'getorderphasedetail',
    //     'getdesigncategory'
    // ]),
    addPaywayPhase(){
        //初始化第一个明细(当前明细为空的时候,得有个填的地方)
        this.paywaydetail.push({
            flag:0,
            num:0,
            remark:"",
            phase:"0",
            beforeOrAfter:0,
            sort:this.paywaydetail.length
        });
    },
    delPaywayPhase(index){
        this.paywaydetail.splice(index,1);
    },
    paywayphaseok(){
        this.custompaywaystate = false;
        //提交自定义汇款项
        this.editorderphase({id:this.orderphaseid,datas:this.paywaydetail}).then(d=>{
            if(d.code==0){
                this.orderphaseid = d.datas;
                this.getorderphasedetail({id:this.orderphaseid,price:this.price}).then(e=>{
                    if(e.code==0){
                        this.paywaydetail = JSON.parse(JSON.stringify(e.datas.list));
                        this.paywaysum =0;
                        this.paywaydetail.forEach(k=>{
                            this.paywaysum=util.plus(this.paywaysum,k.result);
                        });
                        this.changeMatchState();
                    }
                });
            }
        });
    },
    getPayWayDetail(){
        if(this.paywayId){
            this.getpaywaydetail({id:this.paywayId,price:this.price}).then(d=>{
                if(d.code==0 && d.datas!=null && d.datas){
                    this.expressId = d.datas.expressId;
                    if(d.datas.list.length==0){
                        this.$emit("input",{autoremitId:"",price:0,ismatch:false,sendbeforepay:"",expressId:this.expressId});
                    }
                    else{
                        this.paywaydetail = JSON.parse(JSON.stringify(d.datas.list));
                        this.paywaysum =0;
                        this.paywaydetail.forEach(e=>{
                            this.paywaysum=util.plus(this.paywaysum,e.price);
                        });

                        this.sendbeforepay = this.paywaydetail[0].phase==0?true:false;
                        
                        this.extendflag = d.datas.flag;
                        //如果订单的汇款项ID为空,则在获取到通用版后,添加到订单的汇款项Id
                        this.addorderphase(this.paywaydetail).then(e=>{
                            if(e.code==0){
                                this.orderphaseid = e.datas;
                                this.changeMatchState();
                            }
                        });
                    }
                }
            });
        }
        else if(this.designKindId){
            this.getpaywaydetailbydesignkindid({id:this.designKindId,price:this.price,taskId:this.taskKindId}).then(d=>{
                if(d.code==0){
                    if(d.datas!=null){
                        this.paywaydetail = JSON.parse(JSON.stringify(d.datas.list));

                        this.paywaysum =0;
                        this.paywaydetail.forEach(e=>{
                            this.paywaysum=util.plus(this.paywaysum,e.price);
                        });

                        this.sendbeforepay = this.paywaydetail[0].phase==0?true:false;
                        
                        this.extendflag = d.datas.flag;
                        //如果订单的汇款项ID为空,则在获取到通用版后,添加到订单的汇款项Id
                        this.addorderphase(this.paywaydetail).then(e=>{
                            if(e.code==0){
                                this.orderphaseid = e.datas;
                                this.changeMatchState();
                            }
                        });
                    }
                }
            });
            
        }
    },
    changeMatchState(){
        let _len = 0;
        if(this.projectTask){
            this.projectTask.forEach(e=>{
                if(e.selected){
                    _len+=1;
                }
            });
        }
        if(this.paywaydetail.length>0 && this.paywaydetail.length>=_len){
            this.ismatch = true;
        }
        else{
            this.ismatch = false;
        }
        //payway:this.payway,
       this.$emit("input",{autoremitId:this.orderphaseid,price:this.paywaysum,ismatch:this.ismatch,sendbeforepay:this.sendbeforepay,expressId:this.expressId});
    } 
  },
  watch:{
    "paywayId":function(){
        this.getPayWayDetail();
    },
    "price":function(){
        this.getPayWayDetail();
    },
    "projectTask":function(){
        this.changeMatchState();
    },
    "taskKindId":function(){
        this.getPayWayDetail();
    },
    "designKindId":function(){
        this.getPayWayDetail();
    },
    'custompaywaystate':function(){
        this.extendflag = this.$route.path.indexOf("project")>-1?1:0;
        if(this.kind.length>0){
            this.phase2=[];
            this.kind.forEach(e=>{
                this.phase2.push({
                    label:e.name,
                    value:e.kind
                });
            });
        }
        else{
            this.getdesigncategory({page:1,row:20,state:0}).then(d=>{
                this.phase2=[];
                if(d.code==0){
                    d.datas.forEach((e)=>{
                        this.phase2.push({
                            label:e.name,
                            value:e.id
                        });
                    });
                }
            });
        }
    }
  }
}
</script>