<style lang="scss" scoped>

</style>
<template>
    <div>
        <div v-if="hasendtime">
            <Row>
                  <Col span="4">
                    <DatePicker v-model="time.startTime" :type="type" :options="starttimeoptions" placeholder="选择开始日期"  @on-ok="validEndTime" @on-change="changeHandler"></DatePicker>
                  </Col>
                  <Col span="4" style="margin-left:5px;">
                    <DatePicker :type="type" v-model="time.endTime" :options="endtimeoptions"  placeholder="选择结束日期" @on-ok="validEndTime" @on-change="changeHandler"></DatePicker>
                  </Col>
              </Row>
        </div>
        <div v-else>
          <DatePicker v-model="time.startTime" :type="type" @on-change="changeHandler" :options="startoption || starttimeoptions" placeholder="请输入" :style="{width:width}" :disabled="view"></DatePicker>
        </div>
    </div>
</template>

<script>
// import {mapGetters,mapActions} from 'vuex';
import util from '@/libs/util.js';
export default {
  name: 'FormTime',
  props:{
    kind:{
      type:String
    },
    view:{
      type:Boolean,
      default:false
    },
    hasendtime:{
      type:Boolean,
      default:false
    },
    width:{
      type:String,
      default:"380px"
    },
    startoption:{
      type:Object
    },
    init:{
      type:Object,
      default:function(){
        return {startTime:"",endTime:""}
      }
    },
    type:{
      type:String,
      default:"datetime"
    }
  },
  data () {
    return {
        labelwidth:100,
        time:{
          startTime:"",
          endTime:""
        },
        starttimeoptions:{
          disabledDate (date) {
            return date && date.valueOf() < Date.now() - 86400000;
          }
        },
        endtimeoptions:{
            disabledDate (date) {
                return date && date.valueOf() < Date.now()  - 86400000;
            }
        },
        ruleValidate:{
            "startTime": [
                {required: true,type:"date",message: '开始时间不能为空', trigger: 'change' }
            ],
            "endTime": [
                {required: true,type:"date",message: '结束时间不能为空', trigger: 'change' }
            ]
        }
    }
  },
  computed:{
    // ...mapGetters([
    //     'user'
    // ])      
  },
  mounted(){
      this.time.startTime = !this.init.startTime?util.formatObj(util.currentTime()):this.init.startTime;

      this.$emit("input",this.time);
  },
  methods:{
    // ...mapActions([
    // ]),
    changeHandler(){
      let _time = {
        startTime:util.formatTime(this.time.startTime),
        endTime:util.formatTime(this.time.endTime)
      }
      this.$emit("input",_time);
    },
    validEndTime(){
      let _start = this.time.startTime;
      let _end = this.time.endTime;
      if(_start && _end){
          setTimeout(()=>{
              if(_start.getTime()>_end.getTime()){
                  this.time.endTime="";
                  this.$Message.error("结束时间必须大于等于开始时间");
              }
          },500);
      }
    }
  },
  watch:{
    "init":{
      deep:true,
      handler:function(){
        setTimeout(()=>{
            this.time.startTime = util.formatObj(this.init.startTime);
            this.time.endTime = util.formatObj(this.init.endTime);
            this.$emit("input",this.time);
        },200);
      }
    }
  }
}
</script>

