
import { createRouter, createWebHashHistory } from 'vue-router' //, createWebHistory 

const routes = [
    {
        path: '/',
        meta: {
            title: '系统登录'
        },
        component: () => import('./views/login_pro.vue')
    },
    {
        path: '/login',
        meta: {
            title: '系统登录'
        },
        component: () => import('./views/login_pro.vue')
    },
    {
        path: '/autherror',
        meta: {
            title: '未授权'
        },
        component: () => import('./views/autherror.vue')
    },
    {
        path: '/mustchangepwd',
        meta: {
            title: '修改密码'
        },
        component: () => import('./views/mustchangepwd.vue')
    },
    {
        path: '/onlineservicelogin',
        meta: {
            title: '在线咨询登录'
        },
        component: () => import('./views/onlineservicelogin.vue')
    },
    {
        path: '/onlineservice',
        meta: {
            title: '在线客服'
        },
        component: () => import('./views/onlineservice.vue')
    },
    {
        path: '/wxonlineservice',
        meta: {
            title: '微信在线客服'
        },
        component: () => import('./views/wxonlineservice.vue')
    },
    {
        path: '/mychat',
        meta: {
            title: '企业通讯'
        },
        component: () => import('./views/chat.vue'),
        children: [{
            path: 'default',
            meta: {
                title: '企业通讯',
                bread: 0,
                inline: true
            },
            component: () => import('./components/mychatmodel/index.vue')
        }, {
            path: 'scheduling',
            meta: {
                title: '排班',
                bread: 0
            },
            component: () => import('./views/personnel/scheduling.vue')
        }, {
            path: 'dutymanage',
            meta: {
                title: '企业排班',
                bread: 0,
                inline: true
            },
            component: () => import('./views/webmodel/dutymanage.vue')
        }, {
            path: 'networkdisk',
            meta: {
                title: '企业网版',
                bread: 0
            },
            component: () => import('./views/webmodel/networkdisk.vue')
        }, {
            path: 'circlelibrary',
            meta: {
                title: '素材库',
                bread: 0
            },
            component: () => import('./views/webmodel/circlelibrary.vue')
        }]
    },
    // webmodel开始
    {
        path: '/webmodel',
        meta: {
            title: '模态界面',
        },
        component: () => import('./views/webmodel/index.vue'),
        children: [
            {
                path: 'auth',
                component: () => import('./views/webmodel/auth.vue')
            },
            {
                path: 'salecustomer',
                meta: {
                    title: '新增客户',
                    auth: false,
                    header: false,
                    width: 1100,
                    height: 680
                },
                component: () => import('./views/webmodel/salereport/index.vue')
            },
            {
                path: 'salereportcommission',
                meta: {
                    title: '可结算佣金',
                    auth: false,
                    header: false,
                    width: 1000,
                    height: 730
                },
                component: () => import('./views/webmodel/salereport/commission.vue')
            },
            {
                path: 'remitstatslist',
                meta: {
                    title: '财务简报',
                    auth: false,
                    header: false,
                    width: 1200,
                    height: 660
                },
                component: () => import('./views/webmodel/salereport/remitstatslist.vue')
            },
            {
                path: 'circlelibrary',
                meta: {
                    title: '素材库',
                    auth: false,
                    header: false,
                    width: 1200,
                    height: 700
                },
                component: () => import('./views/webmodel/circlelibrary.vue')
            },
            {
                path: 'scheduling',
                meta: {
                    title: '排班管理',
                    auth: false,
                    width: 1150,
                    height: 580
                },
                component: () => import('./views/personnel/scheduling.vue')
            },
            {
                path: 'userlist',
                meta: {
                    title: '用户列表',
                    auth: false,
                    width: 700,
                    height: 510
                },
                component: () => import('./views/webmodel/userlist.vue')
            },
            {
                path: 'companylist',
                meta: {
                    title: '公司列表',
                    auth: false,
                    width: 800,
                    height: 550
                },
                component: () => import('./views/webmodel/companylist.vue')
            },
            {
                path: 'searchlist',
                meta: {
                    title: '搜索列表',
                    auth: false,
                    width: 1000,
                    height: 550
                },
                component: () => import('./views/webmodel/searchlist.vue')
            },
            {
                path: 'orderroute',
                meta: {
                    title: '快递信息',
                    auth: false,
                    width: 400,
                    height: 600
                },
                component: () => import('./views/webmodel/orderroute.vue')
            },
            {
                path: 'expressrefund',
                meta: {
                    title: '订单退款',
                    auth: false,
                    sub: true,
                    width: 400,
                    height: 600
                },
                component: () => import('./views/webmodel/expressrefund.vue')
            },
            {
                path: 'dutymanage',
                meta: {
                    title: '考勤管理',
                    auth: false,
                    header: false,
                    width: 900,
                    height: 720
                },
                component: () => import('./views/webmodel/dutymanage.vue')
            },
            {
                path: 'consultreport',
                meta: {
                    title: '咨询报表',
                    header: false,
                    auth: false,
                    width: 1100,
                    height: 720
                },
                component: () => import('./views/webmodel/bossreport/consultreport.vue')
            },
            {
                path: 'hrreport',
                meta: {
                    title: '人事报表',
                    header: false,
                    auth: false,
                    width: 1100,
                    height: 650
                },
                component: () => import('./views/webmodel/bossreport/hrreport.vue')
            },
            {
                path: 'customerreport',
                meta: {
                    title: '销售报表',
                    auth: false,
                    header: false,
                    width: 1100,
                    height: 750
                },
                component: () => import('./views/webmodel/bossreport/customerreport.vue')
            },
            {
                path: 'storereport',
                meta: {
                    title: '仓储报表',
                    auth: false,
                    width: 780,
                    height: 580
                },
                component: () => import('./views/webmodel/bossreport/storereport.vue')
            },
            {
                path: 'remitreport',
                meta: {
                    title: '财务报表',
                    auth: false,
                    width: 740,
                    height: 580
                },
                component: () => import('./views/webmodel/bossreport/remitreport.vue')
            },
            {
                path: 'designreport',
                meta: {
                    title: '设计报表',
                    auth: false,
                    width: 1100,
                    height: 490
                },
                component: () => import('./views/webmodel/bossreport/designreport.vue')
            },
            {
                path: 'joinreport',
                meta: {
                    title: '求职/入职报表',
                    auth: false,
                    width: 900,
                    height: 750
                },
                component: () => import('./views/webmodel/bossreport/joinreport.vue')
            },
            {
                path: 'leavereport',
                meta: {
                    title: '离职报表',
                    auth: false,
                    width: 900,
                    height: 490
                },
                component: () => import('./views/webmodel/bossreport/leavereport.vue')
            },
            {
                path: 'bankstatement',
                meta: {
                    title: '银行流水',
                    auth: false,
                    width: 800,
                    height: 490
                },
                component: () => import('./views/remittance/bankstatement')
            },
            {
                path: 'spreadreport',
                meta: {
                    title: '运营管理',
                    auth: false,
                    width: 1000,
                    height: 550
                },
                component: () => import('./views/webmodel/bossreport/spreadreport')
            },
            {
                path: 'marketreport',
                meta: {
                    title: '营销管理',
                    auth: false,
                    width: 1100,
                    height: 650
                },
                component: () => import('./views/card/customeruserstatis')
            },
            {
                path: 'sendrule',
                meta: {
                    title: '推送规则',
                    auth: false,
                    width: 1000,
                    height: 650
                },
                component: () => import('./views/baseinfo/sendrule.vue')
            },
            {
                path: 'sendruledetail',
                meta: {
                    title: '客服推送规则',
                    auth: false,
                    width: 1000,
                    height: 660
                },
                component: () => import('./views/baseinfo/sendruledetail.vue')
            },
            {
                path: 'salerank',
                meta: {
                    title: '销售排名',
                    auth: false,
                    header: false,
                    width: 1000,
                    height: 650
                },
                component: () => import('./views/webmodel/ranktbl/salerank.vue')
            },
            {
                path: 'marketrank',
                meta: {
                    title: '运营排名',
                    auth: false,
                    width: 1000,
                    height: 650
                },
                component: () => import('./views/webmodel/ranktbl/marketrank.vue')
            },
            {
                path: 'importantcustomer',
                meta: {
                    title: '重点客户',
                    auth: false,
                    header: false,
                    width: 1000,
                    height: 630
                },
                component: () => import('./views/webmodel/ranktbl/importantcustomer.vue')
            },
            {
                path: 'forgetcustomer',
                meta: {
                    title: '即将遗忘客户',
                    auth: false,
                    header: false,
                    width: 1000,
                    height: 630
                },
                component: () => import('./views/webmodel/ranktbl/forgetcustomer.vue')
            },
            {
                path: 'unvalidcustomer',
                meta: {
                    title: '无效客户',
                    auth: false,
                    header: false,
                    width: 1000,
                    height: 630
                },
                component: () => import('./views/webmodel/ranktbl/unvalidcustomer.vue')
            },
            {
                path: 'consultrank',
                meta: {
                    title: '客服排名',
                    auth: false,
                    header: false,
                    width: 800,
                    height: 670
                },
                component: () => import('./views/webmodel/ranktbl/consultrank.vue')
            },
            {
                path: 'expressrank',
                meta: {
                    title: '快递排名',
                    auth: false,
                    header: false,
                    width: 1000,
                    height: 635
                },
                component: () => import('./views/webmodel/ranktbl/expressrank.vue')
            },
            {
                path: 'storesaleorderrank',
                meta: {
                    title: '发货排名',
                    auth: false,
                    header: false,
                    width: 700,
                    height: 600
                },
                component: () => import('./views/webmodel/ranktbl/storesaleorderrank.vue')
            },
            {
                path: 'designnumrank',
                meta: {
                    title: '设计任务排名',
                    auth: false,
                    header: false,
                    width: 800,
                    height: 670
                },
                component: () => import('./views/webmodel/ranktbl/designnumrank.vue')
            },
            {
                path: 'designdayrank',
                meta: {
                    title: '设计用时排名',
                    auth: false,
                    header: false,
                    width: 800,
                    height: 670
                },
                component: () => import('./views/webmodel/ranktbl/designdayrank.vue')
            },
            {
                path: 'storesaleorder',
                meta: {
                    title: '发货内容',
                    auth: false,
                    header: false,
                    width: 1100,
                    height: 600
                },
                component: () => import('./views/webmodel/ranktbl/storesaleorder.vue')
            },
            {
                path: 'remitstatsrank',
                meta: {
                    title: '汇款排名',
                    auth: false,
                    header: false,
                    width: 880,
                    height: 650
                },
                component: () => import('./views/webmodel/ranktbl/remitstatsrank.vue')
            },
            {
                path: 'spreadcustomerrecord',
                meta: {
                    title: '我的推广',
                    auth: false,
                    width: 880,
                    height: 650
                },
                component: () => import('./views/webmodel/ranktbl/spreadcustomerrecord.vue')
            },
            {
                path: 'spreadcustomerrecorddetail',
                meta: {
                    title: '推广记录详情',
                    auth: false,
                    width: 1050,
                    height: 650
                },
                component: () => import('./views/webmodel/ranktbl/spreadcustomerrecorddetail.vue')
            },
            {
                path: 'remitstatsdetailbyuser',
                meta: {
                    title: '汇款订单详情',
                    auth: false,
                    width: 1210,
                    height: 650
                },
                component: () => import('./views/webmodel/ranktbl/remitstatsdetailbyuser.vue')
            },
            {
                path: 'consultcustomer',
                meta: {
                    title: '客服客资',
                    auth: false,
                    header: false,
                    width: 1000,
                    height: 630
                },
                component: () => import('./views/webmodel/consultreport/index.vue')
            },
            {
                path: 'product',
                meta: {
                    title: '图纸列表',
                    auth: false,
                    width: 840,
                    height: 630
                },
                component: () => import('./views/webmodel/product.vue')
            },
            {
                path: 'express',
                meta: {
                    title: '快递列表',
                    auth: false,
                    width: 400,
                    height: 360
                },
                component: () => import('./views/webmodel/express.vue')
            },
            {
                path: 'orderdetail',
                meta: {
                    title: '订单详情',
                    auth: false,
                    width: 850,
                    height: 620
                },
                component: () => import('./views/webmodel/orderdetail.vue')
            },
            {
                path: 'checkorderdetail',
                meta: {
                    title: '订单核对',
                    auth: false,
                    width: 500,
                    height: 650
                },
                component: () => import('./views/webmodel/mychat/checkorderdetail.vue')
            },
            {
                path: 'schedulingdetail',
                meta: {
                    title: '排班审核',
                    auth: false,
                    width: 1000,
                    height: 570
                },
                component: () => import('./views/webmodel/mychat/schedulingdetail.vue')
            },
            {
                path: 'today53servicescustomerreport',
                meta: {
                    title: '客资新增报表',
                    auth: false,
                    width: 400,
                    height: 500
                },
                component: () => import('./views/webmodel/mychat/today53servicescustomerreport.vue')
            },
            {
                path: 'todaykaoqinrecordreport',
                meta: {
                    title: '考勤报表',
                    auth: false,
                    width: 400,
                    height: 500
                },
                component: () => import('./views/webmodel/mychat/todaykaoqinrecordreport.vue')
            },
            {
                path: 'todayperformancereport',
                meta: {
                    title: '业绩报表',
                    auth: false,
                    width: 400,
                    height: 500
                },
                component: () => import('./views/webmodel/mychat/todayperformancereport.vue')
            },
            {
                path: 'todaysalecustomerreport',
                meta: {
                    title: '客资获取报表',
                    auth: false,
                    width: 400,
                    height: 500
                },
                component: () => import('./views/webmodel/mychat/todaysalecustomerreport.vue')
            },
            {
                path: 'findunsettlecustomer',
                meta: {
                    title: '重复客资查询',
                    auth: false,
                    width: 900,
                    height: 600
                },
                component: () => import('./views/webmodel/findunsettlecustomer.vue')
            },
            {
                path: 'groupuser',
                meta: {
                    title: '组员管理',
                    auth: false,
                    sub: true,
                    width: 1000,
                    height: 600
                },
                component: () => import('./views/webmodel/groupuser.vue')
            },
            {
                path: 'personneledit',
                meta: {
                    title: '请假申请',
                    auth: false,
                    sub: true,
                    width: 540,
                    height: 580
                },
                component: () => import('./views/personnel/personnel.edit.vue')
            },
            {
                path: 'privatesetting',
                meta: {
                    title: '个人设置',
                    auth: false,
                    width: 700,
                    height: 650
                },
                component: () => import('./views/webmodel/privatesetting.vue')
            },
            {
                path: 'productdetail',
                meta: {
                    title: '产品详情',
                    auth: false,
                    width: 600,
                    height: 650
                },
                component: () => import('./views/webmodel/productdetail.vue')
            },
            {
                path: 'customerrecorddetail',
                meta: {
                    title: '客户详情',
                    auth: false,
                    width: 900,
                    height: 650
                },
                component: () => import('./views/webmodel/customerrecorddetail.vue')
            },
            {
                path: 'noticedetail',
                meta: {
                    title: '公告详情',
                    auth: false,
                    header: false,
                    width: 600,
                    height: 660
                },
                component: () => import('./views/webmodel/noticedetail.vue')
            },
            {
                path: 'salevictory',
                meta: {
                    title: '销售捷报',
                    auth: false,
                    header: false,
                    width: 480,
                    height: 730
                },
                component: () => import('./views/webmodel/salevictory.vue')
            },
            {
                path: 'historymsg',
                meta: {
                    title: '我的申请',
                    auth: false,
                    width: 1000,
                    height: 600
                },
                component: () => import('./views/webmodel/historymsg.vue')
            },
            {
                path: 'sendorder',
                meta: {
                    title: '发货订单',
                    auth: false,
                    width: 770,
                    height: 360
                },
                component: () => import('./views/webmodel/sendorder.vue')
            },
            {
                path: 'prefunddetail',
                meta: {
                    title: '退款详情',
                    auth: false,
                    width: 380,
                    height: 680
                },
                component: () => import('./views/webmodel/prefunddetail.vue')
            },
            {
                path: 'refunddetail',
                meta: {
                    title: '退款详情',
                    auth: false,
                    width: 380,
                    sub: true,
                    height: 680
                },
                component: () => import('./views/webmodel/refunddetail.vue')
            },
            {
                path: 'kaoqinerrorapply',
                meta: {
                    title: '考勤异常申请列表',
                    auth: false,
                    width: 900,
                    height: 680
                },
                component: () => import('./views/webmodel/kaoqinerrorapply.vue')
            },
            {
                path: 'kaoqinerroredit',
                meta: {
                    title: '考勤异常申请',
                    auth: false,
                    width: 450,
                    height: 360
                },
                component: () => import('./views/webmodel/kaoqinerroredit.vue')
            },
            {
                path: 'saleslog',
                meta: {
                    title: '销售日志',
                    auth: false,
                    width: 1100,
                    height: 720,
                    header: false
                },
                component: () => import('./views/webmodel/log/sales.vue')
            },
            {
                path: 'performancetarget',
                meta: {
                    title: '业绩目标',
                    header: false,
                    auth: false,
                    width: 1100,
                    height: 630
                },
                component: () => import('./views/webmodel/performancetarget.vue')
            },
            {
                path: 'comevisit',
                meta: {
                    title: '来访登记',
                    auth: false,
                    width: 440,
                    height: 550
                },
                component: () => import('./views/webmodel/comevisit.vue')
            },
            {
                path: 'printapply',
                meta: {
                    title: '打印申请',
                    auth: false,
                    width: 440,
                    height: 420
                },
                component: () => import('./views/webmodel/printapply.vue')
            },
            {
                path: 'personneldetail',
                meta: {
                    title: '申请详情',
                    auth: false,
                    width: 880,
                    height: 390
                },
                component: () => import('./views/webmodel/personneldetail.vue')
            },
            {
                path: 'onlinedoc',
                meta: {
                    title: '文件详情',
                    auth: false,
                    header: false,
                    width: 1200,
                    height: 730
                },
                component: () => import('./views/webmodel/onlinedoc.vue')
            },
            {
                path: 'projectdetail',
                meta: {
                    title: '项目详情',
                    auth: false,
                    width: 780,
                    height: 690
                },
                component: () => import('./views/webmodel/projectdetail.vue')
            },
            {
                path: 'projectremit',
                meta: {
                    title: '项目汇款',
                    auth: false,
                    width: 600,
                    height: 260
                },
                component: () => import('./views/webmodel/projectremit.vue')
            },
            {
                path: 'checkchatstatus',
                meta: {
                    title: '通讯连接状态检查',
                    auth: false,
                    width: 1100,
                    height: 600
                },
                component: () => import('./views/webmodel/checkchatstatus.vue')
            },
            {
                path: 'checkchatprivatestatus',
                meta: {
                    title: '连接状态检查',
                    auth: false,
                    width: 340,
                    height: 550
                },
                component: () => import('./views/webmodel/checkchatprivatestatus.vue')
            },
            {
                path: 'personneldemissiondetail',
                meta: {
                    title: '离职详情',
                    auth: false,
                    width: 380,
                    height: 460
                },
                component: () => import('./views/webmodel/personneldemissiondetail.vue')
            },
            {
                path: 'scheduling53servicesandsalesrate',
                meta: {
                    title: '排班比例',
                    auth: false,
                    width: 740,
                    height: 460
                },
                component: () => import('./views/webmodel/scheduling53servicesandsalesrate.vue')
            },
            {
                path: 'consultandsendrulestats',
                meta: {
                    title: '咨询统计',
                    auth: false,
                    width: 940,
                    height: 560
                },
                component: () => import('./views/webmodel/consultandsendrulestats.vue')
            },
            {
                path: 'definedproject',
                meta: {
                    title: '项目定义',
                    auth: false,
                    width: 1000,
                    height: 680
                },
                component: () => import('./views/webmodel/definedproject.vue')
            },
            {
                path: 'customerrepeatovermax',
                meta: {
                    title: '重复客资详情',
                    auth: false,
                    width: 920,
                    height: 680
                },
                component: () => import('./views/webmodel/customerrepeatovermax.vue')
            },
            {
                path: 'networkdisk',
                meta: {
                    title: '企业网盘',
                    auth: false,
                    width: 1200,
                    height: 680
                },
                component: () => import('./views/webmodel/networkdisk.vue')
            },
            {
                path: 'imgdlledit',
                meta: {
                    title: '图库编辑',
                    auth: false,
                    width: 500,
                    height: 500
                },
                component: () => import('./views/webmodel/imgdlledit.vue')
            },
            {
                path: 'trademanage',
                meta: {
                    title: '交易码管理',
                    auth: false,
                    sub: true,
                    width: 500,
                    height: 300
                },
                component: () => import('./views/webmodel/trademanage.vue')
            },
            {
                path: 'onlinesysuser',
                meta: {
                    title: '客服管理',
                    auth: false,
                    header: false,
                    width: 1100,
                    height: 650
                },
                component: () => import('./views/market/onlinesysuser.vue')
            },
            {
                path: 'onlinesysuseredit',
                meta: {
                    title: '客服编辑',
                    auth: false,
                    sub: true,
                    width: 1100,
                    height: 650
                },
                component: () => import('./views/market/onlinesysuser.edit.vue')
            },
            {
                path: 'matchnetworkmaterial',
                meta: {
                    title: '网络图素材详情',
                    auth: false,
                    width: 1100,
                    height: 650
                },
                component: () => import('./views/webmodel/matchnetworkmaterial.vue')
            },
            {
                path: 'editdistributoruser',
                meta: {
                    title: '编辑分销商',
                    auth: false,
                    width: 600,
                    height: 600
                },
                component: () => import('./views/role/distributorusers.edit.vue')
            },
            {
                path: 'findimage',
                meta: {
                    title: '帮我找图',
                    auth: false,
                    width: 440,
                    height: 340
                },
                component: () => import('./views/webmodel/findimage.vue')
            },
            {
                path: 'prodattr',
                meta: {
                    title: '属性列表',
                    auth: false,
                    width: 440,
                    height: 340
                },
                component: () => import('./views/webmodel/prodattr.vue')
            },
            {
                path: 'memorandum',
                meta: {
                    title: '备忘记录',
                    auth: false,
                    header: false,
                    width: 800,
                    height: 500
                },
                component: () => import('./views/webmodel/tool/memorandum.vue')
            },
            {
                path: 'memorandumdetail',
                meta: {
                    title: '备忘记录详情',
                    auth: false,
                    width: 600,
                    height: 600
                },
                component: () => import('./views/webmodel/tool/memorandumdetail.vue')
            },
            {
                path: 'afternewcustomer',
                meta: {
                    title: '新增客户',
                    auth: false,
                    width: 1100,
                    height: 560
                },
                component: () => import('./views/webmodel/aftersale/afternewcustomer.vue')
            },
            {
                path: 'afterallocationcustomer',
                meta: {
                    title: '跟进客户',
                    auth: false,
                    width: 1100,
                    height: 560
                },
                component: () => import('./views/webmodel/aftersale/afterallocationcustomer.vue')
            },
            {
                path: 'afterallcustomer',
                meta: {
                    title: '我的客户',
                    auth: false,
                    width: 1100,
                    height: 560
                },
                component: () => import('./views/webmodel/aftersale/afterallcustomer.vue')
            },
            {
                path: 'afterproblem',
                meta: {
                    title: '问题处理',
                    auth: false,
                    width: 1100,
                    height: 560
                },
                component: () => import('./views/webmodel/aftersale/afterproblem.vue')
            },
            {
                path: 'boardcheckorder',
                meta: {
                    title: '核对订单',
                    header: false,
                    auth: false,
                    width: 1100,
                    height: 600
                },
                component: () => import('./views/webmodel/aftersale/boardcheckorder.vue')
            },
            {
                path: 'waitupdateorder',
                meta: {
                    title: '待更新订单',
                    header: false,
                    auth: false,
                    width: 1100,
                    height: 600
                },
                component: () => import('./views/webmodel/aftersale/waitupdateorder.vue')
            },
            {
                path: 'waitallocationcustomer',
                meta: {
                    title: '待分配客户',
                    header: false,
                    auth: false,
                    width: 1100,
                    height: 600
                },
                component: () => import('./views/webmodel/aftersale/waitallocationcustomer.vue')
            },
            {
                path: 'errororder',
                meta: {
                    title: '异常订单',
                    header: false,
                    auth: false,
                    width: 1060,
                    height: 530
                },
                component: () => import('./views/webmodel/aftersale/errororder.vue')
            },
            {
                path: 'aftertaskdetail',
                meta: {
                    title: '客户详情',
                    auth: false,
                    width: 800,
                    height: 530
                },
                component: () => import('./views/webmodel/aftersale/aftertaskdetail.vue')
            },
            {
                path: 'marketconsult',
                meta: {
                    title: '新增客资/无效',
                    auth: false,
                    header: false,
                    width: 1100,
                    height: 630
                },
                component: () => import('./views/webmodel/marketreport/consult.vue')
            },
            {
                path: 'marketvisitrecord',
                meta: {
                    title: '跟进客户',
                    auth: false,
                    header: false,
                    width: 1000,
                    height: 640
                },
                component: () => import('./views/webmodel/marketreport/visitrecord.vue')
            },
            {
                path: 'marketperformance',
                meta: {
                    title: '业绩列表',
                    auth: false,
                    header: false,
                    width: 1100,
                    height: 730
                },
                component: () => import('./views/webmodel/marketreport/performance.vue')
            },
            {
                path: 'visitrecordbyuser',
                meta: {
                    title: '跟进次数',
                    auth: false,
                    width: 1100,
                    height: 600
                },
                component: () => import('./views/webmodel/visitrecordbyuser.vue')
            },
            {
                path: 'applyonlinenoscheduling',
                meta: {
                    title: '加班申请',
                    auth: false,
                    width: 400,
                    height: 150
                },
                component: () => import('./views/webmodel/applyonlinenoscheduling.vue')
            },
            {
                path: 'unrelatedcustomer',
                meta: {
                    title: '未关联客户订单',
                    auth: false,
                    width: 1000,
                    height: 650
                },
                component: () => import('./views/webmodel/waitexcutetask/unrelatedcustomer.vue')
            },
            {
                path: 'waitreleasetask',
                meta: {
                    title: '即将释放跟进任务',
                    auth: false,
                    width: 1000,
                    height: 650
                },
                component: () => import('./views/webmodel/waitexcutetask/waitreleasetask.vue')
            },
            {
                path: 'ungetconsult',
                meta: {
                    title: '未完善的客资',
                    auth: false,
                    width: 1000,
                    height: 650
                },
                component: () => import('./views/webmodel/waitexcutetask/ungetconsult.vue')
            },
            {
                path: 'unvalidconsult',
                meta: {
                    title: '无效客资',
                    auth: false,
                    width: 1100,
                    height: 570
                },
                component: () => import('./views/webmodel/consultdetail.vue')
            },
            {
                path: 'consultnosale',
                meta: {
                    title: '超时客资',
                    auth: false,
                    width: 1100,
                    height: 470
                },
                component: () => import('./views/webmodel/consultdetail.vue')
            },
            {
                path: 'uploadfile',
                meta: {
                    title: '文件上传',
                    auth: false,
                    width: 1000,
                    height: 600
                },
                component: () => import('./views/webmodel/uploadfile.vue')
            }
        ]
    },
    //webmodel结束
    {
        path: '/',
        meta: {
            title: '后台管理系统'
        },
        component: () => import('./views/index.vue'),
        children: [
            {
                path: '/m',
                meta: {
                    title: '首页',
                    bread: 0
                },
                component: () => import('./views/default.vue')
            },
            {
                path: '/boss',
                meta: {
                    title: '首页',
                    bread: 0
                },
                component: () => import('./views/defaultbyboss.vue')
            },
            // {
            //     path: '/targetcompletion',
            //     meta: {
            //         title: '目标完成情况',
            //         bread: 1
            //     },
            //     component: () => import('./views/targetcompletion.vue')
            // },
            {
                path: '/project1',
                meta: {
                    title: '我的项目',
                    bread: 0
                },
                component: () => import('./views/project/index.vue'),
                children: [{
                    path: "",
                    component: () => import('./views/project/index.edit.vue')
                },
                {
                    path: "detail/:id",
                    component: () => import('./views/project/index.info.vue')
                },
                {
                    path: "folder/:id",
                    component: () => import('./views/project/index.doc.vue')
                },
                {
                    path: "chat/:id",
                    component: () => import('./views/project/index.chat.vue')
                },
                {
                    path: "user/:id",
                    component: () => import('./views/project/index.user.vue')
                },
                {
                    path: "remit/:id",
                    component: () => import('./views/project/index.remit.vue')
                },
                {
                    path: "remark/:id",
                    component: () => import('./views/project/index.remark.vue')
                }],

            },
            {
                path: '/project',
                meta: {
                    title: '我的项目',
                    bread: 1
                },
                component: () => import('./views/project/index1.vue')
            },
            {
                path: '/projectedit',
                meta: {
                    title: '项目编辑',
                    bread: 1
                },
                component: () => import('./views/project/index1.edit.vue')
            },
            {
                path: '/teamproject',
                meta: {
                    title: '我的项目',
                    bread: 1
                },
                component: () => import('./views/project/index1.team.vue')
            },
            {
                path: '/projectorderedit',
                meta: {
                    title: '设计订单',
                    bread: 1
                },
                component: () => import('./views/project/order.edit.vue')
            },
            {
                path: '/forgetproject',
                meta: {
                    title: '我的项目',
                    bread: 1
                },
                component: () => import('./views/project/index.vue')
            },
            {
                path: '/overproject',
                meta: {
                    title: '结束项目',
                    bread: 1
                },
                component: () => import('./views/project/index.over.vue'),
                children: [
                    {
                        path: "detail/:id",
                        component: () => import('./views/project/index.info.vue')
                    },
                    {
                        path: "folder/:id",
                        component: () => import('./views/project/index.doc.vue')
                    },
                    {
                        path: "chat/:id",
                        component: () => import('./views/project/index.chat.vue')
                    },
                    {
                        path: "user/:id",
                        component: () => import('./views/project/index.user.vue')
                    },
                    {
                        path: "remit/:id",
                        component: () => import('./views/project/index.remit.vue')
                    },
                    {
                        path: "remark/:id",
                        component: () => import('./views/project/index.remark.vue')
                    }
                ]
            },
            {
                path: '/projectinfo',
                meta: {
                    title: '项目信息',
                    bread: 2
                },
                component: () => import('./views/project/index.info.vue')
            },
            {
                path: '/projecttask',
                meta: {
                    title: '项目任务',
                    bread: 2
                },
                component: () => import('./views/project/index.task.vue')
            },
            {
                path: '/projectdoc',
                meta: {
                    title: '项目文档',
                    bread: 3
                },
                component: () => import('./views/project/index.doc.vue')
            },
            {
                path: '/projectschedule',
                meta: {
                    title: '项目日程',
                    bread: 3
                },
                component: () => import('./views/project/index.schedule.vue')
            },
            {
                path: '/projectchat',
                meta: {
                    title: '项目群聊',
                    bread: 3
                },
                component: () => import('./views/project/index.chat.vue')
            },
            {
                path: '/template',
                meta: {
                    title: '定制套餐',
                    bread: 1
                },
                component: () => import('./views/project/template.vue')
            },
            {
                path: '/templateedit',
                meta: {
                    title: '定制套餐编辑',
                    bread: 2
                },
                component: () => import('./views/project/template.edit.vue')
            },
            {
                path: '/order',
                meta: {
                    title: '发货列表',
                    bread: 1
                },
                component: () => import('./views/crm/order.vue')
            },
            {
                path: '/shoporder',
                meta: {
                    title: '订单列表',
                    bread: 1
                },
                component: () => import('./views/crm/shoporder.vue')
            },
            {
                path: '/constructionstandard',
                meta: {
                    title: '施工标准',
                    bread: 1
                },
                components: {
                    default: () => import('./views/baseinfo/constructionstandard.vue')
                }
            },
            {
                path: '/constructionorder',
                meta: {
                    title: '施工订单',
                    bread: 1
                },
                component: () => import('./views/crm/constructionorder.vue')
            },
            {
                path: '/mouldorder',
                meta: {
                    title: '售后订单',
                    bread: 1
                },
                component: () => import('./views/crm/mouldorder.vue')
            },
            {
                path: '/orderedit',
                meta: {
                    title: '订单编辑',
                    bread: 2
                },
                component: () => import('./views/crm/order.edit.vue')
            },
            {
                path: '/unusualorder',
                meta: {
                    title: '异常订单',
                    bread: 1
                },
                components:
                {
                    default: () => import('./views/crm/unusualorder.vue')
                }
            },
            {
                path: '/mouldorderedit',
                meta: {
                    title: '售后订单编辑',
                    bread: 2
                },
                component:() => import('./views/crm/mouldorder.edit.vue')
            },
            {
                path: '/constructionorderedit',
                meta: {
                    title: '施工订单编辑',
                    bread: 2
                },
                components:() => import('./views/crm/constructionorder.edit.vue')
            },
            {
                path: '/ordernoticetemple',
                meta: {
                    title: '通知模板',
                    bread: 1
                },
                component: () => import('./views/crm/ordernoticetemple.vue')
            },
            {
                path: '/ordernoticetempleedit',
                meta: {
                    title: '模板编辑',
                    bread: 2
                },
                component: () => import('./views/crm/ordernoticetemple.edit.vue')
            },
            {
                path: '/customer',
                meta: {
                    title: '潜在客户',
                    bread: 1
                },
                component: () => import('./views/crm/customer.vue')
            },
            {
                path: '/seacustomer',
                meta: {
                    title: '公海客户',
                    bread: 1
                },
                component: () => import('./views/crm/seacustomer.vue')
            },
            {
                path: '/dealcustomer',
                meta: {
                    title: '成交客户',
                    bread: 1
                },
                component: () => import('./views/crm/dealcustomer.vue')
            },
            {
                path: '/consult',
                meta: {
                    title: '客资管理',
                    bread: 1
                },
                component: () => import('./views/consult/consult.vue')
            },
            {
                path: '/repeatconsult',
                meta: {
                    title: '客资管理',
                    bread: 1
                },
                component: () => import('./views/consult/repeatconsult.vue')
            },
            {
                path: '/consultedit',
                meta: {
                    title: '客资编辑',
                    bread: 2
                },
                component: () => import('./views/consult/consult.edit.vue')
            },
            {
                path: '/consultspecialedit',
                meta: {
                    title: '客资编辑',
                    bread: 2
                },
                component: () => import('./views/consult/consult.special.edit.vue')
            },
            {
                path: '/consultprivateedit',
                meta: {
                    title: '私发客资',
                    bread: 1
                },
                component: () => import('./views/consult/consult.private.edit.vue')
            },
            {
                path: '/consultforget',
                meta: {
                    title: '遗忘客资',
                    bread: 1
                },
                component: () => import('./views/consult/consultforget.vue')
            },
            {
                path: '/suggest',
                meta: {
                    title: '客户投诉',
                    bread: 1
                },
                component: () => import('./views/consult/suggest.vue')
            },
            {
                path: '/suggestedit',
                meta: {
                    title: '客户投诉编辑',
                    bread: 2
                },
                component: () => import('./views/consult/suggest.edit.vue')
            },
            {
                path: '/knowledge',
                meta: {
                    title: '知识库管理',
                    bread: 1
                },
                component: () => import('./views/consult/knowledge.vue')
            },
            {
                path: '/knowledgeedit',
                meta: {
                    title: '知识库编辑',
                    bread: 2
                },
                component: () => import('./views/consult/knowledge.edit.vue')
            },
            {
                path: '/product',
                meta: {
                    title: '产品管理',
                    bread: 1
                },
                component: () => import('./views/product/product.vue')
            },
            {
                path: '/productedit',
                meta: {
                    title: '产品编辑',
                    bread: 2
                },
                component: () => import('./views/product/product.edit.vue')
            },
            {
                path: '/source',
                meta: {
                    title: '来源管理',
                    bread: 1
                },
                component: () => import('./views/baseinfo/source.vue')
            },
            {
                path: '/sourceedit',
                meta: {
                    title: '来源编辑',
                    bread: 2
                },
                component: () => import('./views/baseinfo/source.edit.vue')
            },
            {
                path: '/payway',
                meta: {
                    title: '支付方式',
                    bread: 1
                },
                component: () => import('./views/baseinfo/payway.vue')
            },
            {
                path: '/payaccount',
                meta: {
                    title: '收款账户',
                    bread: 1
                },
                component: () => import('./views/baseinfo/payaccount.vue')
            },
            {
                path: '/suggesttype',
                meta: {
                    title: '投诉类型管理',
                    bread: 1
                },
                component: () => import('./views/baseinfo/suggesttype.vue')
            },
            {
                path: '/suggesttypeedit',
                meta: {
                    title: '投诉类型管理',
                    bread: 2
                },
                component: () => import('./views/baseinfo/suggesttype.edit.vue')
            },
            {
                path: '/designcategoryedit',
                meta: {
                    title: '设计分类编辑',
                    bread: 2
                },
                component: () => import('./views/baseinfo/designcategory.edit.vue')
            },
            {
                path: '/designcategory',
                meta: {
                    title: '设计分类',
                    bread: 1
                },
                component: () => import('./views/baseinfo/designcategory.vue')
            },
            {
                path: '/paywayedit',
                meta: {
                    title: '支付方式编辑',
                    bread: 2
                },
                component: () => import('./views/baseinfo/payway.edit.vue')
            },
            {
                path: '/express',
                meta: {
                    title: '快递管理',
                    bread: 1
                },
                component: () => import('./views/baseinfo/express.vue')
            },
            {
                path: '/expressedit',
                meta: {
                    title: '快递编辑',
                    bread: 2
                },
                component: () => import('./views/baseinfo/express.edit.vue')
            },
            {
                path: '/buildstatus',
                meta: {
                    title: '建房状态',
                    bread: 1
                },
                component: () => import('./views/baseinfo/buildstatus.vue')
            },
            {
                path: '/contracttemplate',
                meta: {
                    title: '订单合同',
                    bread: 1
                },
                component: () => import('./views/baseinfo/contracttemplate.vue')
            },
            {
                path: '/contracttemplateedit',
                meta: {
                    title: '合同编辑',
                    bread: 2
                },
                component: () => import('./views/baseinfo/contracttemplate.edit.vue')
            },
            {
                path: '/users',
                meta: {
                    title: '用户列表',
                    bread: 1
                },
                component: () => import('./views/role/users.vue')
            },
            {
                path: '/usersedit',
                meta: {
                    title: '用户编辑',
                    bread: 2
                },
                component: () => import('./views/role/users.edit.vue')
            },
            {
                path: '/distributorusers',
                meta: {
                    title: '分销商列表',
                    bread: 1
                },
                component: () => import('./views/role/distributorusers.vue')
            },
            {
                path: '/distributorusersedit',
                meta: {
                    title: '分销商用户编辑',
                    bread: 2
                },
                component: () => import('./views/role/distributorusers.edit.vue')
            },
            {
                path: '/cooperateusers',
                meta: {
                    title: '合作方列表',
                    bread: 1
                },
                component: () => import('./views/role/cooperateusers.vue')
            },
            {
                path: '/cooperateusersedit',
                meta: {
                    title: '合作方用户编辑',
                    bread: 2
                },
                component: () => import('./views/role/cooperateusers.edit.vue')
            },
            {
                path: '/roles',
                meta: {
                    title: '角色列表',
                    bread: 1
                },
                component: () => import('./views/role/roles.vue')
            },
            {
                path: '/rolesedit',
                meta: {
                    title: '角色编辑',
                    bread: 2
                },
                component: () => import('./views/role/roles.edit.vue')
            },
            {
                path: '/rolesuser',
                meta: {
                    title: '角色用户',
                    bread: 1
                },
                component: () => import('./views/role/roles.user.vue')
            },
            {
                path: '/rolesorganize',
                meta: {
                    title: '部门角色',
                    bread: 2
                },
                component: () => import('./views/role/roles.organize.vue')
            },
            {
                path: '/limit',
                meta: {
                    title: '权限列表',
                    bread: 1
                },
                component: () => import('./views/role/column.vue')
            },
            {
                path: '/customeredit',
                meta: {
                    title: '潜在客户编辑',
                    bread: 2
                },
                component: () => import('./views/crm/customer.edit.vue')
            },
            {
                path: '/customertask',
                meta: {
                    title: '客户任务',
                    bread: 2
                },
                component: () => import('./views/crm/customer.task.vue')
            },
            {
                path: '/mytask',
                meta: {
                    title: '我的任务',
                    bread: 1
                },
                component: () => import('./views/crm/mytask.vue')
            },
            {
                path: '/teamtask',
                meta: {
                    title: '团队任务',
                    bread: 1
                },
                component: () => import('./views/crm/teamtask.vue')
            },
            {
                path: '/myaftertask',
                meta: {
                    title: '我的客户',
                    bread: 1
                },
                component: () => import('./views/crm/myaftertask.vue')
            },
            {
                path: '/teamaftertask',
                meta: {
                    title: '团队客户',
                    bread: 1
                },
                component: () => import('./views/crm/teamaftertask.vue')
            },
            {
                path: '/allocationaftersale',
                meta: {
                    title: '分配管理',
                    bread: 1
                },
                component: () => import('./views/crm/allocationaftersale.vue')
            },
            {
                path: '/afterproblem',
                meta: {
                    title: '问题处理',
                    bread: 1
                },
                component: () => import('./views/crm/afterproblem.vue')
            },
            {
                path: '/afterproblemedit',
                meta: {
                    title: '问题处理编辑',
                    bread: 2
                },
                component: () => import('./views/crm/afterproblem.edit.vue')
            },
            {
                path: '/constructiontask',
                meta: {
                    title: '施工任务',
                    bread: 1
                },
                components: () => import('./views/crm/constructiontask.vue')
            },
            {
                path: '/mouldtask',
                meta: {
                    title: '模具任务',
                    bread: 1
                },
                component: () => import('./views/crm/mouldtask.vue')
            },
            {
                path: '/visitcustomer',
                meta: {
                    title: '拜访客户',
                    bread: 1
                },
                component: () => import('./views/crm/mytask.visit.customer.vue')
            },
            {
                path: '/remittance',
                meta: {
                    title: '汇款管理',
                    bread: 1
                },
                component: () => import('./views/crm/remittance.vue')
            },
            {
                path: '/remittanceedit',
                meta: {
                    title: '汇款编辑',
                    bread: 1
                },
                component: () => import('./views/crm/remittance.edit.vue')
            },
            {
                path: '/remittancemanage',
                meta: {
                    title: '汇款编辑',
                    bread: 2
                },
                component: () => import('./views/crm/remittance.manage.vue')
            },
            {
                path: '/sendrule',
                meta: {
                    title: '推送规则',
                    bread: 1
                },
                component: () => import('./views/baseinfo/sendrule.vue')
            },
            {
                path: '/sendruledetail',
                meta: {
                    title: '客服推送规则',
                    bread: 1
                },
                component: () => import('./views/baseinfo/sendruledetail.vue')
            },
            {
                path: '/schedule',
                meta: {
                    title: '日程安排',
                    bread: 1
                },
                component: () => import('./views/crm/schedule.vue')
            },
            {
                path: '/workrecord',
                meta: {
                    title: '工作计划',
                    bread: 1
                },
                component: () => import('./views/crm/workrecord.vue')
            },
            {
                path: '/workrecordedit',
                meta: {
                    title: '工作计划编辑',
                    bread: 2
                },
                component: () => import('./views/crm/workrecord.edit.vue')
            },
            {
                path: '/workrecordcomment',
                meta: {
                    title: '工作计划点评',
                    bread: 2
                },
                component: () => import('./views/crm/workrecord.comment.vue')
            },
            {
                path: '/refund',
                meta: {
                    title: '退款管理',
                    bread: 1
                },
                component: () => import('./views/crm/refund.vue')
            },
            {
                path: '/todayinfo',
                meta: {
                    title: '今日信息',
                    bread: 1
                },
                component: () => import('./views/crm/todayinfo.vue')
            },
            {
                path: '/doc',
                meta: {
                    title: '文档管理',
                    bread: 2
                },
                component: () => import('./views/doc/doc.vue')
            },
            {
                path: '/docedit',
                meta: {
                    title: '新建文档',
                    bread: 3
                },
                component: () => import('./views/doc/doc.edit.vue')
            },
            {
                path: '/imgdll',
                meta: {
                    title: '图库管理',
                    bread: 1
                },
                component: () => import('./views/doc/imgdll.vue')
            },
            {
                path: '/imgdlledit',
                meta: {
                    title: '图库编辑',
                    bread: 2
                },
                component: () => import('./views/doc/imgdll.edit.vue')
            },
            {
                path: '/notice',
                meta: {
                    title: '企业公告',
                    bread: 1
                },
                component: () => import('./views/sysinfo/notice.vue')
            },
            {
                path: '/noticeedit',
                meta: {
                    title: '企业公告编辑',
                    bread: 2
                },
                component: () => import('./views/sysinfo/notice.edit.vue')
            },
            {
                path: '/customerkind',
                meta: {
                    title: '客户分类',
                    bread: 1
                },
                component: () => import('./views/baseinfo/customerkind.vue')
            },
            {
                path: '/customerkindedit',
                meta: {
                    title: '客户分类编辑',
                    bread: 2
                },
                component: () => import('./views/baseinfo/customerkind.edit.vue')
            },
            {
                path: '/projectkind',
                meta: {
                    title: '项目类型',
                    bread: 1
                },
                component: () => import('./views/baseinfo/projectkind.vue')
            },
            {
                path: '/projectkindedit',
                meta: {
                    title: '项目类型编辑',
                    bread: 2
                },
                component: () => import('./views/baseinfo/projectkind.edit.vue')
            },
            {
                path: '/projectreport',
                meta: {
                    title: '项目报表',
                    bread: 1
                },
                component: () => import('./views/report/project.vue'),
                children: [{
                    path: '/',
                    meta: {
                        title: '销售业绩',
                        bread: 1
                    },
                    component: () => import('./views/report/project.1.vue')
                }, {
                    path: '/project1',
                    meta: {
                        title: '销售业绩',
                        bread: 1
                    },
                    component: () => import('./views/report/project.1.vue')
                }]
            },
            {
                path: '/salereport',
                meta: {
                    title: '销售报表',
                    bread: 1
                },
                component: () => import('./views/report/sale.vue'),
            },
            {
                path: '/consultreport',
                meta: {
                    title: '客服报表',
                    bread: 1
                },
                component: () => import('./views/report/consult.vue')
            },
            {
                path: '/marketreport',
                meta: {
                    title: '运营报表',
                    bread: 1
                },
                component: () => import('./views/report/market.vue')
            },
            {
                path: '/organize',
                meta: {
                    title: '组织管理',
                    bread: 1
                },
                component: () => import('./views/sysinfo/organize.vue')
            },
            {
                path: '/flow',
                meta: {
                    title: '流程管理',
                    bread: 1
                },
                component: () => import('./views/role/flow.vue')
            },
            {
                path: '/flowedit',
                meta: {
                    title: '流程编辑',
                    bread: 2
                },
                component: () => import('./views/role/flow.edit.vue')
            },
            {
                path: '/attr',
                meta: {
                    title: '标签管理',
                    bread: 1
                },
                component: () => import('./views/baseinfo/attr.vue')
            },
            {
                path: '/attr2',
                meta: {
                    title: '属性管理',
                    bread: 1
                },
                component: () => import('./views/baseinfo/attr2.vue')
            },
            {
                path: '/attr2list',
                meta: {
                    title: '属性列表',
                    bread: 2
                },
                component: () => import('./views/baseinfo/attr2list.vue')
            },
            {
                path: '/attr2edit',
                meta: {
                    title: '属性编辑',
                    bread: 3
                },
                component: () => import('./views/baseinfo/attr2edit.vue')
            },
            {
                path: '/setinfolist',
                meta: {
                    title: '公司列表',
                    bread: 1
                },
                component: () => import('./views/sysinfo/setinfolist.vue')
            },
            {
                path: '/setinfo',
                meta: {
                    title: '信息设置',
                    bread: 1
                },
                component: () => import('./views/sysinfo/setinfo.vue')
            },
            {
                path: '/download',
                meta: {
                    title: '导出列表',
                    bread: 1
                },
                component: () => import('./views/exportdata/index.vue')
            },
            {
                path: '/importtemplate',
                meta: {
                    title: '导入模板',
                    bread: 1
                },
                component: () => import('./views/exportdata/importtemplate.vue')
            },
            {
                path: '/pageerror',
                meta: {
                    title: '页面异常',
                    bread: 1
                },
                component: () => import('./views/pageerror.vue')
            },
            {
                path: '/salary',
                meta: {
                    title: '基础工资',
                    bread: 1
                },
                component: () => import('./views/commission/salary.vue')
            },
            {
                path: '/commission',
                meta: {
                    title: '佣金管理',
                    bread: 1
                },
                component: () => import('./views/commission/commission.vue')
            },
            {
                path: '/commissionsetting',
                meta: {
                    title: '佣金配置',
                    bread: 2
                },
                component: () => import('./views/commission/commission.setting.vue')
            },
            {
                path: '/commissionsettingedit',
                meta: {
                    title: '佣金配置编辑'
                },
                component: () => import('./views/commission/commission.setting.edit.vue')
            },
            {
                path: '/apaudit',
                meta: {
                    title: '接入审核',
                    bread: 1
                },
                component: () => import('./views/sysinfo/apaudit.vue')
            },
            {
                path: '/personnel',
                meta: {
                    title: '人事管理'
                },
                component: () => import('./views/personnel/personnel.vue')
            },
            {
                path: '/personneldimission',
                meta: {
                    title: '人事管理'
                },
                component: () => import('./views/personnel/personnel.dimission.vue')
            },
            {
                path: '/personneledit',
                meta: {
                    title: '申请编辑'
                },
                component: () => import('./views/personnel/personnel.edit.vue')
            },
            {
                path: '/comevisit',
                meta: {
                    title: '来访列表',
                    bread:1
                },
                component: () => import('./views/personnel/comevisit.vue')
            },
            {
                path: '/comevisitedit',
                meta: {
                    title: '来访编辑',
                    bread:2
                },
                component: () => import('./views/personnel/comevisit.edit.vue')
            },
            {
                path: '/personnelboard',
                meta: {
                    title: '排班列表',
                    bread: 1
                },
                component: () => import('./views/personnel/board.vue')
            },
            {
                path: '/assetscategory',
                meta: {
                    title: '资产分类',
                    bread: 1
                },
                component: () => import('./views/assets/assetscategory')
            }
            ,
            {
                path: '/assetsmanage',
                meta: {
                    title: '资产属性管理',
                    bread: 1
                },
                component: () => import('./views/baseinfo/assetsmanage.vue')
            },
            {
                path: '/assetsmanageedit',
                meta: {
                    title: '资产属性编辑',
                    bread: 2
                },
                component: () => import('./views/baseinfo/assetsmanage.edit.vue')
            },
            {
                path: '/scheduling',
                meta: {
                    title: '排班列表',
                    bread: 2
                },
                component: () => import('./views/personnel/scheduling.vue')
            },
            {
                path: '/myscheduling',
                meta: {
                    title: '排班申请',
                    bread: 1
                },
                component: () => import('./views/personnel/myscheduling.vue')
            },
            {
                path: '/schedulingauto',
                meta: {
                    title: '自动排班设置',
                    bread: 2
                },
                component: () => import('./views/personnel/scheduling.auto.vue')
            },
            {
                path: '/schedulingrule',
                meta: {
                    title: '班次规则',
                    bread: 2
                },
                component: () => import('./views/personnel/scheduling.rule.vue')
            },
            {
                path: '/schedulingruleedit',
                meta: {
                    title: '班次规则编辑',
                    bread: 3
                },
                component: () => import('./views/personnel/scheduling.rule.edit.vue')
            },
            {
                path: '/schedulingtype',
                meta: {
                    title: '班次配置',
                    bread: 2
                },
                component: () => import('./views/personnel/scheduling.type.vue')
            },
            {
                path: '/festival',
                meta: {
                    title: '节假日管理',
                    bread: 2
                },
                component: () => import('./views/personnel/festival.vue')
            },
            {
                path: '/dutymanage',
                meta: {
                    title: '考勤管理',
                    bread: 1
                },
                component: () => import('./views/personnel/dutymanage.vue')
            },
            {
                path: '/assets',
                meta: {
                    title: '资产管理',
                    bread: 1
                },
                component: () => import('./views/assets/assets.vue')
            },
            {
                path: '/assetsedit',
                meta: {
                    title: '资产编辑',
                    bread: 2
                },
                component: () => import('./views/assets/assets.edit.vue')
            },
            {
                path: '/assetsapply',
                meta: {
                    title: '资产申请管理',
                    bread: 2
                },
                component: () => import('./views/assets/assetsapply.vue')
            },
            {
                path: '/assetsapplyedit',
                meta: {
                    title: '资产申请编辑',
                    bread: 3
                },
                component: () => import('./views/assets/assetsapply.edit.vue')
            },
            {
                path: '/myassets',
                meta: {
                    title: '个人资产',
                    bread: 2
                },
                component: () => import('./views/assets/myassets.vue')
            },
            {
                path: '/userloginlog',
                meta: {
                    title: '用户登录日志',
                    bread: 1
                },
                component: () => import('./views/syslog/userlogin.vue')
            },
            {
                path: '/versionmanage',
                meta: {
                    title: '版本管理',
                    bread: 1
                },
                component: () => import('./views/sysinfo/versionmanage.vue')
            },
            {
                path: '/versionmanageedit',
                meta: {
                    title: '版本编辑',
                    bread: 2
                },
                component: () => import('./views/sysinfo/versionmanage.edit.vue')
            },
            {
                path: '/helperdoc',
                meta: {
                    title: '帮助说明',
                    bread: 1
                },
                component: () => import('./views/sysinfo/helperdoc.vue')
            },
            {
                path: '/helperdocedit',
                meta: {
                    title: '帮助编辑',
                    bread: 2
                },
                component: () => import('./views/sysinfo/helperdoc.edit.vue')
            },
            {
                path: '/auditionarchives',
                meta: {
                    title: '应聘档案',
                    bread: 1
                },
                component: () => import('./views/personnel/auditionarchives.vue')
            },
            {
                path: '/personnelarchives',
                meta: {
                    title: '员工档案',
                    bread: 1
                },
                component: () => import('./views/personnel/archives.vue')
            },
            {
                path: '/archivesedit',
                meta: {
                    title: '档案编辑',
                    bread: 2
                },
                component: () => import('./views/personnel/archives.edit.vue')
            },
            {
                path: '/contract',
                meta: {
                    title: '合同管理',
                    bread: 1
                },
                component: () => import('./views/personnel/contract.vue')
            },
            {
                path: '/contractedit',
                meta: {
                    title: '合同管理',
                    bread: 2
                },
                component: () => import('./views/personnel/contract.edit.vue')
            },
            {
                path: '/archivesinfo',
                meta: {
                    title: '档案信息管理',
                    bread: 1
                },
                component: () => import('./views/baseinfo/archivesinfo.vue')
            },
            {
                path: '/archivesinfoedit',
                meta: {
                    title: '档案信息编辑',
                    bread: 2
                },
                component: () => import('./views/baseinfo/archivesinfo.edit.vue')
            },
            {
                path: '/docinfo',
                meta: {
                    title: '文档信息管理',
                    bread: 1
                },
                component: () => import('./views/baseinfo/docinfo.vue')
            },
            {
                path: '/docinfoedit',
                meta: {
                    title: '文档信息编辑',
                    bread: 2
                },
                component: () => import('./views/baseinfo/docinfo.edit.vue')
            },
            {
                path: '/contracttype',
                meta: {
                    title: '合同类型',
                    bread: 1
                },
                component: () => import('./views/baseinfo/contracttype.vue')
            },
            {
                path: '/contracttypeedit',
                meta: {
                    title: '合同类型编辑',
                    bread: 2
                },
                component: () => import('./views/baseinfo/contracttype.edit.vue')
            },
            {
                path: '/userattr',
                meta: {
                    title: '用户属性',
                    bread: 1
                },
                component: () => import('./views/baseinfo/userattr.vue')
            },
            {
                path: '/prodattr',
                meta: {
                    title: '产品属性',
                    bread: 1
                },
                component: () => import('./views/product/prodattr.vue')
            },
            {
                path: '/prodpromotion',
                meta: {
                    title: '图纸促销',
                    bread: 1
                },
                component: () => import('./views/product/prodpromotion.vue')
            },
            {
                path: '/judgetag',
                meta: {
                    title: '评价标签',
                    bread: 1
                },
                component: () => import('./views/baseinfo/judgetag.vue')
            },
            {
                path: '/projectstore',
                meta: {
                    title: '项目库',
                    bread: 1
                },
                component: () => import('./views/project/index.store.vue')
            },
            {
                path: '/projectcode',
                meta: {
                    title: '编号管理',
                    bread: 1
                },
                component: () => import('./views/project/index.code.vue')
            },
            {
                path: '/projectstoretask',
                meta: {
                    title: '项目库编辑',
                    bread: 2
                },
                component: () => import('./views/project/index.store.task.vue')
            },
            {
                path: '/mould',
                meta: {
                    title: '模具管理',
                    bread: 1
                },
                component: () => import('./views/product/mould.vue')
            },
            {
                path: '/mouldedit',
                meta: {
                    title: '模具编辑',
                    bread: 2
                },
                component: () => import('./views/product/mould.edit.vue')
            },
            {
                path: '/mouldattr',
                meta: {
                    title: '模具属性',
                    bread: 1
                },
                component: () => import('./views/product/mouldattr.vue')
            },
            {
                path: '/mouldcolumn',
                meta: {
                    title: '模具栏目',
                    bread: 1
                },
                component: () => import('./views/product/mouldcolumn.vue')
            },
            {
                path: '/designproduct',
                meta: {
                    title: '设计产品管理',
                    bread: 1
                },
                component: () => import('./views/product/designproduct.vue')
            },
            {
                path: '/designproductedit',
                meta: {
                    title: '设计产品编辑',
                    bread: 2
                },
                component: () => import('./views/product/designproduct.edit.vue')
            },
            {
                path: '/designprodattr',
                meta: {
                    title: '设计产品属性',
                    bread: 1
                },
                component: () => import('./views/product/designprodattr.vue')
            },
            {
                path: '/designprodcolumn',
                meta: {
                    title: '设计产品栏目',
                    bread: 1
                },
                component: () => import('./views/product/designprodcolumn.vue')
            },
            {
                path: '/constructionproduct',
                meta: {
                    title: '工程产品',
                    bread: 1
                },
                component: () => import('./views/product/constructionproduct.vue')
            },
            {
                path: '/constructionproductedit',
                meta: {
                    title: '工程产品编辑',
                    bread: 2
                },
                component: () => import('./views/product/constructionproduct.edit.vue')
            },
            {
                path: '/constructionprodattr',
                meta: {
                    title: '工程产品属性',
                    bread: 1
                },
                component: () => import('./views/product/constructionprodattr.vue')
            },
            {
                path: '/constructionprodcolumn',
                meta: {
                    title: '工程产品栏目',
                    bread: 1
                },
                component: () => import('./views/product/constructionprodcolumn.vue')
            },
            {
                path: '/productcolumn',
                meta: {
                    title: '产品栏目',
                    bread: 1
                },
                component: () => import('./views/product/productcolumn.vue')
            },
            ///////////////////////名片////////////////////////////
            {
                path: '/mycardtemp',
                meta: {
                    title: '名片模板',
                    bread: 1
                },
                component: () => import('./views/card/mycardtemp.vue')
            },
            {
                path: '/mycard',
                meta: {
                    title: '个人名片',
                    bread: 1
                },
                component: () => import('./views/card/mycard.vue')
            },
            {
                path: '/companycard',
                meta: {
                    title: '公司官网',
                    bread: 1
                },
                component: () => import('./views/card/companycard.vue')
            },
            {
                path: '/companyvideo',
                meta: {
                    title: '我的视频',
                    bread: 1
                },
                component: () => import('./views/card/companyvideo.vue')
            },
            {
                path: '/companyintrodcard',
                meta: {
                    title: '推文管理',
                    bread:1
                },
                component: () => import('./views/card/companyintrodcard.vue') 
            },
            {
                path: '/companyintrodcardedit',
                meta: {
                    title: '推文编辑',
                    bread:2
                },
                component: () => import('./views/card/companyintrodcard.edit.vue') 
            },
            {
                path: '/employeecard',
                meta: {
                    title: '名片管理',
                    bread: 1
                },
                component: () => import('./views/card/employeecard.vue')
            },
            {
                path: '/gzhmanage',
                meta: {
                    title: '公众号管理',
                    bread: 1
                },
                component: () => import('./views/card/gzhmanage.vue')
            },
            {
                path: '/gzhmanagemenu',
                meta: {
                    title: '公众号菜单',
                    bread: 2
                },
                component: () => import('./views/card/gzhmanage.menu.vue')
            },
            {
                path: '/gzhmanagearticle',
                meta: {
                    title: '公众号文章',
                    bread: 2
                },
                component: () => import('./views/card/gzhmanage.article.vue')
            },
            {
                path: '/gzhmanagearticleedit',
                meta: {
                    title: '公众号文章编辑',
                    bread: 3
                },
                component: () => import('./views/card/gzhmanage.article.edit.vue')
            },
            {
                path: '/gzhmedia',
                meta: {
                    title: '公众号素材',
                    bread: 1
                },
                component: () => import('./views/card/gzhmanage.media.vue')
            },
            {
                path: '/mycenter',
                meta: {
                    title: '个人中心',
                    bread: 1
                },
                component: () => import('./views/personnel/mycenter')
            },
            {
                path: '/kaoqinnotice',
                meta: {
                    title: '考勤通知',
                    bread: 1
                },
                component: () => import('./views/kaoqin/kaoqinnotice')
            },
            {
                path: '/kaoqinnoticeedit',
                meta: {
                    title: '编辑考勤通知',
                    bread: 1
                },
                component: () => import('./views/kaoqin/kaoqinnotice.edit')
            },
            {
                path: '/kaoqininfo',
                meta: {
                    title: '设备信息',
                    bread: 1
                },
                component: () => import('./views/kaoqin/kaoqininfo')
            },
            {
                path: '/kaoqininfoedit',
                meta: {
                    title: '编辑设备信息',
                    bread: 2
                },
                component: () => import('./views/kaoqin/kaoqininfo.edit')
            },
            {
                path: '/kaoqinrecord',
                meta: {
                    title: '打卡记录',
                    bread: 1
                },
                component: () => import('./views/kaoqin/kaoqinrecord')
            },
            {
                path: '/kaoqinad',
                meta: {
                    title: '考勤轮图',
                    bread: 1
                },
                component: () => import('./views/kaoqin/kaoqinad')
            },
            {
                path: '/kaoqincommand',
                meta: {
                    title: '推送列表',
                    bread: 1
                },
                component: () => import('./views/kaoqin/kaoqincommand')
            },
            {
                path: '/kaoqinuser',
                meta: {
                    title: '考勤员工',
                    bread: 1
                },
                component: () => import('./views/kaoqin/kaoqinuser')
            },
            {
                path: '/jobtime',
                meta: {
                    title: '定时任务',
                    bread: 1
                },
                component: () => import('./views/jobmanage/jobtime')
            },
            {
                path: '/jobexcutelog',
                meta: {
                    title: '执行记录',
                    bread: 1
                },
                component: () => import('./views/jobmanage/jobexcutelog')
            },
            {
                path: '/waitexcutetask',
                meta: {
                    title: '待执行任务',
                    bread: 1
                },
                component: () => import('./views/jobmanage/waitexcutetask')
            },
            {
                path: '/questionaire',
                meta: {
                    title: '问卷管理',
                    bread: 1
                },
                component: () => import('./views/questionaire/questionaire')
            },
            {
                path: '/questionaireedit',
                meta: {
                    title: '问卷编辑',
                    bread: 2
                },
                component: () => import('./views/questionaire/questionaire.edit')
            },
            {
                path: '/questionaireproblem',
                meta: {
                    title: '题库管理',
                    bread: 1
                },
                component: () => import('./views/questionaire/questionaire.problem')
            },
            {
                path: '/questionaireproblemedit',
                meta: {
                    title: '题库编辑',
                    bread: 2
                },
                component: () => import('./views/questionaire/questionaire.problem.edit')
            },
            {
                path: '/shopbanner',
                meta: {
                    title: '商品轮播图',
                    bread: 1
                },
                component: () => import('./views/shop/shopbanner')
            },
            {
                path: '/unvalidreason',
                meta: {
                    title: '无效原因',
                    bread: 1
                },
                component: () => import('./views/baseinfo/unvalidreason')
            },
            {
                path: '/shoponlinequestion',
                meta: {
                    title: '在线问答',
                    bread: 1
                },
                component: () => import('./views/shop/shoponlinequestion')
            },
            {
                path: '/shoparticlecategory',
                meta: {
                    title: '文章分类',
                    bread: 1
                },
                component: () => import('./views/shop/shoparticlecategory')
            },
            {
                path: '/shoparticle',
                meta: {
                    title: '文章管理',
                    bread: 1
                },
                component: () => import('./views/shop/shoparticle')
            },
            {
                path: '/shoparticleedit',
                meta: {
                    title: '文章编辑',
                    bread: 1
                },
                component: () => import('./views/shop/shoparticle.edit')
            },
            {
                path: '/shopmanage',
                meta: {
                    title: '商城管理',
                    bread: 1
                },
                component: () => import('./views/shop/shopmanage')
            },
            {
                path: '/shopmanageedit',
                meta: {
                    title: '商城编辑',
                    bread: 2
                },
                component: () => import('./views/shop/shopmanage.edit')
            },
            {
                path: '/shopextendfn',
                meta: {
                    title: '商城扩展功能',
                    bread: 1
                },
                component: () => import('./views/shop/shopextendfn')
            },
            {
                path: '/shopextendfnedit',
                meta: {
                    title: '编辑商城扩展功能',
                    bread: 1
                },
                component: () => import('./views/shop/shopextendfn.edit')
            },
            {
                path: '/peripheralproduct',
                meta: {
                    title: '周边产品管理',
                    bread: 1
                },
                component: () => import('./views/product/peripheralproduct.vue')
            },
            {
                path: '/peripheralproductedit',
                meta: {
                    title: '周边产品编辑',
                    bread: 2
                },
                component: () => import('./views/product/peripheralproduct.edit.vue')
            },
            {
                path: '/peripheralorder',
                meta: {
                    title: '周边订单',
                    bread: 1
                },
                component: () => import('./views/crm/peripheralorder.vue'),
            },
            {
                path: '/peripheralorderedit',
                meta: {
                    title: '周边编辑',
                    bread: 2
                },
                component: () => import('./views/crm/peripheralorder.edit.vue')
            },
            {
                path: '/bankstatement',
                meta: {
                    title: '银行流水',
                    bread: 1
                },
                component: () => import('./views/remittance/bankstatement')
            },
            {
                path: '/dictbank',
                meta: {
                    title: '银行管理',
                    bread: 1
                },
                component: () => import('./views/baseinfo/dictbank')
            },
            {
                path: '/dictbankedit',
                meta: {
                    title: '银行编辑',
                    bread: 1
                },
                component: () => import('./views/baseinfo/dictbank.edit')
            },
            {
                path: '/spreaduser',
                meta: {
                    title: '运营人员',
                    bread: 1
                },
                component: () => import('./views/spread/spreaduser')
            },
            {
                path: '/spreaddata',
                meta: {
                    title: '数据中心',
                    bread: 1
                },
                component: () => import('./views/spread/spreaddata')
            },
            {
                path: '/spreaddataedit',
                meta: {
                    title: '数据编辑',
                    bread: 1
                },
                component: () => import('./views/spread/spreaddata.edit')
            },
            {
                path: '/spreadconsult',
                meta: {
                    title: '咨询中心',
                    bread: 1
                },
                component: () => import('./views/spread/spreadconsult')
            },
            {
                path: '/spreadconsultedit',
                meta: {
                    title: '咨询编辑',
                    bread: 1
                },
                component: () => import('./views/spread/spreadconsult.edit')
            },
            {
                path: '/spreadaccount',
                meta: {
                    title: '账户中心',
                    bread: 1
                },
                component: () => import('./views/spread/spreadaccount')
            },
            {
                path: '/spreadaccountedit',
                meta: {
                    title: '账户编辑',
                    bread: 1
                },
                component: () => import('./views/spread/spreadaccount.edit')
            },
            {
                path: '/spreadfinance',
                meta: {
                    title: '财务中心',
                    bread: 1
                },
                component: () => import('./views/spread/spreadfinance')
            },
            {
                path: '/spreadfinanceedit',
                meta: {
                    title: '财务编辑',
                    bread: 1
                },
                component: () => import('./views/spread/spreadfinance.edit')
            },
            {
                path: '/videomanage',
                meta: {
                    title: '视频管理',
                    bread: 1
                },
                component: () => import('./views/doc/videomanage')
            },
            {
                path: '/customeruser',
                meta: {
                    title: '会员管理',
                    bread: 1
                },
                component: () => import('./views/card/customeruser')
            },
            {
                path: '/customeruserstatis',
                meta: {
                    title: '营销统计',
                    bread: 1
                },
                component: () => import('./views/card/customeruserstatis')
            },
            {
                path: '/onlineconsult',
                meta: {
                    title: '在线咨询',
                    bread: 1
                },
                component: () => import('./views/card/onlineconsult')
            },
            {
                path: '/onlineconsultedit',
                meta: {
                    title: '咨询配置',
                    bread: 2
                },
                component: () => import('./views/card/onlineconsult.edit')
            },
            {
                path: '/orderreport',
                meta: {
                    title: '订单报表',
                    bread: 1
                },
                component: () => import('./views/report/order')
            },
            {
                path: '/drawdesignreport',
                meta: {
                    title: '成品设计报表',
                    bread: 1
                },
                component: () => import('./views/report/drawdesign')
            },
            {
                path: '/qywxuser',
                meta: {
                    title: '企业微信用户管理',
                    bread: 1
                },
                component: () => import('./views/qywx/user')
            },
            {
                path: '/qywxorg',
                meta: {
                    title: '企业微信组织管理',
                    bread: 1
                },
                component: () => import('./views/qywx/org')
            },
            {
                path: '/qywxchatgroup',
                meta: {
                    title: '企业微信群聊管理',
                    bread: 1
                },
                component: () => import('./views/qywx/chatgroup')
            },
            {
                path: '/checkorder',
                meta: {
                    title: '核对订单',
                    bread: 1
                },
                component: () => import('./views/crm/checkorder')
            },
            {
                path: '/spreadrecord',
                meta: {
                    title: '推广记录',
                    bread: 1
                },
                component: () => import('./views/crm/spreadrecord')
            },
            {
                path: '/subscribecustomer',
                meta: {
                    title: '预约客户',
                    bread: 1
                },
                component: () => import('./views/crm/subscribecustomer')
            },
            {
                path: '/roleposition',
                meta: {
                    title: '岗位职责',
                    bread: 1
                },
                component: () => import('./views/personnel/roleposition')
            },
            {
                path: '/onlinesys',
                meta: {
                    title: '咨询系统',
                    bread: 1
                },
                component: () => import('./views/market/onlinesys')
            },
            {
                path: '/onlinesysedit',
                meta: {
                    title: '咨询系统编辑',
                    bread: 2
                },
                component: () => import('./views/market/onlinesys.edit')
            },
            {
                path: 'onlinewxkf',
                meta: {
                    title: '微信客服管理',
                    auth: false,
                    header: false,
                    width: 1100,
                    height: 650
                },
                component: () => import('./views/market/onlinesyswxkf.vue')
            },
            {
                path: 'onlinewxkfedit',
                meta: {
                    title: '微信客服编辑',
                    auth: false,
                    sub: true,
                    width: 1100,
                    height: 650
                },
                component: () => import('./views/market/onlinesyswxkf.edit.vue')
            },
            {
                path: 'constructionkind',
                meta: {
                    title: '工程类型',
                    bread: 1
                },
                component: () => import('./views/baseinfo/constructionkind.vue')
            },
            {
                path: 'constructionkindedit',
                meta: {
                    title: '工程类型编辑',
                    bread: 2
                },
                component: () => import('./views/baseinfo/constructionkind.edit.vue')
            },
            {
                path: 'constructioncompany',
                meta: {
                    title: '工程公司',
                    bread: 1
                },
                component: () => import('./views/construction/constructioncompany.vue')
            },
            {
                path: 'constructioncompanyedit',
                meta: {
                    title: '工程公司编辑',
                    bread: 2
                },
                component: () => import('./views/construction/constructioncompany.edit.vue')
            }
        ]
    }
];
const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes: routes
})
export default router;