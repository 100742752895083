import { defineStore } from 'pinia';
// import {useUserStore} from '@/store/user/user';
import api from '@/service/http';
// import utils from '@/libs/util'
// import configs from '@/service/configs';

export const useTaskStore = defineStore('task', {
  state: () => {
    return {
      tasklist: []
    }
  },
  getters: {
    // getProductColumn(state) {
    //   return function (cache = true) {
    //     if (cache) {
    //       if (state.productcolumnlist.length > 0) {
    //         return new Promise((resolve) => {
    //           resolve(state.productcolumnlist)
    //         });
    //       }
    //     }
    //     return state.getproductcolumnall();
    //   }
    // }
  },
  actions: {
    //获取任务
    async gettask(data, callback) {
      return await api.get('/task', data, callback).then((d) => {
        return d;
      });
    },
    async posttasklist(data, callback) {
      return await api.post('/task/' + data.page + "/" + data.row, data, callback, ["page", "row"]).then(({ res }) => {
        return res;
      });
    },
    async gettasklockbycreateuserid(data, callback) {
      return await api.get('/task/locklistbycreateuserid/' + data.page + '/' + data.row, data, callback, ["page", "row"]).then((d) => {
        return d;
      });
    },
    async postteamtasklist(data, callback) {
      return await api.post('/task/team/' + data.page + "/" + data.row, data, callback, ["page", "row"]).then(({ res }) => {
        return res;
      });
    },
    async getforgettaskstats(callback) {
      return await api.get('/task/forgetstats', "", callback).then((d) => {
        return d;
      });
    },
    async getforgettask(data, callback) {
      return await api.get('/task/forget/' + data.userId + '/' + data.page + '/' + data.row, data, callback, ["page", "row", "userId"]).then((d) => {
        return d;
      });
    },
    async addtask(data, callback) {
      return await api.post('/task', data, callback).then(({ res }) => {
        return res;
      });
    },
    async gettaskdetail(data, callback) {
      return await api.get('/task/' + data, "", callback).then((d) => {
        return d;
      });
    },
    async gettaskdetailwithcustomer(data, callback) {
      return await api.get('/task/withcustomer/' + data.id + "/" + data.kind, "", callback).then((d) => {
        return d;
      });
    },
    //待执行任务
    async getwaitexcutetask(data, callback) {
      return await api.get('/waitexcutetask/' + data.page + "/" + data.row, data, callback, ["page", "row"]).then((d) => {
        return d;
      });
    },
    async delwaitexcutetask(data, callback) {
      return await api.post('/waitexcutetask/delete', data, callback).then(({ res }) => {
        return res;
      });
    },
    async addwaitexcutetask(data, callback) {
      return await api.post('/waitexcutetask', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getwaitexcutetaskdetail(data, callback) {
      return await api.get('/waitexcutetask/detail/' + data, "", callback).then((d) => {
        return d;
      });
    },
    async setwaitexcutetaskview(data, callback) {
      return await api.post('/waitexcutetask/setview/' + data.id + '/' + data.type, {}, callback).then(({ res }) => {
        return res;
      });
    },
    async setwaitexcutetaskstatus(data, callback) {
      return await api.post('/waitexcutetask/setstatus/' + data, {}, callback).then(({ res }) => {
        return res;
      });
    },
    async findwaitexcutetaskuser(data, callback) {
      return await api.get('/waitexcutetask/finduser/' + data, "", callback).then((response) => {
        return response.data;
      });
    },
    async gettaskpublicsea(data, callback) {
      return await api.get('/task/publicsea/' + data.page + "/" + data.row, data, callback, ["page", "row", "params"]).then((d) => {
        return d;
      });
    },
    async gettaskinpublicsea(data, callback) {
      return await api.get('/task/inpublicsea', data, callback).then((d) => {
        return d;
      });
    },
    async postaftertasklist(data, callback) {
      return await api.post('/aftertask/' + data.page + "/" + data.row, data, callback, ["page", "row", "params"]).then(({ res }) => {
        return res;
      });
    },
    async postteamaftertasklist(data, callback) {
      return await api.post('/aftertask/team/' + data.page + "/" + data.row, data, callback, ["page", "row", "params"]).then(({ res }) => {
        return res;
      });
    },
    async bindaftertaskcustomer(data, callback) {
      return await api.post('/aftertask/bindcustomer', data, callback).then(({ res }) => {
        return res;
      });
    },
    async updateaftertaskstate(data, callback) {
      return await api.put('/aftertask/updatestate', data, callback).then(({ res }) => {
        return res;
      });
    },
    async delaftertask(data, callback) {
      return await api.post('/aftertask/delete', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getaftertaskdetailbycontact(data, callback) {
      return await api.get('/aftertask/bycontact/' + data, "", callback).then((d) => {
        return d;
      });
    },
    async getaftertaskdetail(data, callback) {
      return await api.get('/aftertask/' + data, "", callback).then((d) => {
        return d;
      });
    },
    async gettaskdetailbycontact(data, callback) {
      return await api.get('/task/bycontact/' + data, "", callback).then((d) => {
        return d;
      });
    },
    async gettaskbycontactinself(data, callback) {
      return await api.get('/task/bycontact/inself/' + data, "", callback).then((d) => {
        return d;
      });
    },
    async gettaskclearcontacttxt(data, callback) {
      return await api.get('/task/clearcontacttxt/' + data, "", callback).then((d) => {
        return d;
      });
    },
    async getvisitrecord(data, callback) {
      return await api.get('/visitrecord/' + data.bizId + '/' + data.page + '/' + data.row, "", callback).then((d) => {
        return d;
      });
    },
    async getvisitrecordbyuserid(data, callback) {
      return await api.get('/visitrecord/byuserid/' + data.page + '/' + data.row, data, callback, ["page", "row"]).then((d) => {
        return d;
      });
    },
    async getvisitrecordstatsbyuser(data, callback) {
      return await api.get('/visitrecord/statsbyuserid/' + data.page + '/' + data.row, data, callback, ["page", "row"]).then((d) => {
        return d;
      });
    },
    async getvisitrecordbyadduserid(data, callback) {
      return await api.get('/visitrecord/byadduserid/' + data.page + '/' + data.row, data, callback, ["page", "row"]).then((d) => {
        return d;
      });
    },
    async getvisitrecordconcat(data, callback) {
      return await api.get('/visitrecord/concat/' + data, "", callback).then((d) => {
        return d;
      });
    },
    async addvisitrecord(data, callback) {
      return await api.post('/visitrecord', data, callback).then((d) => {
        return d;
      });
    },
    async updateaftertaskconstrucioninfo(data, callback) {
      return await api.put('/aftertask/updateconstructioninfo', data, callback).then(({ res }) => {
        return res;
      });
    },
    async postbinddealcustomer(data, callback) {
      return await api.post('/task/binddealcustomer', data, callback).then(({ res }) => {
        return res;
      });
    },
    async gettaskungetconsult(data, callback) {
      return await api.get('/task/ungetconsult/' + data.page + '/' + data.row, data, callback, ["page", "row"]).then((d) => {
        return d;
      });
    },
    async reassigntask(data, callback) {
      return await api.post('/task/reassignvalid', data).then(({ res }) => {
        return res;
      });
    }
  }
})