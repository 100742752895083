<template>
    <div ref="findinputbox" class="findinputbox" style="display:inline-block;">
        <Input v-model="value" :placeholder="placeholder || '请输入'" @on-blur="bind" :disabled="view"
            :style="{ 'width': width }"></Input>
        <div v-if="shake" class="findinput" :class="findinputshake ? ' findinput_shake' : ''">
            &nbsp;<i class="iconfont icon-lianjie-tianchong" @click="showDetail"></i>{{ text }}
        </div>
        <slot></slot>
    </div>
</template>

<script>
import { useCommsStore } from "@/store/user/user";
import { storeToRefs } from "pinia";

import "./style.scss";
export default {
    name: 'FindInput',
    setup() {
        const commsstore = useCommsStore();
        const { webmodel } = storeToRefs(commsstore);
        return {
            webmodel,
            setwebmodel: commsstore.setwebmodel
        }
    },
    props: {
        modelValue: Object,
        placeholder: {
            type:String,
            default: "请输入"
        },
        bind: {
            type: Function
        },
        width: {
            type:String,
            default: "200px"
        },
        view: {
            type:Boolean,
            default: false
        },
        shake: {
            type:Boolean,
            default: false
        }
    },
    data() {
        return {
            findinputshake: false,
            timer: null,
            drawerstate: false,
            state: false,
            orderstate: false,
            text: "",
            operation: {
                action: ""
            }
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            }
        }
    },
    mounted() {
    },
    methods: {
        showDetail() {
            this.setwebmodel({ state: true, path: "/findunsettlecustomer", query: { contact: this.value } });
        },
        checkuserphone(phone) {
            var reg = /^1[0-9]{10}$/;
            if (!reg.test(phone)) {
                this.$Message.error("请填写正确的手机号码");
                return false;
            }
        }
    },
    destroyed() {
        clearInterval(this.timer);
    },
    watch: {
        "shake": function () {
            if (this.shake) {
                this.findinputshake = this.shake;
                this.timer = setInterval(() => {
                    this.findinputshake = !this.findinputshake;
                }, 1000);
            }
            else {
                clearInterval(this.timer);
            }
        }
    }
}
</script>
<style lang="scss" scoped>

</style>