import { defineStore } from 'pinia';
// import {useUserStore} from '@/store/user/user';
import api from '@/service/http';
// import configs from '@/service/configs';

export const useChatStore = defineStore('chat', {
  state: () => {
    return {
     dialoglist:[],
     contentlist:[],
     userlist:[],
     customeruserlist:[]
    }
  },
  getters: {
    getDialogList(state) {
      return function (data,cache = true) {
        if (cache) {
          if (state.dialoglist.length > 0) {
            return new Promise((resolve) => {
              resolve(state.dialoglist)
            });
          }
        }
        return state.getchatdialog(data);
      }
    },
    getContnetList(state) {
      return function (data,cache = true) {
        if (cache) {
          if (state.contentlist.length > 0) {
            return new Promise((resolve) => {
              resolve(state.contentlist)
            });
          }
        }
        return state.getchat(data);
      }
    }
  },
  actions: {
    //===========根据组Id获取聊天信息===========
    async getchatbygroupid(data,callback){
        return await api.get('/chat/bygroupid',data,callback).then((d) => {
            return d;
        });
    },
    async getchat(data,callback){
        return await api.get('/chat/all/'+data.flag+"/"+data.isCache+"/"+data.bizId+"/"+data.page+"/"+data.row,data,callback,["flag","page","row","isCache","bizId"]).then(({res}) => {
            return res;
        });
    },
    async getcustomerchat(data,callback){
        return await api.get('/shopcustomerchat/'+data.bizId+"/"+data.page+"/"+data.row,data,callback,["bizId","page","row","flag"]).then((d) => {
            return d;
        });
    },
    async getchatdialog(data,callback){
        return await api.get('/chat/dialog/'+data.page+"/"+data.row,data,callback,["page","row"]).then((d) => {
            return d;
        });
    },
    async getcustomerchatdialog(data,callback){
        return await api.get('/shopcustomerchat/dialog/'+data.page+"/"+data.row,data,callback,["page","row"]).then((d) => {
            return d;
        });
    },
    async finddialogid(data,callback){
        return await api.post('/chat/dialog',data,callback).then(({res}) => {
            return res;
        });
    },
    async findcustomerdialogid(data,callback){
        return await api.post('/shopcustomerchat/user',data,callback).then(({res}) => {
            return res;
        });
    },
    async getgroupdialog(data,callback){
        return await api.get('/chat/groupdialog/'+data.toBizId+"/"+data.pageindex+"/"+data.pagesize,data,callback).then((d) => {
            return d;
        });
    },
    async getchatgroup(callback){
        return await api.get('/chat/group',"",callback).then((d) => {
            return d;
        });
    },
    async editchatgroup(data,callback){
        return await api.post('/chat/editgroup',data,callback).then(({res}) => {
            return res;
        });
    },
    async adduserchat(data,callback){
        return await api.post('/chat/user',data,callback).then(({res}) => {
            return res;
        });
    },
    async addcustomeruserchat(data,callback){
        return await api.post('/shopcustomerchat',data,callback).then(({res}) => {
            return res;
        });
    },
    async ddcustomerkfchat(data,callback){
        return await api.post('/weservice/sendwxkfmsg',data,callback).then(({res}) => {
            return res;
        });
    },
    async addgroupchat(data,callback){
        return await api.post('/chat/group',data,callback).then(({res}) => {
            return res;
        });
    },
    async getchatgroupuser(data,callback){
        return await api.get('/chat/GroupUser/'+data.groupId,"",callback).then((d) => {
            return d;
        });
    },
    async addchatgroupuser(data,callback){
        return await api.post('/chat/addGroupUser/'+data.groupId,data,callback).then(({res}) => {
            return res;
        });
    },
    async getchatgroupdetail(data,callback){
        return await api.get('/chat/groupdetail/'+data.groupId,"",callback).then((d) => {
            return d;
        });
    },
    async dissolvegroup(data,callback){
        return await api.get('/chat/dissolvegroup/'+data.groupId,data,callback).then((d) => {
            return d;
        });
    },
    // async updatedialog(data,callback){
    //     //type:  'user'用户会话  'group'群组会话
    //     return response.data;
    // },
    async removedialog(data,callback){
        return await api.get('/chat/removedialog/'+data.id,data,callback).then(({res}) => {
            return res;
        });
    },
    async cleardialog(data,callback){
        return await api.get('/chat/cleardialog/'+data.flag+'/'+data.id,data,callback).then((d) => {
            return d;
        });
    },
    async customerchatisread(data,callback){
        return await api.post('/shopcustomerchat/isRead',data,callback).then(({res}) => {
            return res;
        });
    },
    async chatisread(data,callback){
        return await api.post('/chat/isRead',data,callback).then(({res}) => {
            return res;
        });
    },
    async chatisreadbycustomerid(data,callback){
        return await api.get('/chat/isReadbycustomerid/'+data.id,"",callback).then((d) => {
            return d;
        });
    },
    async yesterdaykaoqinrecordreport(data,callback){
        return await api.get('/report/yesterdaykaoqinrecord/'+data.date,data,callback,["date"]).then((d) => {
            return d;
        });
    },
    async yesterdaysalescustomerreport(data,callback){
        return await api.get('/report/yesterdaysalescustomer/'+data.date,data,callback,["date"]).then((d) => {
            return d;
        });
    },
    async yesterday53servicescustomerreport(data,callback){
        return await api.get('/report/yesterday53servicescustomer/'+data.date,data,callback,["date"]).then((d) => {
            return d;
        });
    },
    async yesterdayperformancereport(data,callback){
        return await api.get('/report/yesterdayperformance/'+data.date,data,callback,["date"]).then((d) => {
            return d;
        });
    },
    async getchatunreadnum(data,callback){
        return await api.get('/chat/unreadnum',"",callback).then((d) => {
            return d;
        });
    },
    async getcheckchatstatus(data,callback){
        return await api.get('/chatstatus/check',data,callback).then((d) => {
            return d;
        });
    },
    async getcheckchatprivatestatus(data,callback){
        return await api.get('/chatstatus/privatecheck',data,callback).then((d) => {
            return d;
        });
    }
  }
})