<style lang="scss" scoped>
.btnbox {
    display: flex;
    height: 32px;
    margin-bottom: 5px;

    .boxleft,
    .boxright {
        box-sizing: border-box;
    }

    .boxleft {
        flex: 1;

        button,
        >div {
            margin-right: 5px;
        }
    }

    .boxright {
        width: 260px;
        text-align: center;
        // margin-right:10px;
    }
}
</style>
<style>
.boxleft button {
    margin-right: 5px;
}

.boxleft>div {
    margin-right: 5px;
}
</style>
<template>
    <div class="btnbox">
        <div class="boxleft">
            <slot name="left"></slot>
        </div>
        <div class="boxright" v-if="!nodaterange">
            <div style="float:right;">
                <slot name="right"></slot>
            </div>
            <Poptip v-if="refresh" trigger="hover" content="列表刷新" style="float:right;margin:8px 5px;" placement="left">
                <Icon type="ios-refresh" class="advancefilter" size="18" @click="refreshHandle"
                    style="float:right;margin:0px 5px;"></Icon>
            </Poptip>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BtnBox',
    props: {
        refresh: Function
    },
    components: {},
    data() {
        return {

        }
    },
    mounted() {
    },
    methods: {
        refreshHandle(){
            this.refresh();
        }
    }
}
</script>

