
<style>
.ueditor .ivu-form-item-content {
  line-height: 0px !important;
}
</style>
<template>
  <div>
    <div v-if="!view" :style="{ 'width': (width || '500px') }">
      <VueUeditorWrap v-model="value" :config="config" editor-id="editor-demo-01"></VueUeditorWrap>
      <!-- <vue-ueditor-wrap v-model="msg" :config="config" @ready="ready"></vue-ueditor-wrap> -->
    </div>
    <div v-else-if="!value">无</div>
    <div v-else v-html="value"></div>
  </div>
</template>
<script>
// import VueUeditorWrap from 'vue-ueditor-wrap'
export default {
  name: 'FormEditor',
  props: {
    view: {
      type: Boolean,
      default: false
    },
    modelValue: String,
    width: Number
  },
  data() {
    return {
      editorobj: "",
      msg: "",
      config: {
        autoHeightEnabled: false,
        initialFrameWidth: "100%",
        initialFrameHeight: 350,
        autoFloatEnabled: false,
        UEDITOR_HOME_URL: '/static/UEditor/',
        // serverUrl: '//ueditor.zhenghaochuan.com/cos'
      }
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      }
    }
  },
  // components:{
  //     VueUeditorWrap
  // },
  mounted() {
  },
  methods: {
    // ready(editorInstance) {
    //   this.editorobj = editorInstance;
    //   if (this.value) {
    //     setTimeout(() => {
    //       this.msg = this.value;
    //     }, 100);
    //   }
    // }
  },
  destroyed() {
  }
}
</script>

