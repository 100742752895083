<style lang="scss" scoped>

</style>
<template>
  <Image :src="src" @on-load="loadSuccessHandle" @on-erorr="loadErrorHandle" style="width:100%;height:100%;" v-if="src">
    <template #error>
      <img :src="errorimg" style="width:100%;height:100%;" />
    </template>
  </Image>
  <img v-else :src="errorimg" :alt="type"/>
</template>

<script>
export default {
  name: 'ImageLazy',
  props: {
    src: {
      type: String,
      defualt: ""
    },
    type: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      success: false,
      errorimg: "",
      img: ""
    }
  },
  mounted() {
    if (this.type == "tx") {
      this.errorimg = this.static+"/default_tx1.jpg";
    }
    else if (this.type == "zl") {
      this.errorimg =this.static+"/zl.png";
    }
    else if (this.type == "report") {
      this.errorimg = this.static+"/report.png";
    }
    else if (this.type == "leida") {
      this.errorimg = this.static+"/leida.png";
    }
    else if (this.type == "tz") {
      this.errorimg = this.static+"/tz.png";
    }
    else if(this.type == "noimg"){
      this.errorimg =this.static+"/noimg.jpg";
    }
  },
  methods: {
    loadSuccessHandle() {
      this.success = false;
    },
    loadErrorHandle() {
      this.success = true;
    }
  }
}
</script>

