<template>
  <router-view></router-view>
</template>

<script>
import "./default.scss";
// import HelloWorld from './components/HelloWorld.vue'
export default {
  name: 'App',
  components: {
  }
}
</script>


