<style lang="scss" scoped>
@import '../../views/webmodel/index.scss';
</style>
<template>
    <div class="webmodel">
        <div class="webmodel-content">
            <slot name="content"></slot>
        </div>
        <div class="webmodel-footer">
            <div style="float:left;">
              <slot name="page"></slot>
            </div>
            <Button @click="closewebmodel" icon="md-close" style="float:right;margin-right:10px;">关闭</Button>
            <slot name="okbtn"></slot>
            <br style="clear:both;" />
        </div>
    </div>
</template>

<script>
import { webmodelmixins } from '../../views/webmodel/webmodelmixins'
import { useCommsStore } from "@/store/user/user";

export default {
  name: 'DynamicContainerChildWebModel',
  setup() {
     const commsstore = useCommsStore();
     return {
       closewebmodel:commsstore.closewebmodel
     }
  },
  
  mixins:[webmodelmixins],
  data () {
    return {
       
    }
  },
  computed:{
  },
  mounted(){
  },
  methods:{
    
  },
  watch:{
    
      
  }
}
</script>
