<style lang="scss" scoped>
.statsdaterange,
.statsdaterangemin {
    display: inline-block;
    cursor: pointer;
    width: 170px;
    height: 32px;
    background-color: white;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    position: relative;
    transition: all 200ms ease;

    span.result {
        position: absolute;
        top: 7px;
        left: 5px;
    }

    .list {
        position: absolute;
        width: 280px;
        background-color: white;
        z-index: 998;
        right: 0px;
        top: 31px;
        border: 1px solid #ebebeb;
        border-radius: 3px;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
        padding-top: 10px;

        ul {
            width: 95%;
            margin: auto;

            li {
                list-style-type: none;
                width: 25%;
                height: 30px;
                line-height: 30px;
                text-align: left;
                float: left;
                padding-left: 5px;
                font-size: 13px;
            }

            li.t {
                color: #999;
            }

            .active {
                background-color: #407FFF;
                padding: 3px;
                color: white;
                border-radius: 3px;
            }
        }

        .custom {
            width: 100%;
            padding: 10px;
            background-color: #F4F6FC;
            clear: both;

            a {
                color: #91959E;
            }

            a.active {
                color: #2d8cf0;
            }

            .datebox {
                margin-top: 5px;
            }
        }

        .btnbox {
            clear: both;
            background-color: white;
            padding: 10px;
        }
    }

    .list-right {
        left: 170px;
        top: 0px;
    }

    .list-top {
        top: -300px;
    }
}

.statsdaterange:hover {
    border: 1px solid #2b85e4;
}

.statsdaterangemin {
    display: inline-block;
    width: auto;
    padding: 5px 7px;
    border-left: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    border-bottom-left-radius: 10px;
    background-color: #5cadff;
    color: white;

    span.result {
        position: relative;
        top: -1px;
        left: 0px;
        font-size: 13px;
    }

    .list {
        left: -225px;
        top: 31px;
        color: #657180;
    }
}

.statsdaterange {
    div.ivu-date-picker-editor {
        width: 128px !important;
    }
}
</style>

<template>
    <div :class="min ? 'statsdaterangemin' : 'statsdaterange'" :style="{
        'border-top-left-radius': radius.topleft == 0 ? '0px' : '5px',
        'border-top-right-radius': radius.topright == 0 ? '0px' : '5px',
        'border-bottom-left-radius': radius.bottomleft == 0 ? '0px' : '5px',
        'border-bottom-right-radius': radius.bottomright == 0 ? '0px' : '5px'
    }" @click="state = true">
        <span class="result">
            <Icon type="ios-calendar-outline" />&nbsp;{{ daterangetxt }}
        </span>
        <div class="list " :class="(dirct == 'right') ? 'list-right' : (dirct == 'top' ? 'list-top' : '')"
            v-show="state">
            <ul>
                <li class="t">天</li>
                <li @click="daterange = -1, daterangetxttemp = '不限', starttime = '', endtime = ''" v-if="infinite"><span
                        :class="daterange == -1 ? 'active' : ''">不限</span></li>
                <li @click="daterange = 0, daterangetxttemp = '今天', starttime = '', endtime = ''"><span
                        :class="daterange == 0 ? 'active' : ''">今天</span></li>
                <li @click="daterange = 1, daterangetxttemp = '昨天', starttime = '', endtime = ''"><span
                        :class="daterange == 1 ? 'active' : ''">昨天</span></li>

                <li class="t" style="clear:both;">周</li>
                <li @click="daterange = 3, daterangetxttemp = '本周', starttime = '', endtime = ''"><span
                        :class="daterange == 3 ? 'active' : ''">本周</span></li>
                <li @click="daterange = 4, daterangetxttemp = '上周', starttime = '', endtime = ''"><span
                        :class="daterange == 4 ? 'active' : ''">上周</span></li>
                <li @click="daterange = 2, daterangetxttemp = '近7天', starttime = '', endtime = ''"><span
                        :class="daterange == 2 ? 'active' : ''">近7天</span></li>

                <li class="t" style="clear:both;">月</li>
                <li @click="daterange = 6, daterangetxttemp = '本月', starttime = '', endtime = ''"><span
                        :class="daterange == 6 ? 'active' : ''">本月</span></li>
                <li @click="daterange = 7, daterangetxttemp = '上月', starttime = '', endtime = ''"><span
                        :class="daterange == 7 ? 'active' : ''">上月</span></li>
                <li @click="daterange = 8, daterangetxttemp = '近30天', starttime = '', endtime = ''"><span
                        :class="daterange == 8 ? 'active' : ''">近30天</span></li>

                <li class="t" style="clear:both;">季度</li>
                <li @click="daterange = 9, daterangetxttemp = '本季度', starttime = '', endtime = ''"><span
                        :class="daterange == 9 ? 'active' : ''">本季度</span></li>
                <li @click="daterange = 10, daterangetxttemp = '上一季度', starttime = '', endtime = ''"><span
                        :class="daterange == 10 ? 'active' : ''">上一季度</span></li>
                <li></li>
                <!-- <li @click="daterange=11,daterangetxttemp='下一季度',starttime='',endtime=''"><span :class="daterange==11?'active':''">下一季度</span></li> -->
                <li class="t" style="clear:both;">年度</li>
                <li @click="daterange = 12, daterangetxttemp = '本年度', starttime = '', endtime = ''"><span
                        :class="daterange == 12 ? 'active' : ''">本年度</span></li>
                <li @click="daterange = 13, daterangetxttemp = '上一年度', starttime = '', endtime = ''"><span
                        :class="daterange == 13 ? 'active' : ''">上一年度</span></li>
                <li></li>
                <!-- </template> -->
                <!-- <li @click="daterange=14,daterangetxttemp='下一年度',starttime='',endtime=''"><span :class="daterange==14?'active':''">下一年度</span></li> -->
            </ul>
            <div class="custom">
                <a href="javascript:void(0);">自定义</a>
                <div class="datebox">
                    <DatePicker type="date" v-model="starttime" @on-clear="clearDate" placeholder="开始时间"
                        style="width: 125px"></DatePicker>
                    <DatePicker type="date" v-model="endtime" @on-clear="clearDate" placeholder="结束时间"
                        style="width: 125px"></DatePicker>
                </div>
            </div>
            <div class="btnbox">
                <Button type="info" style="float:right;margin-bottom:10px;" @click="submit">确定</Button>
                <Button style="float:right;margin-bottom:10px;margin-right:10px;" @click="cancel">取消</Button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StatsDateRange',
    props: {
        modelValue: String,
        min: Boolean,
        dirct: String,
        infinite: Boolean,
        radius: {
            type: Object,
            default: () => {
                return {
                    topright: 1,
                    bottomright: 1,
                    topleft: 1,
                    bottomleft: 1
                }

            }
        }
    },
    data() {
        return {
            array: ["今天", "昨天", "近7天", "本周", "上周", "", "本月", "上月", "近30天", "本季度", "上一季度", "下一季度", "本年度", "上一年度", "下一年度"],
            daterange: "-1",
            daterangetxt: "",
            state: false,
            single: false,
            starttime: "",
            endtime: "",
            type: "-1"
        }
    },
    // computed: {
    //     value: {
    //         get() {
    //             return this.value;
    //         },
    //         set(value) {
    //             this.type = value;
    //             this.$emit("update:modelValue", value);
    //         }
    //     }
    // },
    mounted() {
        setTimeout(() => {
            this.daterange = this.modelValue;
            if (this.daterange.toString().split("~").length > 1) {
                this.daterangetxt = this.daterange;
            }
            else {
                if (parseInt(this.daterange) > -1) {
                    this.daterangetxt = this.array[parseInt(this.daterange)];
                }
                else {
                    this.daterangetxt = "不限";
                }
            }
        }, 0);
    },
    methods: {
        submit() {
            setTimeout(() => {
                this.state = false;
                if (this.starttime && this.endtime) {
                    this.daterange = this.formatTime(this.starttime) + "~" + this.formatTime(this.endtime);
                    this.daterangetxt = this.daterange;
                }
                else {
                    this.daterangetxt = this.daterangetxttemp;
                }
                this.$emit("update:modelValue", this.daterange);
                this.$emit("onChange", {
                    daterange: this.daterange,
                    daterangetxttemp: this.daterangetxttemp,
                    starttime: this.starttime,
                    endtime: this.endtime
                });
            }, 10);
        },
        cancel() {
            setTimeout(() => {
                this.state = false;
            }, 10);
        },
        formatTime(date) {
            let _moth = date.getMonth() + 1;
            return date.getFullYear() + "-" + this.addZero(_moth) + "-" + this.addZero(date.getDate());
        },
        addZero(d) {
            return d < 10 ? ("0" + d) : d;
        },
        clearDate() {
            this.starttime = "";
            this.endtime = "";
            this.daterange = "0";
            this.daterangetxttemp = this.array[parseInt(this.daterange)];
            this.$emit("update:modelValue", this.daterange);
        }
    },
    watch: {
        starttime: function () {
            if (this.starttime) {
                this.daterange = "-1";
                if (!this.endtime) {
                    this.endtime = this.starttime;
                }
            }
        },
        endtime: function () {
            if (this.starttime) {
                this.daterange = "-1";
            }
        },
        "value": function () {
            this.daterange = this.value;
            if (this.daterange.toString().split("~").length > 1) {
                this.daterangetxt = this.daterange;
            }
            else {
                if (parseInt(this.daterange) > -1) {
                    this.daterangetxt = this.array[parseInt(this.daterange)];
                }
                else {
                    this.daterangetxt = "不限";
                }
            }
        }
    }
}
</script>

