import StatsDateRanage from './statsdaterange/index'; //首页时间选择器
import FastBox from './fastbox/index'; //快捷方式
import MenuType from './menutype/index'; //菜单分组方式
import NavFuncArea from './navfuncarea/index';//功能块区域(我的,全局搜索,消息通知)
import Menus from './menu/index'; //菜单
import BreadCrumb from './breadcrumb/index'//面包屑
import FindInput from './findinput/index'//重构输入框,在失去焦点的时候,会调用接口，找输入的内容是否已经存在,且有按钮查看找到的内容详情
import FormUpload from './formupload/index' //编辑中的图片上传
import FormEditor from './formeditor/index' //编辑中的富文本编辑器
import FormBtn from './formbtn/index' //编辑中的提交和返回按钮 
import FormArea from './formarea/index' //编辑中的省份\城市\区域选择框
import FormTime from './formtime/index' //编辑中的时间选择器
// import TTable from './ttable/index' //二次封装表格(tabs+table+btn+search+pager)
import FormLevel from './formlevel/index' //任务优先级
import FormPhones from './formphones/index' //任务优先级
import FormAuditor from './formauditor/index' //编辑中的审核下拉框
import Memorandum from './memorandum/index'
import AutoRemittance from './autoremittance/index'; //自动审核规则
import CompleteCustomerInfoModal from './completecustomerinfomodal/index';
import WebModel from './webmodel/index'
import TableTabbs from './tabletabs/index'
import DynamicContainer from './dynamiccontainer/index'
import BtnBox from './btnbox/index'
import ImageLazy from './imagelazy/index'


// import ImgTxtInput from './imgtxtinput/index'; //订单交易码上传输入框

const impression = {
  StatsDateRanage,
  FastBox,
  MenuType,
  NavFuncArea,
  Menus,
  BreadCrumb,
  FindInput,
  FormUpload,
  FormEditor,
  FormBtn,
  FormArea,
  FormTime,
  ImageLazy,
  // TTable,
  FormLevel,
  FormPhones,
  FormAuditor,
  Memorandum,
  AutoRemittance,
  CompleteCustomerInfoModal,
  WebModel,
  TableTabbs,
  DynamicContainer,
  BtnBox
  // ImgTxtInput,
}
const qtystool = {
  install : function(Vue){
    Object.keys(impression).forEach(key => {
      Vue.component(impression[key].name, impression[key]);
    });
  }
}
export default qtystool