
<template>
    <Modal v-model="value" width="830" :styles="{ 'top': '10px' }" :mask-closable="false" :closable="false">
        <template #header>
            <h3>高级搜索</h3>
        </template>
        <div style="padding:10px 0px;">
            <Form :model="formItem" ref="formItem" :label-width="80" inline>
                <FormItem label="开始时间">
                    <DatePicker type="date" v-model="formItem.startTime" placeholder="请选择开始时间" style="width: 180px">
                    </DatePicker>
                </FormItem>
                <FormItem label="结束时间">
                    <DatePicker type="date" v-model="formItem.endTime" placeholder="请选择结束时间" style="width: 180px">
                    </DatePicker>
                </FormItem>
                <FormItem label="订单状态">
                    <Select style="width:180px" v-model="formItem.eState">
                        <Option v-for="item in defaulttabs" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="商品套餐">
                    <Select style="width:180px" clearable filterable v-model="formItem.prodPackage">
                        <Option v-for="item in combolist" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="买家姓名">
                    <Input v-model="formItem.customerName" style="width:180px"></Input>
                </FormItem>
                <FormItem label="销售姓名"
                    v-if="(user.code == 'BOSS' || user.code == 'SUPERADMIN' || (user.code == 'AFTERSALES' && user.groupLeader == 'Y'))">
                    <Input v-model="formItem.saleUserName" readonly style="width:180px">
                    <Button slot="append" icon="ios-search"
                        @click="setcomms({ webmodel: { state: true, path: '/userlist', query: { companyId: user.companyId } } })"></Button>
                    </Input>
                </FormItem>
                <FormItem label="商品编号">
                    <Input v-model="formItem.prodCode" style="width:180px"></Input>
                </FormItem>
                <FormItem label="省份">
                    <Select style="width:180px" clearable filterable v-model="formItem.provinceId"
                        @on-change="provinceChangeHandle">
                        <Option v-for="item in provincelist" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="市区">
                    <Select style="width:180px" clearable filterable v-model="formItem.cityId">
                        <Option v-for="item in citylist" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="联系方式">
                    <Input v-model="formItem.customerPhone" style="width:180px"></Input>
                </FormItem>
                <FormItem label="快递公司">
                    <Input v-model="formItem.expressName" style="width:180px">
                    <Button slot="append" icon="ios-search"
                        @click="setcomms({ webmodel: { state: true, path: '/express' } })"></Button>
                    </Input>
                </FormItem>
                <FormItem label="快递单号">
                    <Input v-model="formItem.expressNum" style="width:180px"></Input>
                </FormItem>
                <FormItem label="客户来源">
                    <Select style="width:180px" clearable filterable v-model="formItem.source">
                        <Option v-for="item in sourcelist" :value="item.sourceId" :key="item.sourceId">{{ item.name }}
                        </Option>
                    </Select>
                </FormItem>
                <FormItem label="支付方式">
                    <Select style="width:180px" clearable filterable v-model="formItem.payway">
                        <Option v-for="item in paywaylist" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="编号范围">
                    <InputNumber v-model="formItem.startSort" style="width:83px"></InputNumber>
                    &nbsp;-&nbsp;
                    <InputNumber v-model="formItem.endSort" style="width:82px"></InputNumber>
                </FormItem>
            </Form>
        </div>
        <template #footer>
            <Button icon="md-close" @click="$emit('input', false)">关闭</Button>
            <Button type="primary" icon="md-checkmark" @click="submitSearchHandle">确定</Button>
        </template>
    </Modal>
</template>
<script>
import utils from '@/libs/util.js';
import { useUserStore, useCommsStore } from "@/store/user/user";
import { useBaseInfoStore } from "@/store/crm/baseinfo";
import { storeToRefs } from 'pinia';
export default {
    name: "OrderAdvanceFilter",
    setup() {
        const userstore = useUserStore();
        const { user } = storeToRefs(userstore);
        const commsstore = useCommsStore();
        const baseinfostore = useBaseInfoStore();
        const { webmodel, selected } = storeToRefs(commsstore);
        const { sourcelist, paywaylist} = storeToRefs(baseinfostore);
        return {
            user,
            webmodel,
            selected,
            baseinfostore,
            sourcelist,
            paywaylist,
            setwebmodel: commsstore.setwebmodel
        }
    },
    data() {
        return {
            provincelist: [],
            citylist: [],
            combolist: [],
            formItem: {
                startTime: "",
                endTime: "",
                eState: "-1",
                prodPackage: 0,
                customerName: "",
                saleUserId: "",
                saleUserName: "",
                prodCode: "",
                provinceId: 0,
                cityId: 0,
                customerPhone: "",
                expressId: "",
                expressName: "",
                expressNum: "",
                source: "",
                payway: "",
                startSort: 0,
                endSort: 0
            }
        }
    },
    props: {
        modelValue: Array,
        clearData: Function
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            }
        }
    },
    mounted() {
    },
    methods: {
        advanceSearchHandle() {
            this.advancestate = true;
            //加载身份
            if (this.provincelist.length == 0) {
                this.getarea(0).then(d => {
                    if (d.code == 0) {
                        this.provincelist = d.datas;
                    }
                });
            }
            //商品套餐
            // if (this.combolist.length == 0) {
            //     this.baseinfostore.getProductComboList(0);
            // }
            //客户来源
            this.baseinfostore.getSourceList({ page: 1, row: 50 });
            //支付方式
            this.baseinfostore.getPayWayList({ page: 1, row: 50, state: 0, flag: 0 })
        },
        submitSearchHandle() {
            let _filter = [];
            for (let key in this.formItem) {
                if (this.formItem[key]) {
                    if (key == "customerName") {
                        _filter.push({
                            name: "customerName",
                            condition: 2,
                            value: this.formItem[key],
                            label: "客户姓名"
                        })
                    }
                    else if (key == "customerPhone") {
                        _filter.push({
                            name: "customerPhone",
                            condition: 0,
                            value: this.formItem[key],
                            label: "联系方式"
                        })
                    }
                    else if (key == "saleUserId") { // && this.formItem[key]!=this.user.id
                        _filter.push({
                            name: "saleUserId",
                            condition: 2,
                            value: this.formItem[key],
                            label: "销售人员",
                            aliasvalue: this.formItem["saleUserName"]
                        })
                    }
                    else if (key == "provinceId") {
                        _filter.push({
                            name: "provinceId",
                            condition: 2,
                            value: this.formItem[key],
                            label: "省份:"
                        })
                    }
                    else if (key == "cityId") {
                        _filter.push({
                            name: "cityId",
                            condition: 2,
                            value: this.formItem[key],
                            label: "城市:"
                        })
                    }
                    else if (key == "source") {
                        _filter.push({
                            name: "source",
                            condition: 2,
                            value: this.formItem[key],
                            label: "来源:"
                        })
                    }
                    else if (key == "payway") {
                        _filter.push({
                            name: "payway",
                            condition: 2,
                            value: this.formItem[key],
                            label: "支付方式:"
                        })
                    }
                    else if (key == "prodPackage") {
                        _filter.push({
                            name: "prodPackage",
                            condition: 2,
                            value: this.formItem[key],
                            label: "套餐:"
                        })
                    }
                    else if (key == "eState" && this.formItem[key] != "-1") {
                        _filter.push({
                            name: "eState",
                            condition: 2,
                            value: this.formItem[key],
                            label: "订单状态:"
                        })
                    }
                    else if (key == "prodCode") {
                        _filter.push({
                            name: "prodCode",
                            condition: 0,
                            value: this.formItem[key],
                            label: "图纸编号:"
                        })
                    }
                    else if (key == "expressName") {
                        _filter.push({
                            name: "expressName",
                            condition: 2,
                            value: this.formItem[key],
                            label: "快递名称:"
                        })
                    }
                    else if (key == "expressNum") {
                        _filter.push({
                            name: "expressNum",
                            condition: 2,
                            value: this.formItem[key],
                            label: "快递编号:"
                        })
                    }
                }
            }
            //开始结束时间
            if (this.formItem.startTime && this.formItem.endTime) {
                let _startTime = utils.formatTime(this.formItem.startTime);
                let _endTime = utils.formatTime(this.formItem.endTime);
                _filter.push({
                    name: "SendTime",
                    condition: 8,
                    value: _startTime.split(" ")[0] + "," + _endTime.split(" ")[0],
                    label: "日期范围:"
                })
            }
            else if (this.formItem.startTime && !this.formItem.endTime) {
                let _startTime = utils.formatTime(this.formItem.startTime);
                _filter.push({
                    name: "SendTime",
                    condition: 6,
                    value: _startTime.split(" ")[0],
                    label: "录单时间:"
                })
            }
            else if (!this.formItem.startTime && this.formItem.endTime) {
                let _endTime = utils.formatTime(this.formItem.endTime);
                _filter.push({
                    name: "SendTime",
                    condition: 7,
                    value: _endTime.split(" ")[0],
                    label: "录单时间:"
                })
            }
            //开始结束编号
            if (this.formItem.startSort > 0 || this.formItem.endSort > 0) {
                _filter.push({
                    name: "ProdSort",
                    condition: 13,
                    value: this.formItem.startSort + "@" + this.formItem.endSort,
                    label: "编号范围:"
                })
            }

            this.$emit('update:modelValue', false)
            setTimeout(() => {
                this.$emit("on-change", _filter);
            }, 100);
        },
        provinceChangeHandle() {
            this.getarea(this.formItem.provinceId).then(d => {
                if (d.code == 0) {
                    this.citylist = d.datas;
                }
            });
        },
        clearAdvanceHandle() {
            for (let key in this.formItem) {
                if (typeof this.formItem[key] == "string") {
                    this.formItem[key] = "";
                }
                else if (typeof this.formItem[key] == "number") {
                    this.formItem[key] = 0;
                }
            }
        }
    },
    watch: {
        "selected": function () {
            // if(!this.comms.webmodel.state && this.comms.webmodel.key=="refresh"){
            //     this.$set(this.ttcallback, "refresh", true);
            // }
            // else 
            if (!this.comms.webmodel.state && this.comms.webmodel.action == "result") {
                if (this.comms.webmodel.key == "product") {
                    this.formItem.prodCode = this.comms.webmodel.result.code;
                }
                else if (this.comms.webmodel.key == "express") {
                    this.formItem.expressName = this.comms.webmodel.result.company;
                    this.formItem.expressId = this.comms.webmodel.result.id;
                }
                else if (this.comms.webmodel.key == "userlist") {
                    this.formItem.saleUserId = this.comms.webmodel.result.id;
                    this.formItem.saleUserName = this.comms.webmodel.result.userName;
                }
            }
        },
        "clearData": function () {
            this.clearAdvanceHandle();
        }
    }
}
</script>