<template>
    <div>
        <Modal v-model="value" width="390" :mask-closable="false" :closable="false">
            <template #header>
                <div style="font-size:16px;font-weight:bold;text-align:center;">系统提示</div>
            </template>
            <p style="text-align:center;" v-if="isValid == 1">是否确定将该客户设置为{{ isValid == 1 ? "有效" : "无效" }}</p>
            <div>
                <Form :model="formItem1" ref="formItem1" :label-width="80" :rules="ruleValidate">
                    <FormItem label="无效原因">
                        <RadioGroup v-model="formItem1.radioselected" @on-change="radioHandler">
                            <Radio :label="d.name" v-for="(d, i) in unvalidreason" :key="'unvalid' + i"></Radio>
                            <Radio label="其它"></Radio>
                        </RadioGroup>
                    </FormItem>

                    <FormItem label="其它原因" prop="validRemark">
                        <Input type="textarea" v-model="formItem1.validRemark" placeholder="请输入其它原因" />
                    </FormItem>
                </Form>
            </div>
            <template #footer>
                <Button type="error" @click="setValidData" icon="md-checkmark" style="float:right;"
                    :loading="submitloading">确定</Button>
                <Button @click="$emit('update:modelValue', false); submitloading = false;" icon="md-close"
                    style="float:right;margin-right:10px;">关闭</Button>
                <br class="clear" />
            </template>
        </Modal>
    </div>
</template>
<script>
import { useCommsStore } from "@/store/user/user";
import { useBaseInfoStore } from "@/store/crm/baseinfo";
import { useCustomerStore } from "@/store/crm/customer";

export default {
    name: "MyCustomerIsValidModal",
    setup() {
        const commsstore = useCommsStore();
        const baseinfostore = useBaseInfoStore();
        const customerstore = useCustomerStore();
        return {
            baseinfostore,
            customerstore,
            setcomms: commsstore.setcomms
        }
    },
    props: {
        modelValue: Array,
        datas: Array
    },
    data() {
        return {
            isValid: 0,
            submitloading: false,
            formItem: {
                taskId: "",
                customerId: "",
            },
            formItem1: {
                validRemark: "",
                radioselected: "",
            },
            unvalidreason: [],
            ruleValidate: {
                validRemark: [{ required: true, message: "原因不能为空", trigger: "blur" }]
            }
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    },
    methods: {
        setValidData() {
            this.$refs["formItem1"].validate((valid) => {
                if (!valid) {
                    return false;
                } else {
                    this.submitloading = true;
                    let _formItem = this.formItem.map(m => {
                        return {
                            id: m.customerId,
                            taskId: m.taskId,
                            isValid: 2,
                            validReason: this.formItem1.radioselected,
                            validRemark: this.formItem1.validRemark
                        }
                    })
                    this.customerstore.setcustomervalid(_formItem).then(d => {
                        if (d.code == 0) {
                            this.$Message.success("操作成功");
                            this.$emit("on-change", {
                                state: 100
                            });
                        }
                        this.submitloading = false;
                        this.$emit("update:modelValue", false);
                    });
                    this.setcomms({ completecustomerinfomodal: { state: false } });
                    this.setcomms({ customeronlinesubscribe: { state: false } });
                }
            })
        },
        radioHandler(val) {
            this.formItem1.validRemark = val;
        },
        changeHandler(event) {
            var val = event.target.value;
            let findval = false;
            this.unvalidreason.forEach(e => {
                if (e.name == val) {
                    findval = true;
                }
            });
            if (!findval) {
                this.radioselected = "其它";
            }
            else {
                this.radioselected = val;
            }
            this.formItem1.validRemark = val;
        },
        clearData() {
            this.formItem1.validRemark = "";
            this.formItem1.radioselected = "";
        }
    },
    watch: {
        "value": function () {
            if (this.value) {
                this.formItem = JSON.parse(JSON.stringify(this.datas));
                this.baseinfostore.getunvalidreason({ page: 1, row: 20 }, d => {
                    this.unvalidreason = d;
                    if (d.length > 0 && !this.radioselected) {
                        this.formItem1.validRemark = this.unvalidreason[0].name;
                        this.formItem1.radioselected = this.unvalidreason[0].name;
                    }
                });
            }
            else {
                this.clearData();
            }
        }
    }
}
</script>