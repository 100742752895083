<style lang="scss" scoped>
.m-header {
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #ebebeb;
    padding: 10px 10px;
    font-size: 15px;

    span {
        font-weight: bold;
    }
}

.spin-col {
    display: inline-block;
    vertical-align: middle;
    margin-left: -10px;

}
</style>
<style>
body {
    padding: 0px;
}
</style>
<template>
    <div>
        <Modal class="webmodel_container" v-model="mainstate" :styles="{ 'width': width + 'px', 'top': '10px' }"
            :footer-hide="hidefooter" :mask-closable="false" :closable="false">
            <!-- <template #header></template> -->
            <!-- <p slot="header" class="modalheaderclose" v-if="false"></p> -->
            <div class="m-header modalheaderclose" v-if="header">
                <span>{{ title }}</span>
                <Icon v-if="header" type="md-close-circle" size="25" @click="closewebmodel" />
            </div>
            <div class="m-content" :style="{ 'height': height + 'px' }" v-if="mainstate">
                <div v-if="opacity == 0" style="text-align:center;line-height:30px;cursor:pointer;"
                    @click="closewebmodel">
                    <div class="spin-col">
                        <Spin>
                            <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
                        </Spin>
                    </div>
                    请稍候...
                </div>
                <div :style="{ 'opacity': opacity, 'height': '100%' }">
                    <iframe id="webiframe" width="100%" ref="webiframe" name="webiframe"
                        :src="configs.base + currentview" :height="height" frameborder="no" marginwidth="0"
                        marginheight="0" scrolling="no"></iframe>
                </div>
            </div>
            <template #footer></template>
        </Modal>

        <Modal class="webmodel_container" v-model="substate" :styles="{ 'width': subwidth + 'px', 'top': '10px' }"
            :footer-hide="hidefooter" :mask-closable="false" :closable="false">
            <p slot="header" class="modalheaderclose" v-if="false"></p>
            <div class="m-header modalheaderclose" v-if="subheader">
                <span>{{ subtitle }}</span>
                <Icon v-if="subheader" type="md-close-circle" size="25" @click="closewebmodel" />
            </div>
            <div class="m-content" :style="{ 'height': subheight + 'px', 'overflow': 'hidden' }" v-if="substate">
                <div v-if="subopacity == 0" style="text-align:center;line-height:30px;" @click="substate = false">
                    请稍候..
                </div>
                <div :style="{ 'opacity': subopacity, 'height': '100%' }">
                    <iframe id="subwebiframe" name="subwebiframe" :height="subheight" width="100%"
                        :src="configs.base + subcurrentview" frameborder="no" marginwidth="0" marginheight="0"
                        scrolling="no"></iframe>
                </div>
            </div>
            <div slot="footer"></div>
        </Modal>
    </div>
</template>

<script>
import { useUserStore, useCommsStore } from "@/store/user/user";
import { reactive, toRefs } from "vue";
import { storeToRefs } from "pinia";
export default {
    name: 'WebModel',
    setup() {
        const commsstore = useCommsStore();
        const usersotre = useUserStore();
        const { user } = storeToRefs(usersotre);
        const { webmodel, selected,webmodeldatas } = storeToRefs(commsstore);
        const state = reactive({
            configs: usersotre.configs
        })
        return {
            ...toRefs(state),
            user,
            webmodel,
            selected,
            webmodeldatas,
            initwebmodel: commsstore.initwebmodel,
            onselected: commsstore.onselected,
            closewebmodel: commsstore.closewebmodel,
            synchselected: commsstore.synchselected
        }
    },

    data() {
        return {
            title: "",
            width: 150,
            height: 30,
            currentview: "",
            header: false,
            subtitle: "",
            subwidth: 150,
            subheight: 30,
            subcurrentview: "",
            subheader: false,
            hidefooter: true,
            opacity: 0,
            subopacity: 0,
            mainstate: false,
            substate: false,
            iframe: ""
        }
    },
    created() {
        this.$Spin.show();
        window.postInitMessage = (e) => {
            this.init(e);
        }
        window.postResult = (e) => {
            this.synchselected(e.datas);
        }
        window.postLocalPage = (e) => {
            this.$router.push(e);
        }
    },
    mounted() {
        document.onkeydown = (e) => {
            if (e.code == "Escape") {
                this.closewebmodel();
            }
        }
    },
    methods: {
        init(e) {
            let _this = this;
            var val = JSON.parse(JSON.stringify(e));//.data
            var _action = val.action;
            if (_action == "init") {
                if (this.substate) {
                    //子iframe已经被打开
                    _this.subwidth = val.width;
                    _this.subheight = val.height;
                    _this.subtitle = val.title;
                    _this.subheader = val.header == false ? false : true;
                    setTimeout(() => {
                        if (val.height > 0) {
                            _this.subopacity = 1;
                        }
                    }, 500);
                }
                else {
                    _this.width = val.width;
                    _this.height = val.height;
                    _this.title = val.title;
                    _this.header = val.header == false ? false : true;
                    setTimeout(() => {
                        if (val.height > 0) {
                            _this.opacity = 1;
                        }
                    }, 500);
                }
            }
            if (_action == "synchwebmodel") {
                //可以理解为更新主体的webmodel
                this.initwebmodel(e);
            }
        },
        getpathdetail(path, query) {
            let dir = "#/webmodel";
            let _currentview = path;
            //路径不是以 / 开头的要补上
            if (_currentview.indexOf('/') > 0 || _currentview.indexOf('/') < 0) {
                _currentview = "/" + _currentview;
            }

            //默认路径是从webmodel中去找
            //但是如果指定了非webmodel页面也要可以跳
            let _paths = _currentview.split('#/')
            if (_paths.length >= 2) {
                _currentview = "#/" + _paths[1];
            }
            else {
                _currentview = dir + _currentview;
            }

            let _key = "";
            for (let key in query) {
                if (!_key) {
                    _key += "?" + key + "=" + query[key]
                }
                else {
                    _key += "&" + key + "=" + query[key]
                }
            }
            if (_key) {
                _currentview += _key
            }
            return _currentview;
        }
    },
    watch: {
        "webmodel": {
            deep: true,
            handler() {
                let _state = this.webmodel.state;
                let _currentview = this.webmodel.path;
                let _query = this.webmodel.query;
                let _substate = this.webmodel.subwebmodel.state;
                let _subcurrentview = this.webmodel.subwebmodel.path;
                let _subquery = this.webmodel.subwebmodel.query;
                let _onlyclosesub = this.webmodel.subwebmodel.onlyclosesub;
                if (_substate) {
                    if (_subcurrentview.indexOf("http") > -1) {
                        window.open(_subcurrentview);
                    }
                    else {
                        this.substate = _state;
                        this.subcurrentview = this.getpathdetail(_subcurrentview, _subquery);
                        setTimeout(() => {
                            let $iframe = document.getElementById("subwebiframe");
                            $iframe.onload = () => {
                                $iframe.contentWindow.postPageMessage({ action: "initstore", datas: this.webmodel }, this.user,this.webmodeldatas);
                            }
                        }, 0);
                    }
                }
                else {
                    // let _postdatas = this.webmodel.postdatas;
                    if (_state && !_onlyclosesub) {
                        if (_currentview.indexOf("http") > -1) {
                            window.open(_currentview);
                        }
                        else {
                            this.mainstate = _state;
                            this.currentview = this.getpathdetail(_currentview, _query);
                            setTimeout(() => {
                                let $iframe = document.getElementById("webiframe");
                                $iframe.onload = () => {
                                    // $iframe.contentWindow.postMessage({ action: "inituser", datas: this.user }, '*');
                                    $iframe.contentWindow.postPageMessage({ action: "initstore", datas: this.webmodel, selected: this.selected }, this.user,this.webmodeldatas);
                                }
                            }, 0);
                        }
                    }
                    else if (_state && _onlyclosesub) {
                        this.substate = false;
                        this.subcurrentview = "#/webmodel";
                        this.subwidth = 150;
                        this.subheight = 30;
                        this.subopacity = 0;
                        this.subheader = false;
                        this.subtitle = "";
                        setTimeout(() => {
                            let $iframe = document.getElementById("webiframe");
                            $iframe.contentWindow.postPageMessage({ action: "initstore", datas: this.webmodel, selected: this.selected }, this.user,this.webmodeldatas);
                        }, 100);
                    }
                    else {
                        this.mainstate = false;
                        this.currentview = "#/webmodel";
                        this.width = 150;
                        this.height = 30;
                        this.opacity = 0;
                        this.header = false;
                        this.title = "";
                    }
                }
            }
        }
    }
}
</script>

