/*
 * @Author: GT
 * @Date: 2018-05-29 13:06:55
 * @LastEditors: GT
 * @LastEditTime: 2018-05-29 13:07:17
 * @Description: 
 */


let dict = {

};

//支付账号0：图纸 1:定制设计 2:模具
dict.thepayaccount = function() {
  return [
    // {
    //   label:"POSS机",
    //   value:"POSS机"
    // },
    {
      label:"成品图纸二维码",
      value:"成品图纸二维码",
      type:"0/"
    },
    {
      label:"私人定制二维码",
      value:"私人定制二维码",
      type:"1/",
      bind:""
    },
    {
      label:"全民付",
      value:"全民付",
      type:"0/1/"
    },
    {
      label:"货到付款",
      value:"货到付款",
      type:"0/",
      bind:"6017314c-c53d-4c55-bcac-3d1c4553e804"
    },
    {
      label:"模具收款二维码",
      value:"模具收款二维码",
      type:"2/",
      bind:""
    },
    {
      label:"淘宝（个人）",
      value:"淘宝（个人）",
      type:"0/",
      bind:""
    },
    {
      label:"淘宝（企业）",
      value:"淘宝（企业）",
      type:"0/",
      bind:""
    },
    {
      label:"支付宝",
      value:"支付宝",
      type:"0/1/",
      bind:""
    },
    {
      label:"移动网站",
      value:"移动网站",
      type:"0/",
      bind:""
    },
    {
      label:"现金",
      value:"现金",
      type:"0/1/2/"
    },
    {
      label:"微商城",
      value:"微商城",
      type:"0/",
      bind:""
    },
    {
      label:"小程序",
      value:"小程序",
      type:"0/",
      bind:""
    },
    {
      label:"对公账户",
      value:"对公账户",
      type:"0/1/",
      bind:""
    }
  ];
    // return [
    //     {
    //       label: "建筑设计农行基本2211",
    //       value: "建筑设计农行基本2211"
    //     },
    //     {
    //       label: "鼎川电子商务公司微信",
    //       value: "鼎川电子商务公司微信"
    //     },
    //     {
    //       label: "电子商务微商城",
    //       value: "电子商务微商城"
    //     },
    //     {
    //       label: "鼎川建筑设计公司微信",
    //       value: "鼎川建筑设计公司微信"
    //     },
    //     {
    //       label: "鼎川图纸超市微商城",
    //       value: "鼎川图纸超市微商城"
    //     },
    //     {
    //       label: "鼎川建筑波哥支付宝",
    //       value: "鼎川建筑波哥支付宝"
    //     },
    //     {
    //       label: "公司支付宝",
    //       value: "公司支付宝"
    //     },
    //     {
    //       label: "鼎川建筑(**萍)微信",
    //       value: "鼎川建筑(**萍)微信"
    //     },
    //     {
    //       label: "现金",
    //       value: "现金"
    //     },
    //     {
    //       label: "poss机收款",
    //       value: "poss机收款"
    //     },
    //     {
    //       label: "宋工@鼎川建筑构件模具",
    //       value: "宋工@鼎川建筑构件模具"
    //     }
    // ];
};

dict.acreage = function(){
    return [
        {
            label: "100平米以内",
            value: "100平米以内"
        },
        {
            label: "100-150平米",
            value: "100-150平米"
        },
        {
            label: "150-200平米",
            value: "150-200平米"
        },
        {
            label: "200平米以上",
            value: "200平米以上"
        },
    ];
}

dict.floor = function(){
    return [
      {
          label: "二层",
          value: "二层"
      },
      {
          label: "三层",
          value: "三层"
      },
      {
          label: "一层",
          value: "一层"
      },
      {
          label: "多层",
          value: "多层"
      }
    ];
}

dict.vacate = function(){
  return [
        {
            label:"休年假",
            value:90
        },{
            label:"事假",
            value:91
        },{
            label:"病假",
            value:92
        },{
            label:"婚假",
            value:93
        },{
            label:"产检假",
            value:94
        },{
            label:"产假",
            value:95
        },{
            label:"哺乳假",
            value:96
        },{
            label:"陪产假",
            value:97
        },{
            label:"丧假",
            value:98
        }
    ];
}

dict.personnel = function(){
  return [
      {
          label: "入职申请",
          value: "0"
      },
      {
          label: "离职申请",
          value: "1"
      },
      {
          label: "请假申请",
          value: "2"
      },
      {
          label: "转正申请",
          value: "3"
      },
      {
          label: "出差申请",
          value: "4"
      },
      {
          label: "加班申请",
          value: "5"
      },
      {
          label: "调岗申请",
          value: "6"
      },
      {
          label: "升职申请",
          value: "7"
      },
      {
          label: "调休申请",
          value: "7"
      }
  ];
}

dict.platformList = function(){
  return [{
    id:"大搜",
    name:"大搜"
  },{
    id:"信息流",
    name:"信息流"
  },{
    id:"自媒体",
    name:"自媒体"
  },{
    id:"其它",
    name:"其它"
  }]
}

dict.throwPlatformList = function(){
  return [{
    id:"百度",
    name:"百度"
  },{
    id:"360",
    name:"360"
  },{
    id:"神马",
    name:"神马"
  },{
    id:"搜狗",
    name:"搜狗"
  },{
    id:"其它",
    name:"百度"
  }]
}

dict.attrList = function(){
  return [{
    id:"正企",
    name:"正企"
  },{
    id:"非企",
    name:"非企"
  }]
}

dict.buildtime = function(){
  return [{
    id:"随便看看",
    name:"随便看看"
  },{
    id:"一个月",
    name:"一个月"
  },{
    id:"三个月",
    name:"三个月"
  },{
    id:"半年内",
    name:"半年内"
  },{
    id:"一年以上",
    name:"一年以上"
  },{
    id:"正在建",
    name:"正在建"
  }]
}

dict.budget = function(){
  return [{
    id:"30万左右",
    name:"30万左右"
  },{
    id:"50万左右",
    name:"50万左右"
  },{
    id:"80万左右",
    name:"80万左右"
  },{
    id:"100万左右",
    name:"100万左右"
  },{
    id:"100万以上",
    name:"100万以上"
  }]
}

dict.taskstate = function(){
  return [{
    name:"不限",id:-1
  },{
    name:"待完善",id:0
  },{
    name:"待跟进",id:1
  },{
    name:"待联系",id:2
  },{
    name:"跟进中",id:3
  },{
    name:"对比中",id:4
  },{
    name:"已报价",id:5
  },{
    name:"图已定",id:6
  }];

}
export default dict;