<style lang="scss" scoped>
.navicon {
  transition: all 200ms ease;
}

.navicon:hover {
  background-color: #f9f9f9 !important;

  i {
    color: #ddd !important;
  }
}

.userlist {
  display: flex;
  padding: 5px;
  cursor: pointer;

  .tx {
    width: 55px;

    img {
      width: 100%;
    }
  }

  .info {
    flex: 1;
    padding-left: 10px;

    .username {
      font-weight: bold;
    }

    .logintime {
      color: #595959;
    }
  }

  .action {
    width: 40px;
    text-align: center;
    padding-top: 10px;
    box-sizing: border-box;
  }

  &:hover {
    background-color: #ebebeb;

    .action i {
      color: #2d8cf0;
    }
  }
}

.togglemenu {
  float: left;
  margin-top: 20px;
  margin-left: 15px;
  cursor: pointer;

  &:hover {
    i {
      color: #ebebeb;
    }
  }
}
</style>
<template>
  <div class="navfunarea">
    <!-- <div class="togglemenu" @click="togglemenu = !togglemenu, $emit('on-change', togglemenu)">
      <Icon type="md-arrow-dropleft-circle" size="20" v-if="!togglemenu" />
      <Icon type="md-arrow-dropright-circle" size="20" v-else />
    </div> -->
    <Row style="width: 325px; display: inline-block; margin-top: -5px">
      <Col span="24" style="margin: 18px 18px 18px 10px; position: relative">
      <Input class="homesearch" v-model="homesearchvalue" search placeholder="请输入项目编号、联系电话、微信号、客户姓名"
        @on-search="homeSearchHandle" @on-click="homeSearchHandle" :loading="homesearchloading"
        style="width: 300px; background-color: #515a6e; color: white">
      </Input>
      <div v-if="homesearchloading" style="position: absolute;top: 0px;left: 0px;width: 101%;height: 100%;background-color: rgba(255, 255, 255, 0.5);">
        <Spin style="margin-left: 45%; margin-top: 5px"></Spin>
      </div>
      </Col>
    </Row>
    <Dropdown style="float: right">
      <a class="userinfo1" @click="goUserInfo">
        <div class="img imgloading imgcircle">
          <img :src="user.headImgUrl + '?w=nowater'" @load="loadHeadImg($event)"
            :class="onlinestate ? (realsendtime ? '' : 'warn') : 'fail'" />
          <Icon type="md-person" size="16" color="white" style="
              width: 110%;
              height: 110%;
              padding-top: 4px;
              padding-right: 1px;
            " :class="onlinestate ? (realsendtime ? '' : 'warn') : 'fail'"></Icon>
        </div>
      </a>
      <template #list>
        <DropdownMenu style="right: -10px">
          <DropdownItem>
            <div>{{ user.userName }}({{ user.roleName }})</div>
          </DropdownItem>
          <DropdownItem v-if="user.collectionId">
            <div @click="openSwitchUserHandle">
              <Icon type="md-swap" size="15" />&nbsp;切换账号
            </div>
          </DropdownItem>
          <DropdownItem divided v-if="!workrange">
            <span style="color: #ed4014; display: inline-block" @click="applyOnlineResultHandle">未在工作时间范围内</span>
          </DropdownItem>
          <DropdownItem divided>
            <div @click="reOnline(0)">
              <span class="statecircle stateon"></span>上线
            </div>
          </DropdownItem>
          <DropdownItem>
            <div @click="reOnline(1)">
              <span class="statecircle stateoff"></span>下线
            </div>
          </DropdownItem>
          <DropdownItem divided>
            <div @click="goUserInfo">
              <Icon type="ios-flag" size="15" />&nbsp;个人中心
            </div>
          </DropdownItem>
          <DropdownItem v-if="user.employeeState == 0">
            <div @click="$router.push('/mycard')">
              <Icon type="ios-card" />&nbsp;个人名片
            </div>
          </DropdownItem>
          <DropdownItem v-if="configs.baseurl && configs.baseurl.indexOf('http://localhost') > -1">
            <div @click="logout('')">
              <Icon type="ios-power" />&nbsp;注销
            </div>
          </DropdownItem>
          <DropdownItem>
            <div @click="setwebmodel({ state: true, path: '/privatesetting' })">
              <Icon type="ios-cog" size="14" />&nbsp;设置
            </div>
          </DropdownItem>
          <DropdownItem>
            <div @click="phoneview = true">
              <Icon type="md-phone-portrait" />&nbsp;工作平台移动端
            </div>
          </DropdownItem>
          <!-- <DropdownItem divided>
                      <div @click="openVersion(version)"><Icon type="md-bookmark" />&nbsp;Version {{version}}</div>
                  </DropdownItem> -->
        </DropdownMenu>
      </template>
    </Dropdown>
    <Row style="float: right; width: 40px; margin-right: 20px">
      <Col span="24" style="margin: 18px">
      <Badge :count="unreadnum">
        <Tooltip content="企业通讯">
          <a href="javascript:void(0);" @click="openMyChat" class="demo-badge navicon">
            <Icon type="md-chatbubbles" />
          </a>
        </Tooltip>
      </Badge>
      </Col>
    </Row>
    <Row style="float: right; width: 40px">
      <Col span="5" style="margin: 18px">
      <Badge>
        <Tooltip content="考勤打卡">
          <a href="javascript:void(0);" @click="
            setwebmodel({ state: true, path: '/dutymanage',query:{year:currentdate.year,month:currentdate.month} })
          " class="demo-badge navicon">
            <Icon type="md-calendar" />
          </a>
        </Tooltip>
      </Badge>
      </Col>
    </Row>

    <Row style="float: right; width: 40px" v-if="user.imgPower && user.imgPower != 'N'">
      <Col span="5" style="margin: 18px">
      <span>
        <Tooltip content="素材库">
          <a href="javascript:void(0);" @click="openMaterialLibHandle" class="demo-badge navicon">
            <Icon type="ios-globe" />
          </a>
        </Tooltip>
      </span>
      </Col>
    </Row>

    <Row style="float: right; width: 40px" v-if="user.docPower && user.docPower != 'N'">
      <Col span="5" style="margin: 18px">
      <span>
        <Tooltip content="企业网盘">
          <a href="javascript:void(0);" @click="openNetWorkDiskHandle" class="demo-badge navicon">
            <Icon type="ios-filing" />
          </a>
        </Tooltip>
      </span>
      </Col>
    </Row>

    <Modal v-model="applyonlinestate" :mask-closable="false" :closable="false" width="360">
      <template #header>
        <div style="color: #f60; text-align: center">
          <Icon type="information-circled"></Icon>
          <h3>系统提示</h3>
        </div>
      </template>
      <div v-if="applyonlineresult == 0" style="text-align: center; padding: 10px">
        <p>是否确定提交加班申请？</p>
      </div>
      <div v-if="applyonlineresult == 1" style="text-align: center; padding: 10px">
        <p>审核中,请稍候刷新重试!</p>
      </div>
      <div v-if="applyonlineresult == 2" style="text-align: center; padding: 10px">
        <p>审核成功,可以通过上下线进行尝试登陆!</p>
      </div>
      <div v-if="applyonlineresult == 3" style="text-align: center; padding: 10px">
        <p>审核失败!</p>
      </div>
      <template #footer>
        <Button @click="applyonlinestate = false" icon="md-close">关闭</Button>
        <Button type="primary" @click="applyOnlineResultHandle" icon="md-refresh" :loading="submitloading"
          v-if="applyonlineresult != '0'">刷新</Button>
        <Button type="error" @click="applyOnlineHandle" v-else icon="md-checkmark" :loading="submitloading">确定</Button>
      </template>
    </Modal>

    <Modal v-model="switchuserstate" :mask-closable="false" :closable="false" width="360">
      <template #header>
        <h3>切换账号</h3>
      </template>
      <div>
        <div class="userlist" @click="(switchuserstate = false), logout('?id=' + d.userId)"
          v-for="(d, i) in usercollection" :key="'usercollection' + i">
          <div class="tx">
            <img :src="d.headImgUrl || '${process.env.BASE_URL}/default_tx1.jpg'" alt="" />
          </div>
          <div class="info">
            <div class="username">{{ d.userName }}({{ d.user }})</div>
            <div class="companyname">
              {{ d.companyName }}&nbsp;{{ d.roleName }}
            </div>
            <div class="rolename">上次登录时间:{{ d.lastLoginTime }}</div>
          </div>
          <div class="action">
            <Icon type="ios-arrow-dropright" size="30" />
          </div>
        </div>
      </div>
      <template #footer>
        <Button @click="switchuserstate = false" icon="md-close">关闭</Button>
      </template>
    </Modal>

    <Modal v-model="chatwhostate" :mask-closable="false" :closable="false" width="360">
      <template #header>
        <h3>聊天对象</h3>
      </template>
      <div>
        <div class="userlist" @click="chatHandle(d)" v-for="(d, i) in chatdatas" :key="'chatwho' + i">
          <div class="tx">
            <img :src="d.HeadImgUrl || '${process.env.BASE_URL}/default_tx1.jpg'" alt="" />
          </div>
          <div class="info">
            <div class="username">{{ d.UserName }}</div>
            <div class="companyname">
              {{ d.CompanyName }}&nbsp;{{ d.OrganizeName }}
            </div>
            <div class="rolename">{{ d.RoleName }}</div>
          </div>
          <div class="action">
            <Icon type="ios-chatboxes" size="28" />
          </div>
        </div>
      </div>
      <template #footer>
        <Button @click="chatwhostate = false" icon="md-close">关闭</Button>
      </template>
    </Modal>
    <Modal v-model="phoneview" width="300">
      <template #header>
        <h3>工作平台移动端</h3>
      </template>
      <div style="text-align: center">
        <Alert type="warning" style="padding: 5px">
          该二维码为{{ user.userName }}({{ user.user }})的工作平台移动端入口,请使用微信扫一扫.(注:分享给其他人无效)</Alert>
        <img :src="configs.baseurl + 'api/backstage/wxcode/mobileplatform/' + user.id" alt="" />
      </div>
      <template #footer>
        <Button type="default" icon="md-close" @click="phoneview = false">关闭</Button>
      </template>
    </Modal>

    <Modal v-model="searchliststate" width="1000" :styles="{ top: '10px' }">
      <template #header>
        <h3>搜索结果列表</h3>
      </template>
      <div>
        <Table ref="selection" stripe :loading="tblloading" border :columns="columns1" :data="data1"></Table>
      </div>
      <template #footer>
        <div style="float: left; margin-top: 7px; color: #ed4014" v-if="data1 && data1.length >= 15">
          注:列表最多显示15条数据,关键词输入越完整,结果越精准。
        </div>
        <Button type="default" icon="md-close" @click="searchliststate = false">关闭</Button>
      </template>
    </Modal>
    <ChangeTaskState v-model="setchangetaskstatestate" :datas="formItem" :inputorder="true" @click="inputOrderHandle" />
  </div>
</template>

<script>
import enums from "@/libs/enums";
import utils from "@/libs/util";
import ChangeTaskState from "../../views/crm/modal/mytask/changetaskstate";
import { useUserStore, useCommsStore } from "@/store/user/user";
import { useCustomerStore } from "@/store/crm/customer";
import { useSysInfoStore } from "@/store/user/sysinfo";
import { reactive, toRefs } from "vue";
import { storeToRefs } from "pinia";

export default {
  name: "NavFuncArea",
  props: [],
  components: { ChangeTaskState },
  setup() {
    const userstore = useUserStore();
    const commsstore = useCommsStore();
    const customerstore = useCustomerStore();
    const sysinfostore = useSysInfoStore();
    const { comms,webmodel,useronline } = storeToRefs(commsstore);
    const state = reactive({
      user: userstore.user,
      configs: userstore.configs
    });
    return {
      ...toRefs(state),
      userstore,
      comms,
      webmodel,
      useronline,
      customerstore,
      sysinfostore,
      setwebmodel: commsstore.setwebmodel,
      setcomms: commsstore.setcomms
    }
  },

  data() {
    return {
      unreadnum: 0,
      togglemenu: false,
      homesearchvalue: "",
      homesearchloading: false,
      phoneview: false,
      chatwhostate: false,
      applyonlinestate: false,
      switchuserstate: false,
      applyonlineresult: 0,
      workrange: true,
      schedulingdata: [],
      onlinestatemodal: false,
      onlinestate: false,
      realsendtime: false,
      forcestate: false,
      privatesettingstate: false,
      setchangetaskstatestate: false,
      value4: "",
      dateState: 0,
      forceofflineuserid: "",
      loading: false,
      versions: "",
      version: "0.0.0",
      onlineuserstats: "",
      submitloading: false,
      usercollection: [],
      chatdatas: [],
      searchliststate: false,
      tblloading: false,
      columns1: [
        {
          title: "类型",
          key: "orderType",
          width: 80,
          render: (h, params) => {
            return h("span", {
              innerHTML: (function () {
                if (params.row.orderType == 0) {
                  return "订单";
                } else if (params.row.orderType == 1) {
                  return "项目";
                } else if (params.row.orderType == 4) {
                  return "图册";
                } else if (params.row.orderType == 9) {
                  return "客资";
                } else {
                  return "未知";
                }
              })(),
            });
          },
        },
        {
          title: "状态",
          key: "state",
          width: 100,
          render: (h, params) => {
            return h("span", {
              innerHTML: (function () {
                if (params.row.orderType == 9) {
                  return enums.taskstate(params.row.state);
                } else {
                  if (params.row.orderType == 0) {
                    if (params.row.state == 0) {
                      return "<span>待发货</span>";
                    } else if (params.row.state == 1) {
                      return "<span style='color:#2db7f5;'>待签收</span>";
                    } else if (params.row.state == 2) {
                      return "<span style='color:#19be6b;'>正常</span>";
                    } else if (params.row.state == 3) {
                      return "<span style='color:#ed4014;'>已拒收</span>";
                    }
                  } else {
                    return "已成单";
                  }
                }
              })(),
            });
          },
        },
        {
          title: "客户编号",
          key: "customerCode",
          width: 150,
          render: (h, params) => {
            return h("span", {
              innerHTML: params.row.customerCode || "--",
            });
          },
        },
        {
          title: "客户名称",
          key: "customerName",
          width: 100,
        },
        {
          title: "备注",
          key: "name",
          render: (h, params) => {
            return h("span", {
              innerHTML: params.row.name || "--",
            });
          },
        },
        {
          title: "销售人员",
          key: "userName",
          width: 100,
        },
        {
          title: "更新时间",
          key: "addTime",
          width: 150,
        },
        {
          title: "操作",
          key: "action",
          width: 100,
          render: (h, params) => {
            return h("div", [
              h("a", {
                style: {
                  "margin-right": "5px",
                },
                onClick: () => {
                  let _datas = params.row.id;
                  let _key = "";
                  if (params.row.orderType == 0) {
                    _key = "order";
                  } else if (params.row.orderType == 4) {
                    _key = "order";
                  } else if (params.row.orderType == 1) {
                    _key = "project";
                  } else if (params.row.orderType == 9) {
                    _key = "customer";
                  }
                  this.homeSearchModel(_key, _datas);
                },
              }, "详情"),
              h("a", {
                style: {
                  display:
                    params.row.orderType == 9 && params.row.state != 7
                      ? "inline-block"
                      : "none",
                },
                onClick: () => {
                  this.setchangetaskstatestate = true;
                  this.formItem.customerId = params.row.customerId;
                  this.formItem.id = params.row.id;
                },
              }, "录单")
            ]);
          },
        },
      ],
      data1: [],
      formItem: {},
      currentdate:utils.currentDate()
    };
  },
  mounted() {
    this.loadChatUnRead();
  },
  methods: {
    loadChatUnRead() {
      this.userstore.getchatunreadnum(d => {
        this.unreadnum = d;
        this.setcomms({unreadnum:d});
      });
    },
    goUserInfo() {
      this.$router.push({
        path: "/mycenter",
        query: {
          id: this.user.id,
        },
      });
    },
    logout(url) {
      this.setcomms({ loginout: { state: true, url: url } });
    },
    openMyChat() {
      if (!this.onlinestate) {
        this.$Notice.warning({
          title: "系统提示",
          desc: "当前处于离线状态,无法打开企业内部通讯。",
          duration: 10,
        });
        return false;
      }
      this.$Notice.destroy();
      this.setcomms({
        mychat: { state: true },
      });
    },
    openVersion(ver) {
      this.setcomms({
        versionmodal: {
          state: true,
          datas: this.versions,
          version: ver,
        },
      });
    },
    openForum() {
      this.setcomms({
        forummodal: true,
      });
    },
    reOnline(state) {
      if (state == 0) {
        this.setcomms({
          reonline: "online",
        });
      } else if (state == 1) {
        this.setcomms({
          reonline: "offline",
        });
      }
    },
    refresOnline() {
      let _action = this.useronline.action;

      if (_action == "joinaction" || _action == "noaction") {
        if (this.useronline.action == "joinaction" && this.useronline.data != null) {
          var e = this.useronline.data;
          if (e.id == this.user.id) {
            this.onlinestate = true;
            this.realsendtime = e.realSendTime;
            if (!this.realsendtime) {
              this.workrange = false;
              let txt =
                "当前时间不在排班时间范围内,无法实时新增/获取客资推送,请在班次时间内再次上线。";
              if (this.user.code == "53SERVICES") {
                txt =
                  "当前时间不在排班时间范围内,工作平台客服无法上线,请在班次时间内再次上线。";
              }
              this.$Notice.warning({
                title: "系统提示",
                desc: txt,
                duration: 10,
              });
            }
          }
        } else {
          if (this.useronline.data.id == this.user.id) {
            this.onlinestate = false;
          }
        }
      } else {
        if (this.useronline.data.id == this.user.id) {
          this.onlinestate = false;
        }
      }
    },
    setDate(d) {
      this.loadSchedulingUser(d, "");
    },
    pickerChange(e) {
      this.loadSchedulingUser("", e);
    },
    openDuty() {
      if (this.user.code == "SUPERADMIN" || this.user.code == "BOSS") {
        this.setcomms({ dutyrecordmodal: { state: true } });
      } else {
        this.setcomms({ dutymodal: { state: true } });
      }
    },
    openSetting() {
      //打开设置,主要包含消息强提醒,菜单分组
    },
    getOnlineUser(obj, online, action) {
      //obj 要调整的对象
      //action 0:对比所有线上用户  1:对比刚上线的用户
      //let isFind = false;
      if (action == 0) {
        for (let i = 0; i < this.useronline.list.length; i++) {
          var d = this.useronline.list[i];
          if (d.id == obj.id) {
            obj.online = online == "leaveaction" ? 1 : 0;
            break;
          }
        }
      } else if (action == 1) {
        if (this.useronline.data.id == obj.id) {
          obj.online = online == "leaveaction" ? 1 : 0;
        }
      }
      return obj.online;
    },
    loadSchedulingUser(today, date) {
      this.getschedulinguser({ today: today || "", date: date || "" }).then(
        (d) => {
          this.schedulingdata = [];
          if (d.code == 0) {
            this.schedulingdata = d.datas;
            this.schedulingdata.forEach((d1) => {
              d1.children.forEach((d2) => {
                d2.online = 1;
                this.useronline.list.forEach((d3) => {
                  if (d2.id == d3.id) {
                    d2.online = 0;
                  }
                });
              });

              d1.children = d1.children.sort(function (a, b) {
                return a.online - b.online;
              });
            });
          }
        }
      );
    },
    openDialog(d1) {
      if (d1.id != this.user.id) {
        this.setcomms({
          opendialog: {
            state: true,
            userId: d1.id,
            userName: d1.userName,
            flag: 0,
          },
        });
        this.onlinestatemodal = false;
      }
    },
    loadHeadImg(event) {
      var target = event.target;
      var newclass = "img imgloaded";
      target.parentNode.className = newclass;
    },
    applyOnlineResultHandle() {
      this.applyonlineresult = "0";
      this.submitloading = true;
      this.getapplyonlineresult().then((d) => {
        if (d.code == 0) {
          this.applyonlineresult = d.datas;
          this.applyonlinestate = true;
        }
        this.submitloading = false;
      });
    },
    applyOnlineHandle() {
      this.submitloading = true;
      this.applyonlinenoscheduling().then((d) => {
        if (d.code == 0) {
          this.applyonlineresult = "1";
        }
        this.submitloading = false;
      });
    },
    openSwitchUserHandle() {
      this.switchuserstate = true;
      this.sysinfostore.getusercollection({
        collectionId: this.user.collectionId,
        excludeUserId: this.user.id,
      },(d) => {
          this.usercollection = d;
      });
    },
    homeSearchHandle() {
      if (!this.homesearchvalue) {
        this.$Message.error("请输入搜索内容");
        return false;
      }
      this.homesearchloading = true;
      this.customerstore.gethomesearch({ name: this.homesearchvalue }).then(({ res }) => {
        if (res.code == 0) {
          if (res.key == "list") {
            this.data1 = res.list;
            this.searchliststate = true;
          } else {
            this.homeSearchModel(res.key, res.list[0].id);
          }
        } else if (res.code == 1) {
          this.$Message.error("没有找到任何内容");
        }
        this.homesearchloading = false;
      });
    },
    homeSearchModel(key, datas) {
      if (key == "order") {
        this.setwebmodel({
          state: true,
          path: "/orderdetail",
          query: {
            id: datas,
            ordertype: 0,
          }
        });
      } else if (key == "customer") {
        this.setwebmodel({
          state: true,
          path: "/customerrecorddetail",
          query: { id: datas }

        });
      } else if (key == "project") {
        this.setwebmodel({
          state: true,
          path: "/projectdetail",
          query: {
            current: 0,
            id: datas,
          }
        });
      } else if (key == "chat") {
        if (!this.onlinestate) {
          this.$Notice.warning({
            title: "系统提示",
            desc: "当前处于离线状态,无法打开企业内部通讯。",
            duration: 10,
          });
          return false;
        }
        var _chatdatas = JSON.parse(datas);
        this.setcomms({
          opendialog: {
            state: true,
            datas: {
              bizId: _chatdatas.Id,
              bizName: _chatdatas.UserName,
              dialogId: _chatdatas.DialogId,
              currenttab: 1,
              flag: 0,
            },
          },
        });
      } else if (key == "chatwho") {
        if (!this.onlinestate) {
          this.$Notice.warning({
            title: "系统提示",
            desc: "当前处于离线状态,无法打开企业内部通讯。",
            duration: 10,
          });
          return false;
        }
        this.chatwhostate = true;
        this.chatdatas = JSON.parse(datas);
      } else if (key == "checkchatstatus") {
        this.setwebmodel({ state: true, path: "/checkchatstatus" });
      } else if (key == "checkchatprivatestatus") {
        this.setwebmodel({
          state: true, path: "/checkchatprivatestatus"
        });
      }
    },
    chatHandle(d) {
      this.chatwhostate = false;
      this.setcomms({
        opendialog: {
          state: true,
          datas: {
            bizId: d.Id,
            bizName: d.UserName,
            currenttab: 1,
            flag: 0,
          },
        },
      });
    },
    openMaterialLibHandle() {
      if (window.Client) {
        window.Client.openExtendPage(
          this.basepath +
          "#/webmodel/circlelibrary?type=extendpage&docPower=" +
          this.user.docPower +
          "&imgPower=" +
          this.user.imgPower
        );
      } else {
        this.setwebmodel({
          state: true,
          path:
            "/circlelibrary?docPower=" +
            this.user.docPower +
            "&imgPower=" +
            this.user.imgPower
        });
        // this.setcomms({webmodel:{state:true,path:'https://oval-melody-6ba.notion.site/360-3c3f7bfa9f1041a3be840a04aaaab1cb'}});
      }
    },
    openNetWorkDiskHandle() {
      if (window.Client) {
        window.Client.openExtendPage(
          this.basepath + "#/webmodel/networkdisk?type=extendpage&id=3",
          false,
          true
        );
      } else {
        this.setwebmodel({ state: true, path: "/networkdisk", query: { id: 3 } });
        // this.setcomms({webmodel:{state:true,path:'https://oval-melody-6ba.notion.site/360-3c3f7bfa9f1041a3be840a04aaaab1cb'}});
      }
    },
    inputOrderHandle() {//url
      this.searchliststate = false;
      //  this.goPageHandle(url);
    },
  },
  watch: {//refreshchatunread
    "comms.mychat": {
      deep:true,
      handler:function(){
        if(!this.comms.mychat.state){
          this.loadChatUnRead();
        }
      }
      
    },
    "comms.unreadnum": function () {
      this.unreadnum = this.comms.unreadnum;
    },
    "useronline": {
      deep: true,
      handler: function () {
        this.refresOnline();
      },
    },
    onlinestatemodal: function () {
      if (this.onlinestatemodal && this.schedulingdata.length == 0) {
        this.loadSchedulingUser();
      }
    },
    "comms.webmodel": function () {
      let _state = this.comms.webmodel.state;
      let _action = this.comms.webmodel.action;
      if (!_state && _action == "page") {
        this.searchliststate = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>