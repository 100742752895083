let cache = {

};
cache.init = function() {
    if(window.localStorage){
        return true;
    }
    else{
        console.error("当前浏览器不支持localStorage!!");
        return false;
    }
};
cache.read = function(key) {
    this.init();
    try{
        if(window.localStorage[key]){
            return JSON.parse(window.localStorage[key]);
        }
        else{
            return '';
        }
    }
    catch(ex){
        console.error(ex);
        return false;
    }
};
cache.write = function(key,txt) {
    this.init();
    if(typeof txt == "object"){
        window.localStorage[key]=JSON.stringify(txt);
    }
    else{
        window.localStorage[key]=txt;
    }
};
cache.delete = function(key) {
    this.init();
    window.localStorage[key]="";
};
cache.push = function(key,txt) {
    this.init();
    let _d = JSON.parse(JSON.stringify(this.read(key) || []));
    let pushjson = function(){
        if(typeof txt == "object"){
            //缓存中没有记录路径,直接插入
            if(_d.length==0){
                _d.push(txt);
                cache.write(key,_d);
            }
            else{
                let _isFind = false;
                _d.forEach((v,i)=>{
                    if(v.path==txt.path){
                        _isFind = true;
                        _d.splice(i+1,1);
                        i--;
                    }
                });
                if(!_isFind){
                    _d.push(txt);
                    
                }
                cache.write(key,_d);
            }
        }
        else{
            console.error("txt的类型必须为object");
        }
    }
    if(window.localStorage[key]){
        _d = JSON.parse(window.localStorage[key]);
        pushjson();
    }
    else{
        
        pushjson();
    }
    


};

export default cache;