import { defineStore } from "pinia";
import api from "@/service/http";
// import configs from "@/service/configs";
// import {ref} from "vue"

//default defineStore
export const useSysInfoStore = defineStore("sysinfo", {
  state: () => {
    return {

    };
  },
  getters: {},
  actions: {
    async getorganize(data, callback) {
      return await api.get("/organize/" + data.id, data, callback, ["id"]).then((d) => {
        return d;
      });
    },
    async getorganizeall(data, callback) {
      return await api.get("/organize/all", data, callback).then((d) => {
        return d;
      });
    },
    async getorganizedetail(data, callback) {
      return await api.get("/organize/detail/" + data.id, "", callback).then((d) => {
        return d;
      });
    },
    async addorganize(data, callback) {
      return await api.post("/organize", data, callback).then(({ res }) => {
        return res;
      });
    },
    async editorganize(data, callback) {
      return await api.put("/organize", data, callback).then(({ res }) => {
        return res;
      });
    },
    async delorganize(data, callback) {
      return await api.post("/organize/delete", data, callback).then(({ res }) => {
        return res;
      });
    },
    async updateorganizestate(data, callback) {
      return await api.put("/organize/updatestate", data, callback).then(({ res }) => {
        return res;
      });
    },
    ///公司信息
    async getsetinfo(data, callback) {
      return await api.get("/setinfo/" + data.page + "/" + data.row, data, callback, ["page", "row"]).then((d) => {
        return d;
      });
    },
    async editsetinfo(data, callback) {
      return await api.put("/setinfo", data, callback).then(({ res }) => {
        return res;
      });
    },
    async addsetinfo(data, callback) {
      return await api.post("/setinfo", data, callback).then(({ res }) => {
        return res;
      });
    },
    async getsetinfodetail(data, callback) {
      return await api.get("/setinfo/" + data, "", callback).then((d) => {
        return d;
      });
    },
    async getwaterlistbycompanyid(data, callback) {
      return await api.get("/setinfo/waterlist/" + data, "", callback).then((d) => {
        return d;
      });
    },
    ///个人配置信息
    async editprivatesetting(data, callback) {
      return await api.put("/privatesetting", data, callback).then(({ res }) => {
        return res;
      });
    },
    async getprivatesetting(callback) {
      return await api.get("/privatesetting", "", callback).then((d) => {
        return d;
      });
    },
    async getpcauth(data, callback) {
      return await api.get("/PcAuth", data, callback).then((d) => {
        return d;
      });
    },
    async getpcauthdetaillist(data, callback) {
      return await api.get("/PcAuth/detaillist", data, callback).then((d) => {
        return d;
      });
    },
    async isauth(data, callback) {
      return await api.post("/PcAuth", data, callback).then(({ res }) => {
        return res;
      });
    },
    async updateauthstate(data, callback) {
      return await api.put("/PcAuth", data, callback).then(({ res }) => {
        return res;
      });
    },
    async updateauthlogintype(data, callback) {
      return await api.put("/PcAuth/loginType", data, callback).then(({ res }) => {
        return res;
      });
    },
    async pcauditsynchuser(data, callback) {
      return await api.get("/PcAuth/synchuser", data, callback).then((d) => {
        return d;
      });
    },
    async delpcaudit(data, callback) {
      return await api.post("/PcAuth/delete/" + data.flag + "/" + data.id, {}, callback).then(({ res }) => {
        return res;
      });
    },
    //栏目调整
    async getwebcolumn(data, callback) {
      return await api.get("/column/" + data.flag, data, callback, ["flag"]).then((d) => {
        return d;
      })
    },
    async addwebcolumn(data, callback) {
      return await api.post("/column", data, callback).then(({ res }) => {
        return res;
      })
    },
    async editwebcolumn(data, callback) {
      return await api.put("/column", data, callback).then(({ res }) => {
        return res;
      })
    },
    async delwebcolumn(data, callback) {
      return await api.post("/column/delete/" + data.flag, data, callback).then(({ res }) => {
        return res;
      })
    },
    async updatewebcolumnstate(data, callback) {
      return await api.put("/column/updatestate", data, callback).then(({ res }) => {
        return res;
      })
    },
    //角色
    async getrolemodule(data, callback) {
      return await api.get("/role/module/" + data, "", callback).then((d) => {
        return d;
      })
    },
    async getrolesubmodule(data, callback) {
      return await api.get("/role/module/" + data, "", callback).then((d) => {
        return d;
      })
    },
    async getrole(data, callback) {
      return await api.get("/role", data, callback).then((d) => {
        return d;
      })
    },
    async getrolebyid(data, callback) {
      return await api.get("/role/" + data, "", callback).then((d) => {
        return d;
      })
    },
    async addrole(data, callback) {
      return await api.post("/role", data, callback).then(({ res }) => {
        return res;
      })
    },
    async delrole(data, callback) {
      return await api.post("/role/delete", data, callback).then(({ res }) => {
        return res;
      })
    },
    async editrole(data, callback) {
      return await api.put("/role", data, callback).then(({ res }) => {
        return res;
      })
    },
    async updaterolestate(data, callback) {
      return await api.put("/role/updatestate", data, callback).then(({ res }) => {
        return res;
      })
    },
    async updateuserstate(data, callback) {
      return await api.put("/user/updatestate", data, callback).then(({ res }) => {
        return res;
      })
    },
    async getroleuser(data, callback) {
      return await api.get("/role/roleuser", "", callback).then((d) => {
        return d;
      })
    },
    async getnoinroleuser(data, callback) {
      return await api.get("/Role/NoInRoleUser", data, callback).then((d) => {
        return d;
      })
    },
    async getuserbyid(data, callback) {
      return await api.get("/user/detail/" + data, "", callback).then((d) => {
        return d;
      })
    },
    async adduser(data, callback) {
      return await api.post("/user", data, callback).then(({ res }) => {
        return res;
      })
    },
    async deluser(data, callback) {
      return await api.post("/user/delete/" + data, "", callback).then(({ res }) => {
        return res;
      })
    },
    async edituser(data, callback) {
      return await api.put("/user", data, callback).then(({ res }) => {
        return res;
      });
    },
    async getwetoken(data, callback) {
      return await api.get('/weservice/token', data, callback).then((d) => {
        return d;
      });
    },
    async getwecode(data, callback) {
      return await api.post('/weservice/wecode', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getwecodes(data, callback) {
      return await api.post('/weservice/wecodes', data, callback).then(({ res }) => {
        return res;
      });
    },
    async uploadtempmaterial(data, callback) {
      return await api.post('/weservice/uploadtempmaterial', data, callback, ["file"]).then(({ res }) => {
        return res;
      });
    },
    async checkorderstatus(data, callback) {
      return await api.get('/weservice/checkorderstatus/' + data.oCode, data, callback, ["oCode"]).then((d) => {
        return d;
      });
    },
    async generatewxurllink(data, callback) {
      return await api.post('/weservice/generateurllink', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getusercollection(data, callback) {
      return await api.get("/user/collection/" + data.collectionId, data, callback, ["collectionId"]).then((d) => {
        return d;
      });
    },
    async addusercollection(data, callback) {
      return await api.post("/user/addcollection", data, callback).then(({ res }) => {
        return res;
      });
    },
    async delusercollection(data, callback) {
      return await api.post("/user/delcollection/" + data, {}, callback).then(({ res }) => {
        return res;
      });
    },
    async setusercollectiondefault(data, callback) {
      return await api.post("/user/setcollectiondefault", data, callback).then((response) => {
        return response.data;
      });
    },
    async setdefaultusertx(data, callback) {
      return await api.get("/user/batchsetdefaulttx", data, callback).then((d) => {
        return d;
      });
    },
    //分销商
    async getdistributoruser(data, callback) {
      return await api.get("/distributoruser", data, callback).then((d) => {
        return d;
      })
    },
    async getdistributoruserbyid(data, callback) {
      return await api.get("/distributoruser/" + data, "", callback).then((d) => {
        return d;
      })
    },
    async adddistributoruser(data, callback) {
      return await api.post("/distributoruser", data, callback).then(({ res }) => {
        return res;
      })
    },
    async deldistributoruser(data, callback) {
      return await api.post("/distributoruser/delete", data, callback).then(({ res }) => {
        return res;
      })
    },
    async editdistributoruser(data, callback) {
      return await api.put("/distributoruser", data, callback).then(({ res }) => {
        return res;
      })
    },
    async updatedistributoruserstate(data, callback) {
      return await api.put("/distributoruser/updatestate", data, callback).then(({ res }) => {
        return res;
      })
    },
    async getdistributoruserroleinfobycode(data, callback) {
      return await api.get("/distributoruser/roleinfo/" + data, "", callback).then((d) => {
        return d;
      })
    },
    async getdistributorcompany(data, callback) {
      return await api.get("/distributorcompany", data, callback).then((d) => {
        return d;
      })
    },
    async adddistributorcompany(data, callback) {
      return await api.post("/distributorcompany", data, callback).then(({ res }) => {
        return res;
      })
    },
    async editdistributorcompany(data, callback) {
      return await api.put("/distributorcompany", data, callback).then(({ res }) => {
        return res;
      })
    },
    //合作方
    async getcooperateuser(data, callback) {
      return await api.get("/cooperateuser", data, callback).then((d) => {
        return d;
      })
    },
    async getcooperateuserbyid(data, callback) {
      return await api.get("/cooperateuser/" + data, "", callback).then((d) => {
        return d;
      })
    },
    async addcooperateuser(data, callback) {
      return await api.post("/cooperateuser", data, callback).then(({ res }) => {
        return res;
      })
    },
    async delcooperateuser(data, callback) {
      return await api.post("/cooperateuser/Delete", data, callback).then(({ res }) => {
        return res;
      })
    },
    async editcooperateuser(data, callback) {
      return await api.put("/cooperateuser", data, callback).then(({ res }) => {
        return res;
      })
    },
    async updatecooperateuserstate(data, callback) {
      return await api.put("/cooperateuser/updatestate", data, callback).then(({ res }) => {
        return res;
      })
    },
    async getcooperatecompany(data, callback) {
      return await api.get("/cooperatecompany", data, callback).then((d) => {
        return d;
      })
    },
    async addcooperatecompany(data, callback) {
      return await api.post("/cooperatecompany", data, callback).then(({ res }) => {
        return res;
      })
    },
    async editcooperatecompany(data, callback) {
      return await api.put("/cooperatecompany", data, callback).then(({ res }) => {
        return res;
      })
    },
    async getareabyfindname(data, callback) {
      return await api.get("/area/byfindname", data, callback).then(({ res }) => {
        return res;
      })
    },
    async getsalestats(data, callback) {
      return await api.get("/user/salestats", data, callback).then(({ res }) => {
        return res;
      });
    },
    async getgroupmember(data, callback) {
      return await api.get("/user/ingroup", data, callback).then((d) => {
        return d;
      })
    },
    async resetuserpwd(data, callback) {
      return await api.get("/user/resetpwd/" + data, callback).then(({ res }) => {
        return res;
      });
    },
    async finduserisexist(data, callback) {
      return await api.post("/user/IsExist", data, callback).then(({ res }) => {
        return res;
      });
    },
    async getrelatedbygroupleader(callback) {
      return await api.get("/user/relatedbygroupleader", "", callback).then((d) => {
        return d;
      })
    },
    //===========版本管理===========
    async updatenoticetop(data, callback) {
      return await api.put("/notice/updatetop", data, callback).then(({ res }) => {
        return res;
      });
    },
    async getversionmanage(data, callback) {
      return await api.get('/versionmanage', data, callback).then((d) => {
        return d;
      });
    },
    async getversionmanagelasted(callback) {
      return await api.get('/versionmanage/lasted', "", callback).then((d) => {
        return d;
      });
    },
    async addversionmanage(data, callback) {
      return await api.post('/versionmanage', data, callback).then(({ res }) => {
        return res;
      });
    },
    async editversionmanage(data, callback) {
      return await api.put('/versionmanage', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getversionmanagedetail(data, callback) {
      return await api.get('/versionmanage/' + data.id, data, callback, ["id"]).then((d) => {
        return d;
      });
    },
    async getversionmanagebyname(data, callback) {
      return await api.get('/versionmanage/byname/' + data, "", callback).then((d) => {
        return d;
      });
    },
    async delversionmanage(data, callback) {
      return await api.post('/versionmanage/delete', data, callback).then(({ res }) => {
        return res;
      });
    },
    async updateversionmanagestate(data, callback) {
      return await api.put('/versionmanage/UpdateState', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getversionmanagesendrange(data, callback) {
      return await api.get("/versionmanage/sendrange/" + data.id, data, callback).then((d) => {
        return d;
      });
    }
  }
});

