<style lang="scss" scoped>

</style>
<template>
    <div>
        <Modal v-model="modal1" width="830">
            <div slot="header" style="font-size:16px;font-weight:bold;">{{txt?txt:"拜访信息"}}</div>
            <div class="record" v-for="(d,i) in visitfromprospect && visitfromprospect.datas" :key="'record'+i">
                <span class="time">{{d.addTime.replace("T"," ").split(" ")[0].split("-")[1]+"月"+d.addTime.replace("T"," ").split(" ")[0].split("-")[2]+"日"}}</span>
                <div v-html="d.remark">
                    
                </div>
            </div>
            <div slot="footer">
                <Button type="info" @click="setinfomodal({visitId:''})" style="float:right;">确定</Button>
                <br style="clear:both;"/>
            </div>
        </Modal>
    </div>
</template>

<script>
// import {mapGetters,mapActions} from 'vuex'
export default {
  name: 'VisitInfo',
  props:['value','txt'],
  data () {
    return {
        operation:{
            action:""
        },
        modal1:false
    }
  },
  computed:{
    // ...mapGetters([
    //     'result',
    //     'user',
    //     'infomodal',
    //     'visitfromprospect'
    // ])
  },
  mounted(){
  },
  methods:{
    // ...mapActions([
    //     'setinfomodal',
    //     'getvisitfromprospect'
    // ]),
    setmodal(b){
        if(!b){
            this.setinfomodal({visitId:""});
        }
    }
  },
  watch:{
    "infomodal":function(){
        if(this.infomodal.visitId){
            this.getvisitfromprospect(this.infomodal.visitId)
            this.modal1 = true;
        }
        else{
            this.modal1 = false;
        }
    }
  }
}
</script>

