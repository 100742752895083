<style lang="scss" scoped>
.tabletabbs {
    .btnbox {
        display: flex;
        height: 32px;
        margin-bottom: 5px;

        .boxleft,
        .boxright {
            box-sizing: border-box;
        }

        .boxleft {
            flex: 1;
            display: flex;

            button,
            >div {
                margin-right: 5px;
            }

            .filtertags {
                flex: 1;
                height: 100%;
            }
        }

        .boxright {
            width: 160px;
            text-align: center;
            margin-right: 10px;
        }

        .filtertags {
            width: 20px;
            height: 20px;
            display: inline-block;
        }
    }

    .tabsbottom {
        display: flex;

        .li {
            width: 40%;
        }

        .li:last-child {
            width: 60%;
        }
    }
}
</style>
<style>
.tabletabbs .boxleft button {
    margin-right: 5px;
}

.tabletabbs .boxleft>div {
    margin-right: 5px;
}
</style>
<template>
    <div ref="tabletabs" class="tabletabbs" style="display:flex;flex-direction:column;">
        <AdvanceFilter v-model="advancesearchstate" :bind="bind" @on-change="filterHandle" />
        <Tabs value="name1" v-model="value" @on-click="changeHandle">
            <TabPane :label="d.name" :name="d.id" v-for="(d, i) in tabs" :key="'tabs' + i"></TabPane>
            <template #extra>
                <Input icon="ios-search" v-model="searchval" clearable @on-clear="clearHandle" placeholder="请输入关键字搜索..."
                    style="width: 240px;float:right;" @on-enter="changeHandle" @on-click="changeHandle">
                <template #prepend v-if="searchlist.length > 0">
                    <Select v-model="searchkey" style="width: 80px" @on-change="selectChangeHandle">
                        <Option :value="d.value" v-for="(d, i) in searchlist" :key="'searchselect' + i">{{ d.label }}
                        </Option>
                    </Select>
                </template>
                </Input>
                <div style="float:right;">
                    <slot name="extrachild"></slot>
                </div>
                <Poptip trigger="hover" content="列表刷新" style="float:right;margin:8px 5px;" placement="left">
                    <Icon type="ios-refresh" class="advancefilter" size="18" @click="changeHandle"
                        style="margin:0px 5px;float:right;"></Icon>
                </Poptip>
                <Poptip trigger="hover" v-if="stats" content="统计报表" style="float:right;margin:10px 5px;"
                    placement="left">
                    <Icon type="ios-stats" class="advancefilter" size="14" @click="statsHandle"
                        style="margin:0px 5px;float:right;"></Icon>
                </Poptip>
                <Poptip trigger="hover" v-if="bind" content="高级过滤" style="float:right;margin:10px 5px;"
                    placement="left">
                    <Icon type="ios-funnel" class="advancefilter" size="14" @click="advancesearchstate = true"
                        style="margin:0px 5px;float:right;"></Icon>
                </Poptip>
            </template>
        </Tabs>
        <div class="btnbox">
            <div class="boxleft">
                <Button v-if="!noadd" type="info" @click="goEditPage" icon="md-add">新增{{ addtxt }}</Button>
                <Button v-if="!nodel" @click="delDataConfirm" icon="md-close">{{ deltxt || '批量删除' }}</Button>
                <slot name="button"></slot>
                <div class="filtertags"></div>
            </div>
            <div class="boxright" v-if="!nodaterange">
                <!-- <slot name="datefilter"> -->
                <StatsDateRange v-model="childdaterange" @on-change="changeHandle" />
                <!-- </slot> -->
            </div>
        </div>
        <slot name="table"></slot>
        <div class="tabsbottom">
            <div class="li" style="padding-top:15px;">
                <slot name="extradatas"></slot>
            </div>
            <div class="li" v-if="haspage">
                <Page :total="params.total" show-total show-sizer show-elevator style="text-align:right;margin-top:5px;"
                    :current="params.pageindex" :page-size="params.pagesize" @on-page-size-change="pagesizeChange"
                    @on-change="page"></Page>

            </div>
        </div>
    </div>
</template>

<script>
import AdvanceFilter from '@/components/tabletabs/advancefilter/index'
export default {
    name: 'TableTabs',
    props: {
        tabs: {
            type: Array,
            default: () => ([{ id: "-1", name: '全部' }])
        },
        searchlist: {
            type: Array,
            default: () => ([])
        },
        noadd: {
            type: Boolean,
            default: false
        },
        addurl: String,
        nodel: {
            type: Boolean,
            default: false
        },
        nodaterange: {
            type: Boolean,
            default: false
        },
        addtxt: {
            type: String,
            default: ""
        },
        deltxt: {
            type: String,
            default: ""
        },
        load: Function,
        init: Function,
        modelValue: String,
        params: {
            type: Object,
            default: () => ({ pageindex: 1, pagesize: 20, total: 0 })
        },
        firstsearchkey: String,
        daterange: {
            type: String,
            default: "-1"
        },
        haspage: {
            type: Boolean,
            default: true
        },
        addfunc: Function,
        bind: String
    },
    components: { AdvanceFilter },
    data() {
        return {
            tabsindex: this.modelValue || '-1',
            searchkey: "",
            searchval: "",
            searchcondition: "",
            childdaterange: "-1",
            advancesearchstate: false,
            delconfirm: false,
            filters: []
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.tabsindex = value;
                this.$emit("update:modelValue", value);
            }
        }
    },
    mounted() {
        this.tabsindex = this.modelValue;
        this.childdaterange = this.daterange;
        if (this.init) {
            this.init(() => {
                if (!this.tabsindex || this.tabsindex == "-1") {
                    this.tabsindex = this.tabs[0].id;
                }
                if (this.load) {
                    this.changeHandle();
                }
            });
        }
        else {
            if (this.load) {
                this.changeHandle();
            }
        }

        if (this.searchlist.length > 0) {
            if (this.firstsearchkey) {
                let $select = this.searchlist.filter(f => f.value == this.firstsearchkey);
                this.searchkey = $select[0].value;
                this.searchcondition = $select[0].condition || 0;
            }
            else {
                this.searchkey = this.searchlist[0].value;
                this.searchcondition = this.searchlist[0].condition || 0;
            }
        }
    },
    methods: {
        changeHandle() {
            let _params = this.setParams();

            let p = { page: this.params.pageindex, row: this.params.pagesize, type: this.childdaterange, advanceFilter: _params };
            _params.forEach((e, i) => {
                if (e.name == "state") {
                    p.state = e.value;
                    _params.splice(i, 1);
                }
                else if (e.name == "name") {
                    p.name = e.value;
                    _params.splice(i, 1);
                }
            });
            setTimeout(() => {
                this.load(p);
            }, 0);
        },
        refresh() {
            //给外部调用的
            this.changeHandle();
        },
        clearHandle() {
            this.load({ page: this.params.pageindex, row: this.params.pagesize });
        },
        statsHandle() { },
        goEditPage() {
            let editpath = "";
            if (this.addfunc) {
                this.addfunc();
            }
            else {
                if (this.addurl) {
                    editpath = this.addurl;
                }
                else {
                    let _path = this.$route.path;
                    editpath = _path + "edit?from=" + _path;
                }
                this.$router.push(editpath);
            }
        },
        delDataConfirm() {
            this.$parent.delConfirm(this.$parent.selection);
        },
        pagesizeChange(val) {
            this.$emit("update:params", { pageindex: this.params.pageindex, pagesize: val, total: this.params.total });
            setTimeout(() => {
                this.changeHandle();
            }, 0);
        },
        page(index) {
            this.$emit("update:params", { pageindex: index, pagesize: this.params.pagesize, total: this.params.total });
            setTimeout(() => {
                this.changeHandle();
            }, 0);
        },
        adaptionTbl() {
            setTimeout(() => {
                if (this.height) {
                    this.tblheight = this.height;
                }
                else {
                    //适配屏幕大小
                    let $bodyright = document.getElementsByClassName("layout-menu-right")[0].offsetHeight;
                    let $ivurow = 0;
                    if (document.getElementsByClassName("custombtn").length > 0) {
                        $ivurow = document.getElementsByClassName("custombtn")[0].offsetHeight;
                    }
                    this.tblheight = $bodyright - 152 - $ivurow;
                }
            }, 200);
        },
        setParams() {
            let _params = [];
            if (this.searchkey && this.searchval) {
                _params.push({
                    name: this.searchkey,
                    condition: this.searchcondition,
                    value: this.searchval
                });
            }
            if (this.tabsindex != "-1") {
                var $filter = this.tabs.filter(f => f.id == this.tabsindex);
                if ($filter.length > 0) {
                    let key = $filter[0].filterkey || "state";
                    let val = $filter[0].id;
                    _params.push({
                        name: key,
                        condition: 2,
                        value: val
                    });
                }
            }

            if(this.filters.length>0){
                this.filters.forEach(e1 => {
                    _params.push(e1);
                })
            }

            return _params;
        },
        selectChangeHandle(val) {
            var $select = this.searchlist.filter(f => f.value == val);
            if ($select.length > 0) {
                this.searchcondition = $select.condition || 0;
            }
        },
        filterHandle(e) {
            this.filters = [];
            if (e && e.length > 0) {
                e.forEach(e1 => {
                    this.filters.push({
                        name:e1.name,
                        condition:e1.condition,
                        value:e1.value,
                        force:e1.force
                    });
                })
            }
            this.changeHandle();
        }
    }
}
</script>

