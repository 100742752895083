/*
 * @Author: GT
 * @Date: 2018-05-29 13:06:55
 * @LastEditors: GT
 * @LastEditTime: 2018-05-29 13:07:17
 * @Description:
 */

let util = {};
util.title = function (title) {
  title = title ? title + " - 工作平台" : "工作平台";
  window.document.title = title;
};

//当前时间
util.currentTime = function () {
  var now = new Date();
  var year = now.getFullYear(); //年
  var month = now.getMonth() + 1; //月
  var day = now.getDate(); //日

  var hh = now.getHours(); //时
  var mm = now.getMinutes(); //分
  var ss = now.getSeconds();

  var clock = year + "-";
  if (month < 10) clock += "0";
  clock += month + "-";
  if (day < 10) clock += "0";
  clock += day + " ";
  if (hh < 10) clock += "0";
  clock += hh + ":";
  if (mm < 10) clock += "0";
  clock += mm + ":";
  if (ss < 10) clock += "0";
  clock += ss;
  return clock;
};

util.currentDate = function (type) {
  var date = new Date();
  let _year = date.getFullYear();
  let _month = date.getMonth() + 1;
  if (parseInt(_month) < 10) {
    _month = "0" + _month;
  }
  let _day = date.getDate();
  if (parseInt(_day) < 10) {
    _day = "0" + _day;
  }
  if (type == 1) {
    return _year + "-" + _month + "-" + _day;
  }
  else {
    return { year: _year, month: _month, day: _day }
  }
}

util.currentDateTime = function () {
  var date = new Date();
  let _year = date.getFullYear();
  let _month = date.getMonth() + 1;
  if (parseInt(_month) < 10) {
    _month = "0" + _month;
  }
  let _day = date.getDate();
  if (parseInt(_day) < 10) {
    _day = "0" + _day;
  }

  var hh = date.getHours(); //时
  var mm = date.getMinutes(); //分
  var ss = date.getSeconds();
  return { year: _year, month: _month, day: _day, hour: hh, minute: mm, second: ss }
}

util.nextMonth = function () {
  let _month = parseInt(this.currentDate().month) + 1;
  if (_month < 10) {
    return "0" + _month;
  }
  return _month;
}

//转换成decimal格式,保留两位小数
util.toDecimal2 = function (x) {
  var f = parseFloat(x);
  if (isNaN(f)) {
    return false;
  }
  f = Math.round(x * 100) / 100;
  var s = f.toString();
  var rs = s.indexOf(".");
  if (rs < 0) {
    rs = s.length;
    s += ".";
  }
  while (s.length <= rs + 2) {
    s += "0";
  }
  return s;
};

//新的JSON对象
util.newObj = function (obj) {
  return JSON.parse(JSON.stringify(obj));
};

util.datamap = function (d, s) {
  for (let k in s) {
    for (let m in d) {
      if (k == m) {
        s[k] = d[k];
      }
    }
  }
};

//从date对象转换成字符串
util.formatTime = function (date) {
  if (typeof date == "object") {
    if (date) {
      let _moth = date.getMonth() + 1;
      let _date = date.getDate();
      return (
        date.getFullYear() +
        "-" +
        _moth +
        "-" +
        _date +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
      );
    }
  } else {
    return date;
  }
};

//从字符串转换成date对象
util.formatObj = function (date) {
  if (typeof date == "object") {
    return date;
  } else {
    date = date.replace("T", " ");
    return new Date(date.replace(/-/g, "/"));
  }
};

//格式化,去掉日期中的T
util.formatTimeStr = function (data) {
  if (!data) {
    return "--";
  } else {
    return (data == "0001-01-01T00:00:00" || data == "0001-01-01 00:00:00") ? "--" : data.replace("T", " ");
  }
};

//结合TTable组件,设置过滤条件
util.loadFilter = function (args, convertParams) {
  let p = { page: 1, row: 20, type: "-1", params: [] };

  if (args) {
    for (let key in args) {
      if (key != 'advanceFilter') {
        p[key] = args[key];
      }
    }
    // for (let key in args.params) {
    //   p[key] = args.params[key];
    // }
    if (args.advanceFilter && args.advanceFilter.length > 0) {
      args.advanceFilter.forEach((v) => {
        p.params.push({
          name: v.key || v.name,
          condition: v.condition,
          value: v.value,
          force: v.force ? true : false
        });
      });
    }

  }
  return p;
};

util.loadGetFilter = function (args, convertParams) {
  //convertParams格式:
  //['a','b']
  //目的将params的值改成urlparams
  //并将params对象设置为[]
  var p = this.loadFilter(args, convertParams);
  if (convertParams && typeof convertParams == "object" && convertParams.length > 0 && p.params.length > 0) {
    convertParams.forEach(e => {
      var _filter = p.params.filter(f => f.name == e);
      if (_filter.length > 0) {
        p[e] = _filter[0].value
      }
    })
    p.params = [];
  }
  return p;
}
util.loadPostFilter = function (args, convertParams) {
  //convertParams格式:
  //[{from:'a',to:'b',condition:12}]
  //目的将params的key值改成指定值
  var p = this.loadFilter(args, convertParams);
  if (convertParams && convertParams.length > 0) {
    convertParams.forEach(e => {
      p.params.forEach((e1, i) => {
        if (e1.name == e.from) {
          e1.name = e.to;
          if (e.condition) {
            e1.condition = e.condition;
          }
        }
        else if (e1.name == e.name) {
          p[e.name] = e1.value;
          p.params.splice(i, 1);
        }

      });


    });
  }
  return p;
}

//比较日期大小
//type        0或者空:不包含时间 1:包含时间
//targetdate 目标时间
//sourcedate 当前时间
//result     0 当前时间大于目标时间 1 当前时间小于目标时间 2 当前时间等于目标时间 -1表示错误比较
util.comparedate = function (args) {
  let _result = -1;
  if (args && args.targetdate) {
    let _sourcedate = args.sourcedate || this.currentTime();

    if (!args.type || args.type == 0) {
      _sourcedate = _sourcedate.split(" ")[0];
    }
    let _targetdate = args.targetdate;

    let sd = new Date(_sourcedate);
    let td = new Date(_targetdate);
    if (sd.getTime() > td.getTime()) {
      _result = 0;
    } else if (sd.getTime() < td.getTime()) {
      _result = 1;
    } else if (sd.getTime() == td.getTime()) {
      _result = 2;
    }
  }
  return _result;
};

util.isToday = function (date) {
  let _result = 1;
  if (date) {
    let td = new Date(date.replace("T", " ").split(" ")[0]);
    let sd = new Date(this.currentTime().split(" ")[0]);
    if (sd.getTime() == td.getTime()) {
      _result = 0;
    }
  }
  return _result;
};

util.time = function (times) {
  if (!times || times <= 0) {
    return 0;
  }
  // let timer = null;
  // timer = setInterval(function(){
  var day = 0,
    hour = 0,
    minute = 0,
    second = 0;
  if (times > 0) {
    day = Math.floor(times / (60 * 60 * 24));
    hour = Math.floor(times / (60 * 60)) - day * 24;
    minute = Math.floor(times / 60) - day * 24 * 60 * 60 - hour * 60;
    second =
      Math.floor(times) - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60;
  }
  if (day <= 9) day = "0" + day;
  if (hour <= 9) hour = "0" + hour;
  if (minute <= 9) minute = "0" + minute;
  if (second <= 9) second = "0" + second;
  //console.info(day+"天:"+hour+"小时:"+minute+"分钟:"+second+"秒");
  let d = hour + "小时:" + minute + "分钟:" + second + "秒";
  // times--;
  console.warn(d);
  return d;
  // },1000);
  // if(times<=0){
  //     clearInterval(timer);
  // }
};

util.addDate = function (days) {
  if (days == undefined || days == "") {
    days = 1;
  }
  var date = new Date();
  date.setDate(date.getDate() + days);
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var mm = "'" + month + "'";
  var dd = "'" + day + "'";
  //单位数前面加0
  if (mm.length == 3) {
    month = "0" + month;
  }
  if (dd.length == 3) {
    day = "0" + day;
  }
  var time =
    date.getFullYear() + "-" + month + "-" + day + " " + hours + ":" + minutes;

  return time;
};

util.monthDayNum = function (args) {
  var curDate = new Date();
  var curMonth = curDate.getMonth();
  if (args) {
    curMonth = args - 1;
  }
  curDate.setMonth(curMonth + 1);
  curDate.setDate(0);
  return curDate.getDate();
};

util.divideMonth = function (m, d) {
  let _date = new Date();
  if (!m) {
    m = _date.getMonth() + 1;
  }
  if (!d) {
    d = _date.getDate();
  }

  let _day = 15;
  let _range1 = "";
  let _range2 = "";
  if (d > _day) {
    _range1 = m + "月" + (_day + 1) + "日-" + m + "月" + util.monthDayNum(m) + "日";
    let _m = m + 1;
    if (_m > 12) {
      _m = "01";
    }
    _range2 = _m + "月1日-" + _m + "月" + (_day) + "日";
  }
  else {
    _range1 = m + "月1日-" + m + "月" + (_day) + "日";
    _range2 = m + "月" + (_day + 1) + "日-" + m + "月" + util.monthDayNum(m) + "日";

  }
  return [_range1, _range2];
}

util.dayWeek = function (d) {
  var date = new Date();
  var a = new Array("日", "一", "二", "三", "四", "五", "六");
  let _d = d.split('-');
  let _month = _d[0];
  // let _day =  _d[1];
  let _year = date.getFullYear();
  if (date.getMonth() + 1 > parseInt(_month)) {
    _year += 1;
  }
  var week = new Date(_year + "-" + d).getDay();
  return "周" + a[week];
};



util.startWeekDay = function () {
  var date = new Date();
  var nowDayOfWeek = date.getDay();
  var nowDay = date.getDate();
  //已周日为一周的第一天
  var startDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    nowDay - nowDayOfWeek
  );
  return startDay + 1;
};
//一般用于Get请求
//url:链接
//data:需要拼接的数据(JSON)
//exclude:排除data中的字段不进行拼接(array)
util.concatUrl = function (url, data, exclude) {
  if (typeof exclude == "object") {
    ["params", "page", "row"].forEach(e => {
      exclude.push(e);
    });
  }
  else {
    exclude = [];
  }
  for (let key in data) {
    let isfind = false;
    exclude.forEach(e => {
      if (key == e) {
        isfind = true;
      }
    });
    if (!isfind) {
      if (url.indexOf("?") > -1) {
        url += "&" + key + "=" + data[key];
      } else {
        url += "?" + key + "=" + data[key];
      }
    }
  }
  return url;
};

util.securityphone = function (str) {
  if (str) {

    let p1 = str.substring(0, 3);
    let p2 = str.substring(3, 8);
    let p3 = str.substring(8, 11);
    return p1 + p2.replace(/\d/g, "*") + p3;
  }
  else {
    return str;
  }
}

util.securitypstr = function (str) {
  if (str) {
    var len = str.toString().length;
    if (len == 11) {
      return this.securityphone(str);
    }
    else {
      var num = parseInt(len / 2);
      let p1 = str.substring(0, num);
      let p2 = str.substring(num, len);
      return p1 + p2.replace(/\d/g, "*");
    }
  }
  else {
    return str;
  }
}

util.getconnuserinfo = function (usr, extendobj) {
  let obj = new Object();
  obj.userId = usr.id;
  obj.status = "onlineuser";
  for (let key in extendobj) {
    obj[key] = extendobj[key];
  }
  return obj;
}

util.difftime = function (d1, type) {
  //type 1 返回秒
  //type 其它  返回天/小时/分钟/秒

  //d1作为一个变量传进来
  //如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
  var dateBegin = new Date(d1);//将-转化为/，使用new Date
  var dateEnd = new Date();//获取当前时间
  var dateDiff = dateEnd.getTime() - dateBegin.getTime();//时间差的毫秒数

  var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000));//计算出相差天数
  var leave1 = dateDiff % (24 * 3600 * 1000)    //计算天数后剩余的毫秒数
  var hours = Math.floor(leave1 / (3600 * 1000))//计算出小时数
  //计算相差分钟数
  var leave2 = leave1 % (3600 * 1000)    //计算小时数后剩余的毫秒数
  var minutes = Math.floor(leave2 / (60 * 1000))//计算相差分钟数
  //计算相差秒数
  var leave3 = leave2 % (60 * 1000)      //计算分钟数后剩余的毫秒数
  var seconds = Math.round(leave3 / 1000);
  if (type == 1) {
    return dateDiff / 1000;
  }
  else {
    return dayDiff + "天 " + hours + "小时 " + minutes + " 分钟" + seconds + " 秒";
  }
  // console.info(dateDiff+"时间差的毫秒数",dayDiff+"计算出相差天数",leave1+"计算天数后剩余的毫秒数"
  //     ,hours+"计算出小时数",minutes+"计算相差分钟数",seconds+"计算相差秒数");
}

util.plus = function (a, b) {
  let num = (a) => {
    if (a != null && a.toString() != "") {
      let r = /^-?(0|[1-9]+\d*|[1-9]+\d*\.\d+|0\.\d+)$/;
      if (r.test(a.toString())) {
        return true;
      }
    }
    return false;
  }
  if (!num(a) || !num(b)) {
    return null;
  }
  let c, d, m;
  try {
    c = a.toString().split(".")[1].length;
  } catch (e) {
    c = 0;
  }

  try {
    d = b.toString().split(".")[1].length;
  } catch (e) {
    d = 0;
  }
  m = Math.pow(10, Math.max(c, d));
  return (a * m + b * m) / m;
}

util.multiply = function (a, b) {
  var m = 0,
    s1 = a.toString(),
    s2 = b.toString(),
    number,
    z = '';
  try {
    m += s1.split(".")[1].length
  } catch (e) {
    console.log(e);
  }
  try {
    m += s2.split(".")[1].length
  } catch (e) {
    console.log(e);
  }
  console.log(`m = ${m}`)
  number = Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
  if (m) {
    try {
      let n = m - number.toString().split(".")[1].length;
      if (n) {
        for (let i = 0; i < n; i++) {
          z += '0';
        }
      }

      let r = number.toString() + z;
      return r

    } catch (e) {
      for (let i = 0; i < m; i++) {
        z += '0';
      }
      let r = number.toString() + '.' + z;
      return r
    }
  }
  return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}

util.imgTxt = function (file) {
  let formItem = {
    tradeCode: "",
    tradePrice: 0,
    tradeTime: ""
  };
  if (file.error != -1) {
    let fileId = JSON.parse(file.id);
    formItem.filepath = file.filepath;
    let payfrom = "";//0:淘宝 1:建设银行公账 2:向总微信 3:微信商户 4:支付宝 5:微信商城 6:全民支付
    fileId.forEach(e => {
      if (e.words.indexOf("邮费:") > -1) {
        payfrom = "0";
      }
      else if (e.words.indexOf("大写金额") > -1) {
        payfrom = "1";
      }
      else if (e.words.indexOf("扫二维码付款") > -1 || e.words.indexOf("微信转账") > -1) {
        payfrom = "2";
      }
      else if (e.words == "商户全称湖南鼎川建筑设计有限公司" || e.words == "收款记录") {
        payfrom = "3";
      }
      else if (e.words == "商家订单号") {
        payfrom = "4";
      }
      else if (e.words == "我的订单") {
        payfrom = "5";
      }
      else if (e.words == "商品详情湖南鼎川建筑设计有限公司") {
        payfrom = "6";
      }
    });
    fileId.forEach((e, i) => {
      if (payfrom == "0") {
        let isPrice = parseFloat(e.words.replace("￥", ""));
        if (!isNaN(isPrice) && e.words.indexOf("￥") > -1) {
          formItem.tradePrice = isPrice;
        }
        if (e.words.indexOf("订单编号:") > -1) {
          formItem.tradeCode = e.words.replace("订单编号:", "");
        }
        if (e.words.indexOf("付款时间:") > -1) {
          formItem.tradeTime = e.words.replace("付款时间:", "");
        }
      }
      else if (payfrom == "1") {
        if (e.words.indexOf("转账金额￥") > -1) {
          formItem.tradePrice = parseFloat(e.words.replace("转账金额￥", ""));
        }
        if (e.words == "户名") {
          formItem.tradeCode = fileId[i + 1].words;
        }
        if (e.words == "账号") {
          formItem.tradeCode += "@" + fileId[i + 1].words;
        }
        formItem.tradeTime = "";
      }
      else if (payfrom == "2") {
        if (e.words.indexOf("扫二维码付款") > -1) {
          formItem.tradePrice = Math.abs(parseFloat(fileId[i + 1].words));
        }
        if (e.words.indexOf("当前状态已存入零钱") > -1) {
          formItem.tradePrice = Math.abs(parseFloat(fileId[i - 1].words));
        }
        if (e.words.indexOf("转账单号") > -1 || e.words.indexOf("转专账单号") > -1) {
          let _tradecode = e.words.replace("转账单号", "").replace("转专账单号", "");
          if (_tradecode.length > 0) {
            formItem.tradeCode = e.words.replace("转账单号", "").replace("转专账单号", "") + (fileId[i + 1] ? fileId[i + 1].words : "");
          }
          else {
            formItem.tradeCode = fileId[i + 1].words.replace("转账单号", "").replace("转专账单号", "") + (fileId[i + 2] ? fileId[i + 2].words : "");
          }
        }
        if (e.words == "转账时间") {
          formItem.tradeTime = fileId[i + 1].words;
        }
        if (e.words.indexOf("转账时间") > -1 && e.words.length > 4) {
          formItem.tradeTime = e.words.replace("转账时间", "");
        }
      }
      else if (payfrom == "3") {
        if (e.words == "湖南鼎川建筑设计有限公司") {
          let _price = Math.abs(parseFloat(fileId[i + 1].words))
          if (!isNaN(_price)) {
            formItem.tradePrice = _price > 10000 ? _price / 100 : _price;
          }
        }
        if (e.words == "★★★") {
          let _price = Math.abs(parseFloat(fileId[i + 1].words.replace("￥", "")))
          formItem.tradePrice = _price > 10000 ? _price / 100 : _price;
        }
        if (e.words.indexOf("交易单号") > -1) {
          formItem.tradeCode = e.words.replace("交易单号", "");
          if (!isNaN(fileId[i + 1].words)) {
            formItem.tradeCode += fileId[i + 1].words;
          }
        }
        if (e.words.indexOf("商户单号") > -1) {
          formItem.tradeCode = e.words.replace("商户单号", "");
          if (!isNaN(fileId[i + 1].words)) {
            formItem.tradeCode += fileId[i + 1].words;
          }
        }
        if (e.words.indexOf("支付时间") > -1) {
          formItem.tradeTime = e.words.replace("支付时间", "");
        }
      }
      else if (payfrom == "4") {
        if (e.words.indexOf("交易成功") > -1) {
          formItem.tradePrice = Math.abs(parseFloat(fileId[i - 1].words));
        }
        if (e.words == "订单号") {
          formItem.tradeCode = fileId[i + 1].words;
        }
        if (e.words == "创建时间") {
          formItem.tradeTime = fileId[i + 1].words;
        }
      }
      else if (payfrom == "5") {
        if (e.words.indexOf("订单号:") > -1) {
          formItem.tradeCode = e.words.split(":")[1];
        }
        if (e.words.indexOf("商品实付:") > -1) {
          formItem.tradePrice = parseFloat(e.words.split("￥")[1]);
        }
      }
      else if (payfrom == "6") {
        if (e.words.indexOf("订单号") > -1) {
          formItem.tradeCode = e.words.replace("订单号", "");
        }
        if (e.words.indexOf("支付成功") > -1) {
          formItem.tradePrice = parseFloat(fileId[i - 1].words.split("￥")[1]);
        }
      }
    });
  }

  return formItem;
};


util.setchatimg = function (content) {
  // var imgs = "";
  // var imgregx = content.match(/<img.*(class="emojiimg").*?>/g);
  // if(imgregx){
  //     imgs = imgregx.join('');
  // }

  var _content = content.replace(/<img.*(class="emojiimg").*?>/g, '[表情]');
  _content = content.indexOf("shotscreenimg") > -1 ? "[图片]" : content; //replace(/<img.*(class="shotscreenimg").*?\/>/g,'[图片]');
  _content = content.replace(/<div.*(class="filecontainer").*?>/g, '[文件]');
  // _content = _content.replace(/<img(?![^<>]*?class[^<>]*?>).*?>/g,'[图片]');
  return _content;
}

util.adaptiontable = function (_this) {
  let ap = function () {
    setTimeout(() => {
      let _height = _this.$refs.adaptiontable.$el.offsetHeight;
      _this.$refs.adaptiontable.$el.className = "ivu-table-wrapper ivu-table-wrapper-with-border";
      _this.tblheight = _height;
    }, 0);
  }
  ap();
  window.onresize = function () {
    _this.$refs.adaptiontable.$el.className = "adaptiontable ivu-table-wrapper ivu-table-wrapper-with-border";
    ap();
  }
}

util.downloadAs = function (url, filename) {
  var saveAs = function (blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement('a');
      var body = document.querySelector('body');
      link.href = window.URL.createObjectURL(blob);
      link.download = decodeURI(decodeURI(filename));
      // fix Firefox
      link.style.display = 'none';
      body.appendChild(link);

      let evt = document.createEvent("HTMLEvents");
      evt.initEvent("click", true, true);

      link.click();
      body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    }
  }


  var xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';

  // xhr.overrideMimeType='text/plain;charset=gb2312',
  xhr.onload = function () {
    if (xhr.status === 200) {
      saveAs(xhr.response, filename);
    }
  };
  xhr.send();


}

util.sizeStr = function (limit) {
  var size = "";
  if (limit < 0.1 * 1024) {                            //小于0.1KB，则转化成B
    size = limit.toFixed(2) + "B"
  } else if (limit < 0.1 * 1024 * 1024) {            //小于0.1MB，则转化成KB
    size = (limit / 1024).toFixed(2) + "KB"
  } else if (limit < 0.1 * 1024 * 1024 * 1024) {        //小于0.1GB，则转化成MB
    size = (limit / (1024 * 1024)).toFixed(2) + "MB"
  } else {                                            //其他转化成GB
    size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB"
  }

  var sizeStr = size + "";                        //转成字符串
  var index = sizeStr.indexOf(".");                    //获取小数点处的索引
  var dou = sizeStr.substr(index + 1, 2)            //获取小数点后两位的值
  if (dou == "00") {                                //判断后两位是否为00，如果是则删除00
    return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
  }
  return size;
}

util.uuid = function () {
  // var d = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0;
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
}


util.checkphone = function (phone) {
  var reg = /^1[0-9]{10}$/;
  if (!reg.test(phone)) {
    return false;
  }
  else {
    return true;
  }
}


util.formatYearMonth = function (date) {
  let _datemonth = this.formatTime(date).split("-");
  return _datemonth[0] + '-' + _datemonth[1];
}

util.currentPath = function () {
  if (window.localStorage) {
    let _breadpath = window.localStorage["breadpath"];
    if (_breadpath) {
      var result = JSON.parse(_breadpath);
      if (result && result.length > 0) {
        return result[result.length-1];
      }
      else {
        return "";
      }
    }
  }
  else {
    console.error("当前浏览器不支持localStorage!!");
    return "";
  }
}

util.webmodelCanUpdate = function ($this) {
  if ($this.$route && $this.selected.$route) {
    if ($this.$route.path == $this.selected.$route.path) {
      return true;
    }
    else {
      return false;
    }
  }
  else {
    return false;
  }
}
export default util;
