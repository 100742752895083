<style lang="scss">
    .ivu-breadcrumb{
        cursor: pointer;
    }
    .layout-breadcrumb{
        background-color:#f0f0f0;
    }
</style>
<template>
    <div class="layout-breadcrumb" ref="layoutbreadcrumb"  :style="comms.layoutcontent.style" v-if="$route.path!='/m' && $route.path!='/boss'">
        <div v-if="$route.path=='/projectinfo' || $route.path=='/projecttask' || $route.path=='/projectdoc' || $route.path=='/projectschedule'|| $route.path=='/projectchat'">
            <Button style="position:absolute;top:15px;" @click="$router.push({path:$route.query.from=='overproject'?'overproject':'project',query:{tabsindex:$route.query.tabsindex}})" icon="ios-undo">返回</Button>
            <Tabs class="projecttasktabs" v-model="tabsindex1" >
                <TabPane label="信息" name="projectinfo"></TabPane><!-- v-if="projectdetailtabs" -->
                <TabPane label="任务" name="projecttask"></TabPane><!-- v-if="projecttasktabs" -->
                <TabPane label="文件" name="projectdoc"></TabPane>
                <!-- <TabPane label="日程" name="projectschedule"></TabPane> -->
                <!-- <TabPane label="群聊" name="projectchat"></TabPane> -->
            </Tabs>
        </div>
        <Breadcrumb v-else style="background-color:#f0f0f0">
            <template v-if="!breadpath">
                <BreadcrumbItem href="#" style="cursor:auto;"><span>首页</span></BreadcrumbItem>
            </template>
            <template v-else>
                <BreadcrumbItem href="#"><span @click="$router.push((user.code=='BOSS' || user.code=='SUPERADMIN')?'boss':'m')">首页</span></BreadcrumbItem>
                <BreadcrumbItem href="#" v-for="(d,i) in breadpath" :key="'breadcrumitem'+i"><span @click="$router.push(d)">{{d.meta.title}}</span></BreadcrumbItem>
            </template>
        </Breadcrumb>
    </div>
</template>

<script>
// import {mapGetters,mapActions} from 'vuex'
import Cache from '@/libs/cache';
import Cookies from 'js-cookie'
export default {
  name: 'BreadCrumb',
  props:[],
  data () {
    return {
        comms:{
            layoutcontent:{
                style:""
            }
        },
       tabsindex1:"projecttask",
       breadpath:"",
       projectdetailtabs:false,
       projecttasktabs:true
    }
  },
  computed:{
    // ...mapGetters([
    //     'user',
    //     'comms'
    // ])
  },
  mounted(){
      this.init(); 
      let _path = this.$route.path;
      if(_path.indexOf("project")>-1){
          this.tabsindex1 = _path.substring(1);
      }
  },
  methods:{
    // ...mapActions([
    //    'getdesigncategory'
    // ]),
    init(){
        this.breadpath = Cache.read("breadpath");
        if(this.$route.query.projectId){
            let _path = this.$route.path;
            if(_path.indexOf("project")>-1){
                this.tabsindex1 = _path.substring(1);
            }
            Cookies.set("projecttabs",this.$route.query);
            //入库的项目,在设计师获取以后，无法查看项目详情
            let _noshow = false;
            this.getdesigncategory().then(d=>{
                if(d.code==0){
                    d.datas.forEach(e=>{
                        if(e.roleId==this.user.roleId && e.inStore==1){
                            _noshow = true;
                        }
                    });
                    
                    if(!_noshow){
                    this.projectdetailtabs = true;
                    }
                }
            });

            if(this.user.code=="SALES"){
                this.projecttasktabs = false;
            }
        }
    }
  },
  watch:{
    "$route":function(){
        this.init();
    },
    "tabsindex1":function(){
        let _query = this.$route.query;
        _query.istop = 0;
        setTimeout(()=>{
            if(_query.projectId){
                this.$router.push({path:this.tabsindex1,query:_query});
            }
            else{
                let projecttabs = Cookies.get("projecttabs");
                if(projecttabs){
                    this.$router.push({path:this.tabsindex1,query:JSON.parse(projecttabs)});
                }
            }
        },500);
    }
  }
}
</script>

