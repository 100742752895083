<style lang="scss" scoped>

</style>
<template>
    <div>
        <Select v-model="level" @on-change="changeHandler" style="width:70px">
            <Option v-for="item in levelList" :value="item.value" :key="item.value">{{ item.label }}</Option>
        </Select>
    </div>
</template>

<script>
// import {mapGetters,mapActions} from 'vuex'
export default {
  name: 'FormLevel',
  props:[],
  data () {
    return {
        level:"0",
        levelList:[{
            label:"普通",
            value:"0"
        },{
            label:"一般",
            value:"1"
        },{
            label:"重要",
            value:"2"
        }]
    }
  },
  computed:{
    // ...mapGetters([
    //     'user',
    //     'result',
    //     'member',
    //     'comms'
    // ])
  },
  mounted(){
      
  },
  methods:{
    // ...mapActions([
    //    'getmember',
    //    'setcomms'
    // ]),
    changeHandler(){
        this.$emit("input",this.level);
    }
  },
  watch:{
   
  }
}
</script>

