
<template>
    <Modal v-model="value" width="830" :styles="{'top':'10px'}" :mask-closable="false" :closable="false">
        <p slot="header">
            <span>高级搜索</span>
        </p>
        <div style="padding:10px 0px;">
            <Form :model="formItem" ref="formItem" :label-width="90" inline>
                <FormItem label="分配开始时间">
                    <DatePicker type="date" v-model="formItem.addTime1" placeholder="请选择开始时间" style="width: 170px"></DatePicker>
                </FormItem>
                <FormItem label="分配结束时间">
                    <DatePicker type="date" v-model="formItem.addTime2" placeholder="请选择结束时间" style="width: 170px"></DatePicker>
                </FormItem>
                <FormItem label="建房状态">
                    <Select style="width:170px" v-model="formItem.status">
                        <Option 
                        v-for="item in buildlist" 
                        :value="item.id" 
                        :key="item.id"
                        >{{item.name}}</Option>
                    </Select>
                </FormItem>
                <FormItem label="订单开始时间">
                    <DatePicker type="date" v-model="formItem.sendTime1" placeholder="请选择开始时间" style="width: 170px"></DatePicker>
                </FormItem>
                <FormItem label="订单结束时间">
                    <DatePicker type="date" v-model="formItem.sendTime2" placeholder="请选择开始时间" style="width: 170px"></DatePicker>
                </FormItem>
                <FormItem label="销售人员">
                    <Input v-model="formItem.saleUserName" readonly style="width:170px">
                        <Button slot="append" icon="ios-search"   @click="operation.action='SALES',setcomms({webmodel:{state:true,path:'/userlist',query:{companyId:user.companyId,organizeId:user.organizeId}}})"></Button>
                    </Input>
                </FormItem>
                <FormItem label="销售团队" v-if="user.code=='BOSS' || user.code=='SUPERADMIN'">
                    <Select style="width:170px" v-model="formItem.organizeId">
                        <Option 
                        v-for="item in organizelist" 
                        :value="item.value" 
                        :key="item.value"
                        >{{item.label}}</Option>
                    </Select>
                </FormItem>
                <FormItem label="售后人员">
                    <Input v-model="formItem.owerName" readonly style="width:170px">
                        <Button slot="append" icon="ios-search"   @click="operation.action='AFTERSALES',setcomms({webmodel:{state:true,path:'/userlist',query:{companyId:user.companyId,organizeId:user.organizeId,filtercode:'AFTERSALES'}}})"></Button>
                    </Input>
                </FormItem>
                <!-- <FormItem label="是否跟进">
                    <Select style="width:180px" v-model="formItem.hasRecord">
                        <Option value="-1">不限</Option>
                        <Option value="1">有跟进</Option>
                        <Option value="0">无跟进</Option>
                    </Select>
                </FormItem> -->
                <FormItem label="图纸/项目编号">
                    <Input v-model="formItem.prodCode"  style="width:170px"></Input>
                </FormItem>
                <FormItem label="联系方式">
                    <Input v-model="formItem.customerPhone"  style="width:170px"></Input>
                </FormItem>
                <FormItem label="省份">
                    <Select style="width:170px" clearable filterable v-model="formItem.provinceId" @on-change="provinceChangeHandle">
                        <Option 
                        v-for="item in provincelist" 
                        :value="item.id" 
                        :key="item.id"
                        >{{item.name}}</Option>
                    </Select>
                </FormItem>
                <FormItem label="市区">
                    <Select style="width:170px" clearable filterable v-model="formItem.cityId">
                        <Option 
                        v-for="item in citylist" 
                        :value="item.id" 
                        :key="item.id"
                        >{{item.name}}</Option>
                    </Select>
                </FormItem>
            </Form>
        </div>
        <div slot="footer">
            <Button icon="md-close" @click="$emit('input',false)">关闭</Button>
            <Button type="primary" icon="md-checkmark" @click="submitSearchHandle">确定</Button>
        </div>
    </Modal>
</template>
<script>
    import util from '@/libs/util.js'
    // import {
    //     mapGetters,
    //     mapActions
    // } from 'vuex'
    export default {
        name:"TeamAfterTaskAdvanceFilter",
        data() {
            return {
                provincelist:[],
                citylist:[],
                buildlist:[],
                organizelist:[],
                formItem:{
                    addTime1:"",
                    addTime2:"",
                    sendTime1:"",
                    sendTime2:"",
                    status:"-2",
                    owerId:"",
                    owerUserName:"",
                    saleUserId:"",
                    saleUserName:"",
                    provinceId:0,
                    cityId:0,
                    organizeId:0,
                    prodCode:""
                },
                operation:{
                    action:"",
                    id:""
                }
            }
        },
        props:{
            modelValue:Array,
            clearData:Function
        },
        computed: {
            value:{
                get(){
                    return this.modelValue;
                },
                set(value){
                    this.$emit("update:modelValue",value);
                }
            }
            // ...mapGetters([
            //     'viewpath',
            //     'user',
            //     'upload',
            //     'comms',
            //     'imgTxt'
            // ]),

        },
        mounted() {
            
        },
        methods: {
            // ...mapActions([
            //     'getbuildstatus',
            //     'setcomms',
            //     'getarea',
            //     'getorganize'
            // ]),
            advanceSearchHandle(){
                this.advancestate = true;
                //加载身份
                if(this.provincelist.length==0){
                    this.getarea(0).then(d=>{
                        if(d.code==0){
                            this.provincelist = d.datas;
                        }
                    });
                }
                //商品套餐
                if(this.buildlist.length==0){
                    this.getbuildstatus().then(d=>{
                    if(d.code==0){
                            this.buildlist.push({
                                name:"不限",
                                id:"-2"
                            });
                            d.datas.forEach(e=>{
                                this.buildlist.push({
                                    name:e.name,
                                    id:e.id
                                })
                            });
                            this.buildlist.push({
                                name:"未完善",
                                id:"-1"
                            });
                        }
                    });
                }

                if(this.organizelist.length==0){
                    this.getorganize({id:5,state:0}).then(d=>{
                        if(d.code==0){
                            d.datas.forEach(d1=>{
                                this.organizelist.push({
                                    label:d1.title,
                                    value:d1.id
                                });
                            })
                        }
                    });
                }
            },
            submitSearchHandle(){
                let _filter = [];
                for(let key in this.formItem){
                    if(this.formItem[key]){
                        if(key=="owerId"){
                            _filter.push({
                                name:"OwerId",
                                condition:2,
                                value:this.formItem[key]
                            })
                        }
                        else if(key=="provinceId"){
                            _filter.push({
                                name:"ProvinceId",
                                condition:2,
                                value:this.formItem[key]
                            })
                        }
                        else if(key=="cityId"){
                            _filter.push({
                                name:"CityId",
                                condition:2,
                                value:this.formItem[key]
                            })
                        }
                        else if(key=="status" && this.formItem[key]!="-2"){
                            _filter.push({
                                name:"Status",
                                condition:2,
                                value:this.formItem[key]
                            })
                        }
                        else if(key=="organizeId" && this.formItem[key]>0){
                            _filter.push({
                                name:"OrganizeId",
                                condition:2,
                                value:this.formItem[key]
                            })
                        }
                        else if(key=="prodCode" && this.formItem[key]){
                            _filter.push({
                                name:"ProdCode",
                                condition:0,
                                value:this.formItem[key]
                            })
                        }
                        else if(key=="customerPhone" && this.formItem[key]){
                            _filter.push({
                                name:"CustomerPhone",
                                condition:0,
                                value:this.formItem[key]
                            })
                        }
                    }
                }


                //分配开始结束时间
                if(this.formItem.addTime1 && this.formItem.addTime2){
                    let _startTime = util.formatTime(this.formItem.addTime1);
                    let _endTime = util.formatTime(this.formItem.addTime2);
                    _filter.push({
                        name:"AddTime",
                        condition:8,
                        value:_startTime.split(" ")[0]+","+_endTime.split(" ")[0]
                    })
                }
                else if(this.formItem.addTime1 && !this.formItem.addTime2){
                    let _startTime = util.formatTime(this.formItem.addTime1);
                    _filter.push({
                        name:"AddTime",
                        condition:6,
                        value:_startTime.split(" ")[0]
                    })
                }
                else if(!this.formItem.addTime1 && this.formItem.addTime2){
                    let _endTime = util.formatTime(this.formItem.addTime2);
                    _filter.push({
                        name:"AddTime",
                        condition:7,
                        value:_endTime.split(" ")[0]
                    })
                }


                //订单开始结束时间
                if(this.formItem.sendTime1 && this.formItem.sendTime2){
                    let _startTime = util.formatTime(this.formItem.sendTime1);
                    let _endTime = util.formatTime(this.formItem.sendTime2);
                    _filter.push({
                        name:"SendTime",
                        condition:8,
                        value:_startTime.split(" ")[0]+","+_endTime.split(" ")[0]
                    })
                }
                else if(this.formItem.sendTime1 && !this.formItem.sendTime2){
                    let _startTime = util.formatTime(this.formItem.sendTime1);
                    _filter.push({
                        name:"SendTime",
                        condition:6,
                        value:_startTime.split(" ")[0]
                    })
                }
                else if(!this.formItem.sendTime1 && this.formItem.sendTime2){
                    let _endTime = util.formatTime(this.formItem.sendTime2);
                    _filter.push({
                        name:"SendTime",
                        condition:7,
                        value:_endTime.split(" ")[0]
                    })
                }
                this.$emit("on-change",_filter);
            },
            provinceChangeHandle(){
                this.getarea(this.formItem.provinceId).then(d=>{
                    if(d.code==0){
                        this.citylist = d.datas;
                    }
                });
            },
            clearAdvanceHandle(){
                for(let key in this.formItem){
                    if(typeof this.formItem[key] =="string"){
                        this.formItem[key]="";
                    }
                    else if(typeof this.formItem[key]=="number"){
                        this.formItem[key]=0;
                    }
                }
            },
            search(){
                this.gettaskbycontactinself(this.value4).then(d=>{
                    this.data2=[];
                    if(d.code==0){
                        this.data2 = d.datas;
                    }
                });
            },
            statsHandle(){
                this.$router.push({path:"/salereport",query:{id:"16"}});
            }
        },
        watch: {
            "value":function(){
                if(this.value){
                    this.advanceSearchHandle();
                }
            },
            "comms.webmodel":function(){
                if(!this.comms.webmodel.state && this.comms.webmodel.key=="refresh"){
                    this.$set(this.ttcallback, "refresh", true);
                }
                else if(!this.comms.webmodel.state && this.comms.webmodel.key=="userlist" && this.comms.webmodel.action=="result"){
                    if(this.operation.action=="SALES"){
                        this.formItem.saleUserId = this.comms.webmodel.result.id;
                        this.formItem.saleUserName = this.comms.webmodel.result.userName;
                    }
                    else{
                        this.formItem.owerId = this.comms.webmodel.result.id;
                        this.formItem.owerName = this.comms.webmodel.result.userName;
                    }
                }
            },
            "clearData":function(){
                this.clearAdvanceHandle();
            }
        }
    }
</script>