
<template>
    <Modal v-model="value" width="830" :styles="{'top':'10px'}" :mask-closable="false" :closable="false">
        <p slot="header">
            <span>高级搜索</span>
        </p>
        <div style="padding:10px 0px;">
            <Form :model="formItem" ref="formItem" :label-width="80" inline>
                <FormItem label="开始时间">
                    <DatePicker type="date" v-model="formItem.startTime" placeholder="请选择开始时间" style="width: 180px"></DatePicker>
                </FormItem>
                <FormItem label="结束时间">
                    <DatePicker type="date" v-model="formItem.endTime" placeholder="请选择结束时间" style="width: 180px"></DatePicker>
                </FormItem>
                <FormItem label="客资状态">
                    <Select style="width:180px" v-model="formItem.state">
                        <Option 
                        v-for="item in defaulttabs" 
                        :value="item.id" 
                        :key="item.id"
                        >{{item.name}}</Option>
                    </Select>
                </FormItem>
                <FormItem label="建房楼层">
                    <Input v-model="formItem.floor"  style="width:180px"></Input>
                </FormItem>
                <FormItem label="建房面积">
                    <Input v-model="formItem.acreage"  style="width:180px"></Input>
                </FormItem>
                <FormItem label="创建人员" v-if="(user.code=='BOSS' || user.code=='SUPERADMIN' || (user.code=='AFTERSALES' && user.groupLeader=='Y'))">
                    <Input v-model="formItem.userName" readonly style="width:180px">
                        <Button slot="append" icon="ios-search"   @click="operation.action='53SERVICES',setcomms({webmodel:{state:true,path:'/userlist',query:{companyId:user.companyId}}})"></Button>
                    </Input>
                </FormItem>
                <FormItem label="完成率">
                    <Input v-model="formItem.completeRate"  style="width:180px"></Input>
                    <!-- <Input v-model="formItem.prodCode"  style="width:180px">
                        <Button slot="append" icon="ios-search"   @click="setcomms({webmodel:{state:true,path:'/product'}})"></Button>
                    </Input> -->
                </FormItem>
                <FormItem label="省份">
                    <Select style="width:180px" clearable filterable v-model="formItem.provinceId" @on-change="provinceChangeHandle">
                        <Option 
                        v-for="item in provincelist" 
                        :value="item.id" 
                        :key="item.id"
                        >{{item.name}}</Option>
                    </Select>
                </FormItem>
                <FormItem label="市区">
                    <Select style="width:180px" clearable filterable v-model="formItem.cityId">
                        <Option 
                        v-for="item in citylist" 
                        :value="item.id" 
                        :key="item.id"
                        >{{item.name}}</Option>
                    </Select>
                </FormItem>
                <FormItem label="联系方式">
                    <Input v-model="formItem.customerPhone"  style="width:180px"></Input>
                </FormItem>
                <FormItem label="客户来源">
                    <Select style="width:180px" clearable filterable v-model="formItem.source">
                        <Option 
                        v-for="item in sourcelist" 
                        :value="item.sourceId" 
                        :key="item.sourceId"
                        >{{item.name}}</Option>
                    </Select>
                </FormItem>
                <FormItem label="销售人员">
                    <Input v-model="formItem.saleUserName" readonly style="width:180px">
                        <Button slot="append" icon="ios-search"   @click="operation.action='SALES',setcomms({webmodel:{state:true,path:'/userlist',query:{companyId:user.companyId}}})"></Button>
                    </Input>
                </FormItem>
                <FormItem label="是否跟进">
                    <Select style="width:180px" clearable filterable v-model="formItem.visitRemark">
                        <Option 
                        v-for="item in visitlist" 
                        :value="item.id" 
                        :key="item.id"
                        >{{item.name}}</Option>
                    </Select>
                </FormItem>
            </Form>
        </div>
        <div slot="footer">
            <Button icon="md-close" @click="$emit('input',false)">关闭</Button>
            <Button type="primary" icon="md-checkmark" @click="submitSearchHandle">确定</Button>
        </div>
    </Modal>
</template>
<script>
    import util from '@/libs/util.js'
    // import {
    //     mapGetters,
    //     mapActions
    // } from 'vuex'
    export default {
        name:"OrderAdvanceFilter",
        data() {
            return {
                provincelist:[],
                citylist:[],
                sourcelist:[],
                paywaylist:[],
                combolist:[],
                visitlist:[{
                    id:"-1",
                    name:"全部"
                },{
                    id:"0",
                    name:"有跟进"
                },{
                    id:"1",
                    name:"无跟进"
                }],
                formItem:{
                    startTime:"",
                    endTime:"",
                    state:"0",
                    userId:"",
                    userName:"",
                    prodCode:"",
                    provinceId:0,
                    cityId:0,
                    completeRate:"",
                    acreage:"",
                    visitRemark:"-1",
                    source:"",
                    customerPhone:"",
                    saleUserId:"",
                    saleUserName:""
                },
                operation:{
                    action:"",
                    id:""
                }
            }
        },
        props:{
            modelValue:Array,
            clearData:Function
        },
        computed: {
            value:{
                get(){
                    return this.modelValue;
                },
                set(value){
                    this.$emit("update:modelValue",value);
                }
            }
            // ...mapGetters([
            //     'viewpath',
            //     'user',
            //     'upload',
            //     'comms',
            //     'imgTxt'
            // ]),

        },
        mounted() {
        },
        methods: {
            // ...mapActions([
            //     'setcomms',
            //     'getwecode',
            //     'getarea',
            //     'getsource'
            // ]),
            advanceSearchHandle(){
                this.advancestate = true;
                //加载身份
                if(this.provincelist.length==0){
                    this.getarea(0).then(d=>{
                        if(d.code==0){
                            this.provincelist = d.datas;
                        }
                    });
                }
                //客户来源
                if(this.sourcelist.length==0){
                    this.getsource({page:1,row:50,code:"other"}).then(d=>{
                        if(d.code==0){
                            this.sourcelist = d.datas;
                        }
                    });
                }
                //支付方式
                // if(this.paywaylist.length==0){
                //     this.getpayway({page:1,row:50,state:0,flag:0}).then(d=>{
                //         if(d.code==0){
                //             this.paywaylist = d.datas;
                //         }
                //     });
                // }
            },
            submitSearchHandle(){
                let _filter = [];
                for(let key in this.formItem){
                    if(this.formItem[key]){
                        if(key=="userId"){ // && this.formItem[key]!=this.user.id
                            _filter.push({
                                name:"userId",
                                condition:2,
                                value:this.formItem[key],
                                force:true
                            })
                        }
                        else if(key=="provinceId"){
                            _filter.push({
                                name:"provinceId",
                                condition:2,
                                value:this.formItem[key]
                            })
                        }
                        else if(key=="cityId"){
                            _filter.push({
                                name:"cityId",
                                condition:2,
                                value:this.formItem[key]
                            })
                        }
                        else if(key=="state" && this.formItem[key]!="-1"){
                            _filter.push({
                                name:"state",
                                condition:2,
                                value:this.formItem[key]
                            })
                        }
                        else if(key=="floor"){
                            _filter.push({
                                name:"floor",
                                condition:2,
                                value:this.formItem[key]
                            })
                        }
                        else if(key=="saleUserId"){
                            _filter.push({
                                name:"SaleUserId",
                                condition:2,
                                value:this.formItem[key]
                            })
                        }
                        else if(key=="source"){
                            _filter.push({
                                name:"Source",
                                condition:2,
                                value:this.formItem[key]
                            })
                        }
                        else if(key=="customerPhone"){
                            _filter.push({
                                name:"CustomerPhone",
                                condition:2,
                                value:this.formItem[key]
                            })
                        }
                        else if(key=="visitRemark" && this.formItem[key]!="-1"){
                            if(this.formItem[key]==0){
                                _filter.push({
                                    name:"VisitRemark",
                                    condition:5
                                });
                            }
                            else if(this.formItem[key]==1){
                                _filter.push({
                                    name:"VisitRemark",
                                    condition:6
                                });
                            }
                        }
                    }
                }

                if(this.formItem.acreage){
                    let _acreage = this.formItem.acreage.split("-");
                    if(_acreage.length==1){
                        _filter.push({
                            name:"Acreage",
                            condition:6,
                            value:_acreage[0]
                        })
                    }
                    else if(_acreage.length==2){
                        _filter.push({
                            name:"Acreage",
                            condition:8,
                            value:_acreage[0]+","+_acreage[1]
                        })
                    }
                }


                //开始结束时间
                if(this.formItem.startTime && this.formItem.endTime){
                    let _startTime = util.formatTime(this.formItem.startTime);
                    let _endTime = util.formatTime(this.formItem.endTime);
                    _filter.push({
                        name:"AddTime",
                        condition:8,
                        value:_startTime.split(" ")[0]+","+_endTime.split(" ")[0]
                    })
                }
                else if(this.formItem.startTime && !this.formItem.endTime){
                    let _startTime = util.formatTime(this.formItem.startTime);
                    _filter.push({
                        name:"AddTime",
                        condition:6,
                        value:_startTime.split(" ")[0]
                    })
                }
                else if(!this.formItem.startTime && this.formItem.endTime){
                    let _endTime = util.formatTime(this.formItem.endTime);
                    _filter.push({
                        name:"AddTime",
                        condition:7,
                        value:_endTime.split(" ")[0]
                    })
                }
                this.$emit("on-change",_filter);
            },
            provinceChangeHandle(){
                this.getarea(this.formItem.provinceId).then(d=>{
                    if(d.code==0){
                        this.citylist = d.datas;
                    }
                });
            },
            clearAdvanceHandle(){
                for(let key in this.formItem){
                    
                    if(key=="visitRemark"){
                        this.formItem[key]="-1";
                    }
                    else if(key=="state"){
                        this.formItem[key]="0";
                    }
                    else{
                        if(typeof this.formItem[key] =="string"){
                            this.formItem[key]="";
                        }
                        else if(typeof this.formItem[key]=="number"){
                            this.formItem[key]=0;
                        }
                    }
                }
            },
            search(){
                this.gettaskbycontactinself(this.value4).then(d=>{
                    this.data2=[];
                    if(d.code==0){
                        this.data2 = d.datas;
                    }
                });
            },
            statsHandle(){
                this.$router.push({path:"/salereport",query:{id:"16"}});
            }
        },
        watch: {
            "value":function(){
                if(this.value){
                    this.advanceSearchHandle();
                }
            },
            "comms.webmodel":function(){
                if(!this.comms.webmodel.state && this.comms.webmodel.key=="refresh"){
                    this.$set(this.ttcallback, "refresh", true);
                }
                else if(!this.comms.webmodel.state && this.comms.webmodel.key=="userlist" && this.comms.webmodel.action=="result"){
                    if(this.operation.action=="53SERVICES"){
                        this.formItem.userId = this.comms.webmodel.result.id;
                        this.formItem.userName = this.comms.webmodel.result.userName;
                    }
                    else{
                        this.formItem.saleUserId = this.comms.webmodel.result.id;
                        this.formItem.saleUserName = this.comms.webmodel.result.userName;
                    }
                }
            },
            "clearData":function(){
                this.clearAdvanceHandle();
            }
        }
    }
</script>