import { defineStore } from 'pinia';
// import {useUserStore} from '@/store/user/user';
import api from '@/service/http';
// import configs from '@/service/configs';

export const useBaseInfoStore = defineStore('baseinfo', {
  state: () => {
    return {
      customerkindlist: [],
      sourcelist: [],
      paywaylist: [],
      payaccountlist: [],
      arealist: { province: [], city: [], area: [] },
      dictbanklist: [],
      expresslist: [],
      designkindlist: []
    }
  },
  getters: {
    getCustomerKind(state) {
      return function (data, cache = true) {
        if (cache) {
          if (state.customerkindlist.length > 0) {
            return new Promise((resolve) => {
              resolve(state.customerkindlist)
            });
          }
        }
        return state.getcustomerkind(data);
      }
    },
    getSourceList(state) {
      return function (data, cache = true) {
        if (cache) {
          if (state.sourcelist.length > 0) {
            return new Promise((resolve) => {
              resolve(state.sourcelist)
            });
          }
        }
        return state.getsource(data);
      }
    },
    getPayWayList(state) {
      return function (data, cache = true) {
        if (cache) {
          if (state.paywaylist.length > 0) {
            return new Promise((resolve) => {
              resolve(state.paywaylist)
            });
          }
        }
        return state.getpayway(data);
      }
    },
    getPayAccountList(state) {
      return function (data, cache = true) {
        if (cache) {
          if (state.payaccountlist.length > 0) {
            return new Promise((resolve) => {
              resolve(state.payaccountlist)
            });
          }
        }
        return state.getpayaccount(data);
      }
    },
    getAreaList(state) {
      return function (data, cache = true) {
        if (cache) {
          if (state.arealist.province.length > 0) {
            return new Promise((resolve) => {
              resolve(state.arealist)
            });
          }
        }
        return state.getarealinkage(data);
      }
    },
    getDictBank(state) {
      return function (data, cache = true) {
        if (cache) {
          if (state.dictbanklist.length > 0) {
            return new Promise((resolve) => {
              resolve(state.dictbanklist)
            });
          }
        }
        return state.getdictbank(data);
      }
    },
    getExpressList(state) {
      return function (data, cache = true) {
        if (cache) {
          if (state.expresslist.length > 0) {
            return new Promise((resolve) => {
              resolve(state.expresslist)
            });
          }
        }
        return state.getexpress(data);
      }
    },

  },
  actions: {

    async getpayaccount(data, callback) {
      return await api.get("/payaccount", data, callback).then(({ d }) => {
        this.payaccountlist = d;
        return d;
      });
    },
    async getarealinkage(data, callback) {
      return await api.get("/area/linkage/" + data.provinceId + "/" + data.cityId, data, callback, ["provinceId", "cityId"]).then(({ d }) => {
        if (!data.kind || data.kind == 0) {
          this.arealist = d;
        }
        return d;
      });
    },
    async editorderphase(data, callback) {
      return await api.get("/order/phase/" + (data.id || '0') + '/' + data.price, data.datas, data, callback).then(d => {
        return d;
      });
    },
    async getexpress(data, callback) {
      return await api.get("/express", data, callback).then(({ d }) => {
        this.expresslist = d;
        return d;
      });
    },
    async addexpress(data, callback) {
      return await api.post('/express', data, callback).then(({ res }) => {
        return res;
      });
    },
    async editexpress(data, callback) {
      return await api.put('/express', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getexpressdetail(data, callback) {
      return await api.get('/express/' + data, "", callback).then((d) => {
        return d;
      });
    },
    async getexpressbyorderid(data, callback) {
      return await api.get('/express/byorderid/' + data, "", callback).then((d) => {
        return d;
      });
    },
    async getexpresstrace(data, callback) {
      return await api.get('/express/trace/' + data.code + "/" + data.expressNum + "/" + data.type + "?customerName=" + data.customerName, "", callback).then((d) => {
        return d;
      });
    },
    async delexpress(data, callback) {
      return await api.post('/express/delete', data, callback).then(({ res }) => {
        return res;
      });
    },
    async updateexpressstate(data, callback) {
      return await api.put('/express/UpdateState', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getexpressprice(data, callback) {
      return await api.get('/express/price', data, callback).then((d) => {
        return d;
      });
    },
    async getexpresspricebyareaid(data, callback) {
      return await api.get('/express/pricebyareaid', data, callback).then((d) => {
        return d;
      });
    },
    async editexpressprice(data, callback) {
      return await api.put('/express/updateprice', data, callback).then(({ res }) => {
        return res;
      });
    },
    //============推送规则=============
    async getsource(data, callback) {
      return await api.get("/source", data, callback).then(({ d }) => {
        this.sourcelist = d;
        return d;
      });
    },
    async bindsource(data, callback) {
      return await api.post('/source/bindcode', data, callback).then(({ res }) => {
        return res;
      });
    },
    async setdefaultsource(data, callback) {
      return await api.put('/source/updatesourcedefaultstate', data, callback).then(({ res }) => {
        return res;
      });
    },
    async addsource(data, callback) {
      return await api.post('/source', data, callback).then(({ res }) => {
        return res;
      });
    },
    async editsource(data, callback) {
      return await api.put('/source', data, callback).then(({ res }) => {
        return res
      });
    },
    async getsourcedetail(data, callback) {
      return await api.get('/source/' + data, callback).then((d) => {
        return d;
      });
    },
    async delsource(data, callback) {
      return await api.post('/source/delete', data, callback).then(({ res }) => {
        return res;
      });
    },
    async delbindsource(data, callback) {
      return await api.post('/source/deletebind', data, callback).then(({ res }) => {
        return res;
      });
    },
    async updatesourcestate(data, callback) {
      return await api.put('/source/UpdateState', data, callback).then(({ res }) => {
        return res;
      });
    },
    //============推送规则=============
    async getsendrule(data, callback) {
      return await api.get('/sendrule', data, callback).then((d) => {
        return d;
      });
    },
    async addsendrule(data, callback) {
      return await api.post('/sendrule', data, callback).then(({ res }) => {
        return res;
      });
    },
    async editsendrule(data, callback) {
      return await api.put('/sendrule', data, callback).then(({ res }) => {
        return res;
      });
    },
    async updatesendrulestate(data, callback) {
      return await api.put('/sendrule/updatestate', data, callback).then(({ res }) => {
        return res;
      });
    },
    async updatesendrulenum(data, callback) {
      return await api.put('/sendrule/updatenum/' + data.id + "/" + data.num, "", callback).then(({ res }) => {
        return res;
      });
    },
    async delsendrule(data, callback) {
      return await api.post('/sendrule/delete', data, callback).then(({ res }) => {
        return res;
      });
    },
    async refreshsenduser(data, callback) {
      return await api.get('/sendrule/refreshuser/' + data.id + "/" + data.coderl, "", callback).then((d) => {
        return d;
      });
    },
    //===========咨询推送规则==========
    async getconsultsendrule(data, callback) {
      return await api.get('/consultsendrule/' + data.page + "/" + data.row + "/" + data.relatedId, data, callback, ["page", "row", "relatedId"]).then((d) => {
        return d;
      });
    },
    async getconsultsendruledetail(data, callback) {
      return await api.get('/consultsendrule/' + data.id + "/" + data.userId, data, callback).then((d) => {
        return d;
      });
    },
    async addconsultsendrule(data, callback) {
      return await api.post('/consultsendrule', data, callback).then(({ res }) => {
        return res;
      });
    },
    async editconsultsendrule(data, callback) {
      return await api.put('/consultsendrule', data, callback).then(({ res }) => {
        return res;
      });
    },
    async generateconsultsendresult(data, callback) {
      return await api.get('/consultsendrule/generate/' + data.code, "", callback).then((d) => {
        return d;
      });
    },
    async updateconsultsendrulestate(data, callback) {
      return await api.put('/consultsendrule/updatestate', data, callback).then(({ res }) => {
        return res;
      });
    },
    async setresetsingle(data, callback) {
      return await api.get('/consultsendrule/ResetSingle/' + data.userId + '/' + data.relatedId, "", callback).then((d) => {
        return d;
      });
    },
    //===========银行列表===========
    async getdictbank(data, callback) {
      return await api.get('/dictbank', data, callback).then((d) => {
        return d;
      });
    },
    async adddictbank(data, callback) {
      return await api.post('/dictbank', data, callback).then(({ res }) => {
        return res;
      });
    },
    async editdictbank(data, callback) {
      return await api.put('/dictbank', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getdictbankdetail(data, callback) {
      return await api.get('/dictbank/' + data.id, '', callback).then((d) => {
        return d;
      });
    },
    async deldictbank(data, callback) {
      return await api.post('/dictbank/delete', data, callback).then(({ res }) => {
        return res;
      });
    },
    async updatedictbankstate(data, callback) {
      return await api.put('/dictbank/updatestate', data, callback).then(({ res }) => {
        return res;
      });
    },
    //建房状态
    async getbuildstatus(data, callback) {
      return await api.get('/buildstatus', data, callback).then((d) => {
        return d;
      });
    },
    async addbuildstatus(data, callback) {
      return await api.post('/buildstatus', data, callback).then(({ res }) => {
        return res;
      });
    },
    async editbuildstatus(data, callback) {
      return await api.put('/buildstatus', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getbuildstatusdetail(data, callback) {
      return await api.get('/buildstatus/' + data, '', callback).then((d) => {
        return d;
      });
    },
    async delbuildstatus(data, callback) {
      return await api.post('/buildstatus/delete', data, callback).then(({ res }) => {
        return res;
      });
    },
    async updatebuildstatusstate(data, callback) {
      return await api.put('/buildstatus/updatestate', data, callback).then(({ res }) => {
        return res;
      });
    },
    //工程类型
    async getconstructionkind(data, callback) {
      return await api.get('/constructionkind/' + data.page + "/" + data.row, data, callback, ["page", "row"]).then((d) => {
        return d;
      });
    },
    async addconstructionkind(data, callback) {
      return await api.post('/constructionkind', data, callback).then(({ res }) => {
        return res;
      });
    },
    async editconstructionkind(data, callback) {
      return await api.put('/constructionkind', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getconstructionkinddetail(data, callback) {
      return await api.get('/constructionkind/detail/' + data.id, '', callback).then((d) => {
        return d;
      });
    },
    async delconstructionkind(data, callback) {
      return await api.post('/constructionkind/delete', data, callback).then(({ res }) => {
        return res;
      });
    },
    async updateconstructionkindstate(data, callback) {
      return await api.put('/constructionkind/updatestate', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getconstructioncompany(data, callback) {
      return await api.get('/constructioncompany/' + data.page + "/" + data.row, data, callback, ["page", "row"]).then((d) => {
        return d;
      });
    },
    async addconstructioncompany(data, callback) {
      return await api.post('/constructioncompany', data, callback).then(({ res }) => {
        return res;
      });
    },
    async editconstructioncompany(data, callback) {
      return await api.put('/constructioncompany', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getconstructioncompanydetail(data, callback) {
      return await api.get('/constructioncompany/detail/' + data.id, '', callback).then((d) => {
        return d;
      });
    },
    async updateconstructioncompanystate(data, callback) {
      return await api.put('/constructioncompany/updatestate', data, callback).then(({ res }) => {
        return res;
      });
    },
    //===========无效原因===========
    async getunvalidreason(data, callback) {
      return await api.get('/unvalidreason', data, callback).then((d) => {
        return d;
      });
    },
    async addunvalidreason(data, callback) {
      return await api.post('/unvalidreason', data, callback).then(({ res }) => {
        return res;
      });
    },
    async editunvalidreason(data, callback) {
      return await api.put('/unvalidreason', data, callback).then(({ res }) => {
        return res;
      });
    },
    async delunvalidreason(data, callback) {
      return await api.post('/unvalidreason/delete', data, callback).then(({ res }) => {
        return res;
      });
    },
    async updateunvalidreasonstate(data, callback) {
      return await api.put('/unvalidreason/updatestate', data, callback).then(({ res }) => {
        return res;
      });
    },
    //===========支付方式
    async getpayway(data, callback) {
      return await api.get("/payway", data, callback).then(({ d,res }) => {
        this.paywaylist = d;
        return res;
      });
    },
    async addpayway(data, callback) {
      return await api.post('/payway', data, callback).then(({ res }) => {
        return res;
      });
    },
    async editpayway(data, callback) {
      return await api.put('/payway', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getpaywaydetail(data, callback) {
      return await api.get('/payway/' + data.id + "/" + (data.price || 0), "", callback).then(({ res }) => {
        return res;
      });
    },
    async getpaywaydetailbydesignkindid(data, callback) {
      return await api.get('/payway/bydesignkindid/' + data.id + "/" + (data.price || 0) + "?taskId=" + data.taskId, "", callback).then((d) => {
        return d;
      });
    },
    async delpayway(data, callback) {
      return await api.post('/payway/delete', data, callback).then(({ res }) => {
        return res;
      });
    },
    async updatepaywaystate(data, callback) {
      return await api.put('/payway/UpdateState', data, callback).then(({ res }) => {
        return res;
      });
    },
    //===========设计分类===========
    async getdesigncategory(data, callback) {
      return await api.get('/designcategory', data, callback).then((d) => {
        return d;
      });
    },
    async adddesigncategory(data, callback) {
      return await api.post('/designcategory', data, callback).then(({ res }) => {
        return res;
      });
    },
    async editdesigncategory(data, callback) {
      return await api.put('/designcategory', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getdesigncategorydetail(data, callback) {
      return await api.get('/designcategory/' + data, "", callback).then((d) => {
        return d;
      });
    },
    async deldesigncategory(data, callback) {
      return await api.post('/designcategory/delete', data, callback).then(({ res }) => {
        return res;
      });
    },
    async updatedesigncategorystate(data, callback) {
      return await api.put('/designcategory/UpdateState', data, callback).then(({ res }) => {
        return res;
      });
    },

    //===============获取项目模板
    async getprojecttemplate(data, callback) {
      return await api.get('/projecttemplate', data, callback).then((d) => {
        return d;
      });
    },
    async addprojecttemplate(data, callback) {
      return await api.post('/projecttemplate', data, callback).then(({ res }) => {
        return res;
      });
    },
    async editprojecttemplate(data, callback) {
      return await api.put('/projecttemplate', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getprojecttemplatedetail(data, callback) {
      return await api.get('/projecttemplate/' + data, "", callback).then((d) => {
        return d;
      });
    },
    async delprojecttemplate(data, callback) {
      return await api.post('/projecttemplate/delete', data, callback).then(({ res }) => {
        return res;
      });
    },
    async updateprojecttemplatestate(data, callback) {
      return await api.put('/projecttemplate/UpdateState', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getprojectphasedesignuser(data, callback) {
      return await api.get('/projecttemplate/phasedesign/' + data, "", callback).then((d) => {
        return d;
      });
    },
    async getdesignuserbytaskid(data, callback) {
      return await api.get('/projecttemplate/taskphasedesign/' + data, "", callback).then((d) => {
        return d;
      });
    },
    //获取项目模板任务
    async getprojecttemplatetask(data, callback) {
      return await api.get('/projecttemplate/task/' + data, "", callback).then((d) => {
        return d;
      });
    },
    async addprojecttemplatetask(data, callback) {
      return await api.post('/projecttemplate/task', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getprojecttemplatetaskbyid(data, callback) {
      return await api.get('/projecttemplate/task/' + data, "", callback).then((d) => {
        return d;
      });
    },
    async getprojecttemplatephase(data, callback) {
      return await api.get('/projecttemplate/phase/' + data, "", callback).then((d) => {
        return d;
      });
    },
    async addprojecttemplatephase(data, callback) {
      return await api.post('/projecttemplate/phase', data, callback).then(({ res }) => {
        return res;
      });
    },
    async addprojectfromtemplate(data, callback) {
      return await api.post('/project/addfromtemplate', data, callback).then(({ res }) => {
        return res;
      });
    },
    async addprojectfromtemplatebysale(data, callback) {
      return await api.post('/project/addfromtemplatebysale', data, callback).then(({ res }) => {
        return res;
      });
    },
    //===========客户分类===========
    async getcustomerkind(data, callback) {
      return await api.get("/customerkind", data, callback).then(({ d }) => {
        this.customerkindlist = d;
        return d;
      });
    },
    async addcustomerkind(data, callback) {
      return await api.post('/customerkind', data, callback).then(({ res }) => {
        return res;
      });
    },
    async editcustomerkind(data, callback) {
      return await api.put('/customerkind', data, callback).then(({ res }) => {
        return res;
      });
    },
    async getcustomerkinddetail(data, callback) {
      return await api.get('/customerkind/' + data, "", callback).then((d) => {
        return d;
      });
    },
    async delcustomerkind(data, callback) {
      return await api.post('/customerkind/delete', data, callback).then(({ res }) => {
        return res;
      });
    },
    async updatecustomerkindstate(data, callback) {
      return await api.put('/customerkind/UpdateState', data, callback).then(({ res }) => {
        return res;
      });
    },
    async setcustomerkinddefault(data, callback) {
      return await api.post('/customerkind/setdefault', data, callback).then(({ res }) => {
        return res;
      });
    },
    async changecustomerkind(data, callback) {
      return await api.post('/customer/changekind', data, callback).then(({ res }) => {
        return res;
      });
    }
  }
})