<template>
    <div>
        <Select v-model="provinceId" :style="{ width: long ? '100%' : (small ? '90px' : '133px') }" filterable
            @on-change="selectProvince" label-in-value :disabled="view">
            <Option v-for="item in theprovince" :value="item.id" :key="item.id">{{ item.name }}</Option>
        </Select>
        <Select v-model="cityId" :style="{ width: long ? '100%' : (small ? '100px' : '123px') }" filterable
            @on-change="selectCity" label-in-value :disabled="view">
            <Option v-for="item in thecity" :value="item.id" :key="item.id">{{ item.name }}</Option>
        </Select>
        <Select v-model="areaId" :style="{ width: long ? '100%' : (small ? '100px' : '123px') }" filterable
            @on-change="selectArea" label-in-value :disabled="view">
            <Option v-for="item in thearea" :value="item.id" :key="item.id">{{ item.name }}</Option>
        </Select>
    </div>
</template>

<script>
import { useBaseInfoStore } from "@/store/crm/baseinfo";

export default {
    name: 'FormArea',
    setup() {
        const baseinfostore = useBaseInfoStore();
        return {
            baseinfostore
        }
    },

    props: {
        view: {
            default: false
        },
        init: {
            type: Object,
            default: function () {
                return {
                    province: "",
                    provinceId: 0,
                    city: "",
                    cityId: 0,
                    area: "",
                    areaId: 0
                }
            }
        },
        long: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            theprovince: [],
            thecity: [],
            thearea: [],
            provinceId: 0,
            cityId: 0,
            areaId: 0,
            areas: {
                province: "",
                provinceId: "",
                city: "",
                cityId: "",
                area: "",
                areaId: ""
            },
            operation: {
                action: ""
            }
        }
    },
    mounted() {
        this.loadData();
    },
    methods: {
        loadData() {
            var _init = JSON.parse(JSON.stringify(this.init));
            var _provincetemp = parseInt(_init.provinceId || 0);
            var _citytemp = parseInt(_init.cityId || 0);
            this.baseinfostore.getAreaList({ provinceId: _provincetemp, cityId: _citytemp }).then(d => {
                this.theprovince = d.province;
                this.thecity = d.city;
                this.thearea = d.area;
            });
        },
        selectProvince(val) {
            if (!val) { return false; }
            if (val.label) {
                this.baseinfostore.getAreaList({ provinceId: this.provinceId, cityId: this.cityId }, false);
                this.areas.provinceId = val.value;
                this.areas.province = val.label;
            }

        },
        selectCity(val) {
            if (!val) { return false; }
            if (val.label) {
                this.areas.cityId = val.value;
                this.areas.city = val.label;
                this.baseinfostore.getAreaList({ provinceId: this.provinceId, cityId: this.cityId }, false);
            }
        },
        selectArea(val) {
            if (!val) { return false; }
            if (val.label) {
                this.areas.areaId = val.value;
                this.areas.area = val.label;
            }
            setTimeout(() => {
                this.$emit("input", this.areas);
            }, 100);
        }
    },
    watch: {
        init: {
            deep: true,
            handler: function () {
                this.loadData();
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>