
<template>
    <Modal v-model="value" class="bindcustomer" width="830" :styles="{'top':'10px'}" :mask-closable="false" :closable="false">
            <p slot="header">
                <span>高级搜索</span>
            </p>
            <div style="padding:10px 0px;">
                <Form :model="formItem" ref="formItem" :label-width="80" inline>
                    <FormItem label="开始时间">
                        <DatePicker type="date" v-model="formItem.startTime" placeholder="请选择开始时间" style="width: 180px"></DatePicker>
                    </FormItem>
                    <FormItem label="结束时间">
                        <DatePicker type="date" v-model="formItem.endTime" placeholder="请选择结束时间" style="width: 180px"></DatePicker>
                    </FormItem>
                    <FormItem label="设计套餐">
                        <Select style="width:180px" clearable filterable v-model="formItem.templateId">
                            <Option 
                            v-for="item in templatelist" 
                            :value="item.id" 
                            :key="item.id"
                            >{{item.name}}</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="买家姓名">
                        <Input v-model="formItem.customerName"  style="width:180px"></Input>
                    </FormItem>
                    <FormItem label="买家电话">
                        <Input v-model="formItem.customerPhone"  style="width:180px"></Input>
                    </FormItem>
                    <FormItem label="销售姓名">
                        <Input v-model="formItem.saleUserName" readonly style="width:180px">
                            <Button slot="append" icon="ios-search"   @click="setcomms({webmodel:{state:true,path:'/userlist',query:{companyId:user.companyId}}})"></Button>
                        </Input>
                    </FormItem>
                    <FormItem label="项目进度">
                        <InputNumber v-model="formItem.startProcess" style="width:83px"></InputNumber>
                        &nbsp;-&nbsp;
                        <InputNumber v-model="formItem.endProcess" style="width:80px"></InputNumber>
                    </FormItem>
                    <FormItem label="项目面积">
                        <InputNumber v-model="formItem.startAcreage" style="width:82px"></InputNumber>
                        &nbsp;-&nbsp;
                        <InputNumber v-model="formItem.endAcreage" style="width:82px"></InputNumber>
                    </FormItem>
                    <FormItem label="是否变更">
                        <Select style="width:180px" clearable filterable v-model="formItem.changeNum">
                            <Option 
                            v-for="item in projectchangeoption" 
                            :value="item.id" 
                            :key="'projectchangeoption'+item.id"
                            >{{item.name}}</Option>
                        </Select>
                    </FormItem>
                </Form>
            </div>
            <div slot="footer">
                <Button icon="md-close" @click="$emit('input',false)">关闭</Button>
                <Button type="primary" icon="md-checkmark" @click="submitSearchHandle">确定</Button>
            </div>
        </Modal>
</template>
<script>
    import util from '@/libs/util.js'
    import dict from '@/libs/dict.js'
    // import {
    //     mapGetters,
    //     mapActions
    // } from 'vuex'
    export default {
        name:"TeamTaskAdvanceFilter",
        data() {
            return {
                provincelist:[],
                citylist:[],
                sourcelist:[],
                paywaylist:[],
                combolist:[],
                organizelist:[],
                formItem:{
                    startTime:"",
                    endTime:"",
                    templateId:"",
                    customerName:"",
                    customerPhone:"",
                    saleUserId:"",
                    saleUserName:"",
                    startProcess:0,
                    endProcess:0,
                    startAcreage:0,
                    endAcreage:0,
                    changeNum:-1
                },
                templatelist:[],
                filter:[],
                projectchangeoption:[{
                    id:-1,
                    name:"全部"
                },{
                    id:0,
                    name:"无变更"
                },{
                    id:1,
                    name:"有变更"
                }],
                taskstatelist:dict.taskstate()
            }
        },
        props:{
            modelValue:Array,
            clearData:Function
        },
        computed: {
            value:{
                get(){
                    return this.modelValue;
                },
                set(value){
                    this.$emit("update:modelValue",value);
                }
            }
            // ...mapGetters([
            //     'viewpath',
            //     'user',
            //     'upload',
            //     'comms',
            //     'imgTxt'
            // ]),

        },
        mounted() {
        },
        methods: {
            // ...mapActions([
            //     "getprojecttemplate",
            //     "setcomms"
            // ]),
            advanceSearchHandle(){
                this.advancestate = true;
                if(this.templatelist.length==0){
                     this.getprojecttemplate({page:1,row:100,state:-1}).then(d=>{
                        this.templatelist = [];
                        if(d.code==0){
                            this.templatelist  = d.datas;
                        }
                    });
                }
            },
            submitSearchHandle(){
                let _filter = [];
                for(let key in this.formItem){
                    if(this.formItem[key]){
                        if(key=="customerName"){
                            _filter.push({
                                name:"customerName",
                                condition:2,
                                value:this.formItem[key]
                            })
                        }
                        else if(key=="customerPhone"){
                            _filter.push({
                                name:"customerPhone",
                                condition:0,
                                value:this.formItem[key]
                            })
                        }
                        else if(key=="saleUserId" && this.formItem[key]!=this.user.id){
                            _filter.push({
                                name:"saleUserId",
                                condition:2,
                                value:this.formItem[key]
                            })
                        }
                        else if(key=="templateId"){
                            _filter.push({
                                name:"templateId",
                                condition:2,
                                value:this.formItem[key]
                            })
                        }
                    }
                }
                //开始结束时间
                if(this.formItem.startTime && this.formItem.endTime){
                    let _startTime = util.formatTime(this.formItem.startTime);
                    let _endTime = util.formatTime(this.formItem.endTime);
                    _filter.push({
                        name:"AddTime",
                        condition:8,
                        value:_startTime.split(" ")[0]+","+_endTime.split(" ")[0]
                    })
                }

                //开始结束进度
                if(this.formItem.startProcess>0 || this.formItem.endProcess>0){
                    _filter.push({
                        name:"remitProcess",
                        condition:13,
                        value:this.formItem.startProcess+"@"+this.formItem.endProcess
                    })
                }
                
                //开始结束面积
                if(this.formItem.startAcreage>0 || this.formItem.endAcreage>0){
                    _filter.push({
                        name:"sumAcreage",
                        condition:13,
                        value:this.formItem.startAcreage+"@"+this.formItem.endAcreage
                    })
                }

                if(this.formItem.changeNum>-1){
                    _filter.push({
                        name:"changeNum",
                        condition:this.formItem.changeNum==0?2:6,
                        value:0
                    })
                }

                this.filter = _filter;
                this.advancestate = false;
                 this.$emit("on-change",_filter);
            },
            clearAdvanceHandle(){
                for(let key in this.formItem){
                    if(key=="startTime" || key=="endTime"){
                        this.formItem.startTime = "";
                        this.formItem.endTime="";
                    }
                    if(typeof this.formItem[key] =="string"){
                        this.formItem[key]="";
                    }
                    else if(typeof this.formItem[key]=="number"){
                        this.formItem[key]=0;
                    }
                }
            }
        },
        watch: {
            "value":function(){
                if(this.value){
                    this.advanceSearchHandle();
                }
            },
            "clearData":function(){
                this.clearAdvanceHandle();
            },
            "comms.webmodel":function(){
                let _action = this.comms.webmodel.action;
                let _key = this.comms.webmodel.key;
                let _state = this.comms.webmodel.state;
                let _result = this.comms.webmodel.result;
                if(!_state && _action=="result" && _key=="userlist"){
                    this.formItem.saleUserName = _result.userName;
                    this.formItem.saleUserId = _result.id;
                }
            }
        }
    }
</script>