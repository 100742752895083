<style lang="scss" scoped>
.taskstatebox {
    padding: 5px;
    padding-top: 0px;
    border-bottom: 1px solid #ebebeb;
    cursor: pointer;

    .taskname {
        font-weight: bold;
        line-height: 30px;
    }

    .taskdesp {
        color: #999;
    }

    .taskcheck {
        display: none;
    }

    &:hover {
        background-color: #f9f9f9;
    }
}

.taskstate_active {
    .taskname {
        color: #2d8cf0;
    }

    .taskcheck {
        margin-top: 10px;
        font-size: 20px;
        display: inline-block;
    }
}

.taskstatebox:last-child {
    border: none;
}
</style>
<template>
    <div v-if="taskstateobj">
        <Modal v-model="taskstate" width="360" :styles="{ 'top': '10px' }" :mask-closable="false" :closable="false">
            <template #header><h3>任务状态</h3></template>
            <div :class="'taskstatebox ' + (taskStateForm.state == d.id ? 'taskstate_active' : '')"
                v-for="(d, i) in taskstateobj" :key="'taskstate' + i"
                @click="taskStateForm.state = d.id, taskStateForm.remark = d.desp">
                <div class="taskname">{{ d.name }}
                    <Icon class="taskcheck" type="md-checkmark" color="#2d8cf0" />
                </div>
                <div class="taskdesp">{{ d.desp }}</div>
            </div>
            <template #footer>
                <Button @click="() => { this.taskstate = false; this.$emit('input', false); }" icon="md-close">关闭</Button>
                <Button type="primary" @click="updateTaskStateHandle" icon="md-checkmark">确定</Button>
            </template>
        </Modal>
        <Modal v-model="ordertypestate" width="300" footer-hide :mask-closable="false" :closable="false">
            <template #header><span>录单类型</span></template>
            <div style="padding:0px 10px;">
                <Button type="primary" long style="margin-top:10px;" @click="orderTypeSelectHandle(0)">成品图纸</Button>
                <Button type="primary" long style="margin-top:10px;" @click="orderTypeSelectHandle(1)">定制设计</Button>
                <Button type="primary" long style="margin-top:10px;" @click="orderTypeSelectHandle(4)">成品图册</Button>
                <Button long style="margin-top:10px;margin-bottom:10px;"
                    @click="ordertypestate = false, $emit('input', false)">关闭</Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import enums from '@/libs/enums';
import { useUserStore,useCommsStore } from "@/store/user/user";
import { useTaskStore } from "@/store/crm/task";
import {storeToRefs} from "pinia";

export default {
    name:"ChangeTaskState",
    setup() {
       const userstore = useUserStore();
       const taskstore = useTaskStore();
       const commsstore = useCommsStore();
       const {webmodel} = storeToRefs(commsstore);
       
       const {user} = storeToRefs(userstore);
       return {
         user,
         taskstore,
         webmodel,
         setwebmodel:commsstore.setwebmodel,
         closewebmodel:commsstore.closewebmodel
       }
    },
    
    props: {
        modelValue:{
            type:Boolean,
            default:false
        },
        datas:Object,
        inputorder:{
            type:Boolean,
            default:false
        }
    },
    computed:{
        value:{
            get(){
                return this.modelValue;
            },
            set(value){
                this.$emit("update:modelValue",value);
            }
        }
    },
    data() {
        return {
            taskstate: false,
            ordertypestate: false,
            taskstateobj: enums.taskstateobj(),
            taskStateForm: {
                state: 0,
                remark: ""
            },
            formItem: {}
        }
    },
    mounted() {

    },
    methods: {
        updateTaskStateHandle() {
            if (this.taskStateForm.state == 5 || this.taskStateForm.state == 10) {
                this.$emit("on-change", this.taskStateForm);
            }
            else if (this.taskStateForm.state == 7) {
                this.ordertypestate = true;
            }
            else {
                this.taskstore.updatetaskstate([this.taskStateForm]).then(d => {
                    if (d.code == 0) {
                        this.$Message.success("操作成功");
                    }
                    this.taskstate = false;
                    this.$emit('update:modelValue', false);
                    this.$emit("on-change", this.taskStateForm);
                });
            }
        },
        orderTypeSelectHandle(ordertype) {
            this.ordertypestate = false;
            let pathobj = "";
            if (ordertype == 0) {
                pathobj = {
                    path: '/orderedit', query: {
                        customerId: this.datas.customerId,
                        taskId: this.datas.id
                    }
                };
            }
            else if (ordertype == 1) {
                pathobj = {
                    path: '/projectedit', query: {
                        customerId: this.datas.customerId,
                        taskId: this.datas.id
                    }
                };
            }
            else if (ordertype == 4) {
                pathobj = {
                    path: '/peripheralorderedit', query: {
                        customerId: this.datas.customerId,
                        taskId: this.datas.id
                    }
                };
            }

            var url = this.$route.path;
            if (url.indexOf("/webmodel") > -1) {
                this.setwebmodel(pathobj);
            }
            else {
                this.$router.push(pathobj);
            }
            this.$emit('update:modelValue', false);
            if (this.$attrs.onClick) {
                this.$attrs.onClick();
            }
        }
    },
    watch: {
        "value": function () {
            if (this.value) {
                if (this.inputorder) {
                    this.ordertypestate = true;
                    this.taskstate = false;
                }
                else {
                    this.taskstate = true;
                }
            }
            else{
                this.taskstate = false;
            }
            this.taskStateForm = JSON.parse(JSON.stringify(this.datas));
        }
    }
}
</script>