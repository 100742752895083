<style lang="scss" scoped>
.copy-btn {
  cursor: pointer;
}

.wxcodebox {
  img {
    width: 100%;
  }
}

.remarkbox {
  img {
    width: 100%;
  }
}

.consultsendnotice {
  .container {
    padding: 10px;
    display: flex;

    .tx {
      width: 55px;
      height: 55px;
      border-radius: 55px;
      background-color: #ebebeb;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    .content {
      flex: 1;
      border-right: 1px solid #ebebeb;
      padding-left: 10px;

      .title {
        font-weight: bold;
        font-size: 13px;
      }

      .txt {
        font-size: 14px;
      }

      .time {
        color: #999;
      }
    }

    .fn {
      width: 80px;
      display: flex;
      flex-flow: column;

      div {
        flex: 1;
        text-align: center;
        cursor: pointer;
        padding-top: 5px;

        &:hover {
          color: #5cadff;
        }

        &.disabled:hover {
          color: #ddd;
        }
      }

      div:first-child {
        border-bottom: 1px solid #ebebeb;
      }

      .disabled {
        color: #ddd;
      }
    }
  }
}
</style>
<style>
div.consultsendnotice div.ivu-modal-header {
  display: none !important;
}

div.consultsendnotice div.ivu-modal-body {
  padding: 0px !important;
}
</style>
<template>
  <div>
    <Modal class="consultsendnotice" v-model="comms.consultsendnotice.state" :footer-hide="true" :mask-closable="false"
      :closable="false" :styles="{ top: '65px' }">
      <div class="container">
        <div class="tx">
          <img :src="consultsenddata.customerFrom == 3 ? process.env.BASE_URL+'/tz.png' : consultsenddata.headImgUrl" alt="">
        </div>
        <div class="content">
          <div class="title">{{ consultsenddata.name }}</div>
          <div class="txt" v-if="timercount > 3">{{ consultsenddata.txt }},请在({{ timercount }})秒内响应！</div>
          <div class="txt" v-else-if="timercount <= 3 && timercount > 0">该客资正在释放,倒计时({{ timercount }})秒,请及时做出响应!</div>
          <div class="txt" style="color:#ed4014;" v-else>客资获取超时,请及时做出响应！</div>
          <div class="time">{{ consultsenddata.addTime }}</div>
        </div>
        <div class="fn">
          <div @click="msgIsRead">标记已读</div>
          <div :class="timercount <= 3 ? 'disabled' : ''" @click="getSendDetail">获取详情</div>
        </div>
      </div>
    </Modal>
    <Modal v-model="comms.completecustomerinfomodal.state" :width="simple ? 420 : 510" :mask-closable="false"
      :closable="false" :styles="{ top: '20px' }">
      <template #header>
        <div style="font-size:16px;font-weight:bold;">客户信息</div>
      </template>
      <div>
        <div v-if="customerstatus == 'loading'" style="padding:10px 0px;text-align:center;">获取中,请稍候...</div>
        <Form :model="formItem" ref="formItem" :label-width="90" :rules="ruleValidate1"
          v-else-if="customerstatus == 'success'">
          <FormItem label="客户ID">
            {{ formItem.customerCode }}
          </FormItem>

          <FormItem label="客服人员" v-if="formItem.customerFrom != 3 && formItem.userId && (formItem.userId != user.id)">
            <div style="cursor:pointer;" @click="openChatSingle">
              <span style="vertical-align:middle;display:inline-block;margin-top:-5px;">{{ formItem.userName }}</span><i
                class='iconfont icon-message'
                style="vertical-align: top;width:10px;height:10px;margin-left:5px;color:#2d8cf0;"></i>
            </div>
          </FormItem>

          <FormItem label="客户姓名">
            <div v-if="simple">{{ formItem.customerPhone.name }}</div>
            <Input v-else v-model="formItem.customerPhone.name" style="width:380px"></Input>
          </FormItem>

          <FormItem label="微信类型" v-if="formItem.wxCode && !simple">
            <RadioGroup v-model="formItem.wxContactType">
              <Radio label="0">号码</Radio>
              <Radio label="1">二维码</Radio>
            </RadioGroup>
          </FormItem>

          <div>
            <FormItem label="微信二维码" v-if="formItem.wxContactType == '1'">
              <div class="wxcodebox" style="width:90%;">
                <img :src="confgs.viewpath + '/' + formItem.wxCode.split('@')[0]" alt="">
              </div>
            </FormItem>

            <FormItem label="微信号码" prop="wxContact" v-else>
              <div v-if="simple" class="copy-btn"
                :style="{ 'color': (formItem.wxContact ? '#2d8cf0' : 'black'), 'display': 'inline-block' }"
                :data-clipboard-text="formItem.wxContact" @click="copy">{{ formItem.wxContact || "--" }}</div>
              <Input v-else v-model="formItem.wxContact" :disabled="wxContactdisabled" style="width:380px"></Input>
            </FormItem>
          </div>

          <FormItem label="电话号码">
            <div v-if="simple" class="copy-btn"
              :style="{ 'color': (formItem.customerPhone.phone.length > 0 ? '#2d8cf0' : 'black'), 'display': 'inline-block' }"
              :data-clipboard-text="formItem.customerPhone.phone[0].phone" @click="copy">
              {{ (formItem.customerPhone.phone.length > 0 ? (formItem.customerPhone.phone[0].phone || "--") : "--") }}</div>
            <FormPhones v-else v-model="formItem.customerPhone" :state="simple" :submit="simpleUpdateProspect"
              :shake="false" />
          </FormItem>

          <FormItem label="QQ号码" v-if="formItem.qqContact">
            <div v-if="simple" class="copy-btn"
              :style="{ 'color': (formItem.qqContact ? '#2d8cf0' : 'black'), 'display': 'inline-block' }"
              :data-clipboard-text="formItem.qqContact" @click="copy">{{ formItem.qqContact || "--" }}</div>
            <Input v-else v-model="formItem.qqContact" :disabled="wxContactdisabled" style="width:380px"></Input>
          </FormItem>

          <div v-if="simple && formItem.startTime && formItem.kind == '92b1840b-b738-4cad-8f9f-5036f188fcc1'">
            <FormItem label="建房时间">
              <div v-html="formItem.startTime.split(' ')[0]"></div>
            </FormItem>
          </div>
          <div
            v-if="simple && formItem.acreage1 > 0 && formItem.acreage2 > 0 && formItem.acreage > 0 && formItem.kind == '92b1840b-b738-4cad-8f9f-5036f188fcc1'">
            <FormItem label="建筑面积">
              <div class="copy-btn"
                :style="{ 'color': ((formItem.acreage1 > 0 && formItem.acreage2 > 0 && formItem.acreage > 0) ? '#2d8cf0' : 'black') }"
                :data-clipboard-text="'开间' + formItem.acreage1 + ' X ' + '进深' + formItem.acreage2 + ' X ' + '楼层' + formItem.floor + ' = ' + formItem.acreage + '㎡'"
                @click="copy">
                开间:{{ formItem.acreage1 }}&nbsp;X&nbsp;进深{{ formItem.acreage2 }}&nbsp;X&nbsp;楼层:{{ formItem.floor }}&nbsp;=&nbsp;{{ formItem.acreage }}㎡
              </div>
            </FormItem>
          </div>

          <div v-if="simple && formItem.address && formItem.kind == '92b1840b-b738-4cad-8f9f-5036f188fcc1'">
            <FormItem label="咨询地址">
              <div v-html="formItem.address"></div>
            </FormItem>
          </div>

          <div v-if="simple && formItem.remark">
            <FormItem label="备注">
              <div class="remarkbox" v-html="formItem.remark || '无'"></div>
            </FormItem>
          </div>

          <div v-if="simple && uploadList.length > 0">
            <FormItem label="喜欢外观">
              <FormUpload v-model="imgCovers" :default="uploadList" path="product" :singlefile="false" />
            </FormItem>
          </div>

          <div v-if="!simple">
            <FormItem label="客户分类">
              <Select v-model="formItem.kind0" style="width:380px" @on-change="customerkindChangeHandler">
                <Option v-for="item in kindList" :value="item.value" :key="item.label">{{ item.label }}</Option>
              </Select>
            </FormItem>

            <FormItem label="客户意向" v-if="formItem.kind != 'adfc1428-2e82-45b2-b725-28543a01f1e1'">
              <Select v-model="formItem.kind" style="width:380px">
                <Option v-for="item in kindList1" :value="item.value" :key="item.label">{{ item.label }}</Option>
              </Select>
            </FormItem>

            <div
              v-if="formItem.kind == '7e5ad194-0d81-40f8-b813-22087336cf7f' || formItem.kind == '0ce8dde1-55e8-4390-aa62-753f1b8e18aa' || formItem.kind == 'd6ce0cff-6752-4f9d-ac1e-68f4d2ebfd50'">
              <FormItem label="备注" prop="remark">
                <Input type="textarea" v-model="formItem.remark" style="width:380px"></Input>
              </FormItem>
            </div>

            <div v-else>
              <FormItem label="建筑面积" v-if="formItem.kind == '92b1840b-b738-4cad-8f9f-5036f188fcc1'">
                开间&nbsp;<InputNumber v-model="formItem.acreage1" @on-change="acreageChangeHandler" placeholder="请输入"
                  style="width:40px;"></InputNumber>&nbsp;米&nbsp;
                X&nbsp;
                进深&nbsp;<InputNumber v-model="formItem.acreage2" @on-change="acreageChangeHandler" placeholder="请输入"
                  style="width:40px;"></InputNumber>&nbsp;米&nbsp;
                X&nbsp;
                &nbsp;<InputNumber v-model="formItem.floor" @on-change="acreageChangeHandler" placeholder="请输入"
                  style="width:40px;"></InputNumber>&nbsp;层

                &nbsp;=&nbsp;&nbsp;&nbsp;<InputNumber v-model="formItem.acreage" disabled style="width:50px;">
                </InputNumber>&nbsp;&nbsp;平方米
              </FormItem>

              <FormItem label="建房日期" prop="startTime" v-if="formItem.kind == '92b1840b-b738-4cad-8f9f-5036f188fcc1'">
                <Select v-model="formItem.startTime" style="width:380px;">
                  <Option :value="d.id"
                    v-for="(d, i) in buildtime.filter(f => f.name == '正在建' && f.kind == '85da88eb-fc1b-47b2-8584-639ab71731f8' || f.kind != '正在建')"
                    :key="'startTime' + i">
                    {{ d.name }}
                  </Option>
                </Select>
              </FormItem>

              <FormItem label="建房区域" prop="area" v-if="formItem.kind == '92b1840b-b738-4cad-8f9f-5036f188fcc1'">
                <FormArea v-if="comms.completecustomerinfomodal.state" v-model="areas" :init="initareas" />
                <Input v-model="formItem.area" style="display:none;" />
              </FormItem>


              <FormItem label="备注说明">
                <Input type="textarea" v-model="formItem.remark" :row="5" style="width:380px" />
              </FormItem>
            </div>
          </div>
        </Form>
        <div v-else-if="customerstatus == 'delete'" style="padding:10px 0px;text-align:center;">该客资已经被客服删除!</div>
        <div v-else style="padding:10px 0px;text-align:center;">
          该客资因超时已被推送给其他销售,请及时响应!
        </div>
      </div>
      <template #footer>
        <Button type="success" class="copy-btn" icon="ios-copy" v-if="customerstatus == 'success'"
          @click="()=>{
            this.$Copy({
              text:'客户姓名:' + formItem.customerPhone.remarkName + (formItem.wxContact ? ('\n微信号码:' + formItem.wxContact) : '') + (formItem.customerPhone.phone && formItem.customerPhone.phone[0].phone ? ('\n手机号码:' + formItem.customerPhone.phone[0].phone) : '') + (formItem.qqContact ? ('\nQQ号码:' + formItem.qqContact) : '') + ((formItem.acreage > 0 || formItem.floor > 0) ? ('\n建筑面积:开间' + formItem.acreage1 + ' X ' + '进深' + formItem.acreage2 + ' X ' + '楼层' + formItem.floor + ' = ' + formItem.acreage + '㎡') : '') + '\n咨询地址:' + (formItem.address + (formItem.remark ? ('\n备注:' + formItem.remark) : ''))
            })
          }">复制信息</Button>
        <Button type="primary" icon="md-paper" v-if="simple && formItem.isValid != 2 && customerstatus == 'success'"
          @click="setCompleteInfo">完善信息</Button>
        <Button type="error" icon="ios-alert"
          v-if="simple && formItem.isValid != 2 && customerstatus == 'success' && formItem.taskId" @click="validcustomerstate = {
            showState: true,
            taskId: formItem.taskId,
            bizId: formItem.customerPhone.id
          }">无效</Button>
        <Button type="default" icon="md-close"
          @click="setcomms({ completecustomerinfomodal: { state: false, datas: '' } });">关闭</Button>
        <Button type="primary" icon="md-checkmark" v-if="!simple" @click="submit">完成</Button>
      </template>
    </Modal>
    <MyCustomerIsValidModal v-model="validcustomerstate" :bizId="formItem.customerPhone.id" :taskId="formItem.taskId" />
  </div>
</template>
<script>
import dict from "@/libs/dict.js";
import utils from "@/libs/util.js";
import MyCustomerIsValidModal from '@/components/mycustomertablemodal/isvalid';
import { useUserStore, useCommsStore } from "@/store/user/user";
import { useBaseInfoStore } from "@/store/crm/baseinfo";
import { useCustomerStore } from "@/store/crm/customer";
import { useChatStore } from "@/store/chat/index";
import { storeToRefs } from 'pinia';

export default {
  name: 'CompleteCustomerInfoModal',
  props: ['initdatas'],
  setup() {
    const userstore = useUserStore();
    const commsstore = useCommsStore();
    const baseinfostore = useBaseInfoStore();
    const customerstore = useCustomerStore();
    const chatstore = useChatStore();
    const { user } = storeToRefs(userstore);
    const { comms } = storeToRefs(commsstore);

    return {
      user,
      comms,
      setcomms: commsstore.setcomms,
      // consultsendnotice:comms.consultsendnotice,
      // completecustomerinfomodal:comms.completecustomerinfomodal,
      userstore,
      baseinfostore,
      customerstore,
      chatstore
    }
  },
  components: { MyCustomerIsValidModal },
  data() {
    const validfloor = (rule, value, callback) => {
      if (value === 0) {
        callback(new Error('楼层不能为空'));
      } else {
        callback();
      }
    };
    return {
      validcustomerstate: false,
      bizId: "",
      formItem: {
        customerCreateUser: "",
        kind: "",
        kind0: "",
        ckindId: "",
        customerPhone: {
          id: "",
          phone: [],
          name: "",
          remarkName: ""
        },
        wxCode: "",
        wxContactType: "",
        wxContact: "",
        acreage: 0,
        acreage1: 0,
        acreage2: 0,
        floor: 0,
        startTime: "",
        area: "",
        provinceId: 0,
        cityId: 0,
        areaId: 0,
        province: "",
        city: "",
        remark: ""
      },
      imgCovers: [],
      buildtime: dict.buildtime(),
      phonestate: false,
      wxContactdisabled: false,
      confirm: false,
      areas: {},
      initareas: {
        province: "",
        provinceId: 0,
        city: "",
        cityId: 0,
        area: "",
        areaId: 0
      },
      acreage: dict.acreage(),
      floor: dict.floor(),
      kindList: [],
      kindList1: [],
      kindList2: [],
      starttimeoptions: {
        disabledDate(date) {
          var _date = new Date();
          if (new Date(date.valueOf()).getFullYear() == _date.getFullYear()) {
            return date && new Date(date.valueOf()).getMonth() < new Date().getMonth();
          }
          else {
            return false;
          }
        }
      },
      ruleValidate1: {
        wxContact: [
          {
            required: true,
            message: "微信号码不能为空",
            trigger: "blur"
          }
        ],
        acreageTxt: [
          {
            required: true,
            message: "占地面积不能为空",
            trigger: "blur"
          }
        ],
        floor: [
          {
            required: true,
            validator: validfloor,
            trigger: "blur"
          }
        ],
        startTime: [
          {
            required: true,
            message: "建房日期不能为空",
            trigger: "blur"
          }
        ],
        area: [
          {
            required: true,
            message: "建房区域不能为空",
            trigger: "blur"
          }
        ],
        // remark:[
        //   {
        //     required: true,
        //     message: "备注不能为空",
        //     trigger: "blur"
        //   }
        // ]
      },
      simple: true,
      timer: null,
      timercount: 30,
      consultsenddata: {
        id: "",
        name: "",
        txt: "",
        addTime: ""
      },
      customerstatus: "loading",
      customerkinddata: [],
      uploadList: []
    }
  },
  mounted() {
  },
  methods: {
    submit() {
      try {
        this.formItem.area = this.areas.area;
        this.$refs["formItem"].validate((valid) => {
          if (!valid) {
            return false;
          } else {
            let json = {
              id: this.formItem.customerPhone.id,
              taskId: this.formItem.taskId,
              kind: this.formItem.kind,
              name: this.formItem.customerPhone.name,
              remarkName: this.formItem.customerPhone.remarkName,
              acreageTxt: this.formItem.acreageTxt,
              acreage: this.formItem.acreage,
              acreage1: this.formItem.acreage1,
              acreage2: this.formItem.acreage2,
              floor: this.formItem.floor,
              startTime: this.formItem.startTime,
              provinceId: this.areas.provinceId,
              cityId: this.areas.cityId,
              areaId: this.areas.areaId,
              province: this.areas.province,
              city: this.areas.city,
              area: this.areas.area,
              wxContact: this.formItem.wxContact,
              qqContact: this.formItem.qqContact,
              kind0: this.formItem.kind0,
              ckindId: this.formItem.ckindId,
              isSpecial: this.formItem.isSpecial,
              remark: this.formItem.remark
            }
            this.formItem.customerPhone.phone.forEach(e => {
              if (e.isDefault == true) {
                json.phone = e.phone;
              }
              else {
                if (!json.phone1) {
                  json.phone1 = e.phone;
                }
                else {
                  json.phone2 = e.phone;
                }
              }
            });

            this.completecustomerInfo(json).then(d => {
              if (d.code == 0) {
                this.$Message.success("操作成功");
                this.customerstatus = "loading";
                this.setcomms({ completecustomerinfomodal: { state: false, datas: '', refresh: Math.random() } });
              }
            });
          }
        });
      }
      catch (ex) {
        this.$Message.error(ex.msg);
      }
    },
    simpleUpdateProspect() {
      this.phonestate = false;
    },
    openChatSingle() {
      this.setcomms({
        opendialog: {
          state: true,
          datas: {
            bizId: this.formItem.userId,
            bizName: this.formItem.userName,
            customerId: this.formItem.customerPhone.id,
            currenttab: 1,
            flag: 0
          }
        }
      });
      this.setcomms({ completecustomerinfomodal: { state: false } });
    },
    copy() {
      var clipboard = new this.clipboard(".copy-btn");
      clipboard.on("success", () => {
        this.$Message.success("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        this.$Message.error("复制失败");
        // 释放内存
        clipboard.destroy();
      });
    },
    setCompleteInfo() {
      this.simple = false;
      this.formItem.customerPhone = JSON.parse(JSON.stringify(this.formItem.customerPhone));
    },
    msgIsRead() {
      clearInterval(this.timer);
      //这是信息已读
      this.setcomms({ consultsendnotice: { state: false, data: '' } });
      this.chatstore.chatisread([{ id: this.consultsenddata.id, flag: 2 }]);
      this.customerstatus = "loading";
      //关闭闪烁
      if (window.Client) {
        window.Client.closeFlash();
      }
      //本地减少信息未读数
      this.setcomms({
        updatemsgindialog: {
          data: {
            id: this.consultsenddata.id,
            flag: 2,
          }, action: "isread", refresh: Math.random()
        }
      });
    },
    getSendDetail() {
      //关闭闪烁
      // if(window.Client){
      //     window.Client.closeFlash();
      // }
      this.msgIsRead();
      this.setcomms({ completecustomerinfomodal: { state: false, id: "", simple: true } });
      if (this.timercount <= 0) {
        return false;
      }

      setTimeout(() => {
        this.setcomms({ completecustomerinfomodal: { state: true, id: this.consultsenddata.bizId, simple: true } });
      }, 500);
    },
    customerkindChangeHandler(val) {
      this.formItem.kind0 = val;
      let attrs = [];
      this.customerkinddata.forEach(e => {
        if (e.id == val) {
          e.children.forEach(e1 => {
            attrs.push({
              label: e1.title,
              value: e1.id
            })
          });
        }
      });
      if (attrs.length > 0) {
        this.kindList1 = attrs;
        this.formItem.kind = this.kindList1[0].value;
      }
    },
    acreageChangeHandler() {
      this.formItem.acreage = 0;
      var mult1 = utils.multiply(this.formItem.acreage1, this.formItem.acreage2);
      this.formItem.acreage = utils.multiply(mult1, this.formItem.floor)
    },
    copyinfoHandle() {

    }
  },
  watch: {
    "comms.completecustomerinfomodal.state": function () {
      let _id = this.comms.completecustomerinfomodal.id;
      let _state = this.comms.completecustomerinfomodal.state;
      let _datas = this.comms.completecustomerinfomodal.datas;
      let _simple = this.comms.completecustomerinfomodal.simple;

      if (_state) {
        this.simple = _simple;
        this.baseinfostore.getcustomerkind({ state: 0 },d => {
            this.kindList = [];
            this.kindList1 = [];
            this.customerkinddata = d;
            this.customerkinddata.forEach((v, i) => {
              this.kindList.push({
                label: v.title,
                value: v.id
              });
              if (i == 0) {
                v.children.forEach(e => {
                  this.kindList1.push({
                    label: e.title,
                    value: e.id
                  });
                });
              }
            });
        });
        setTimeout(() => {
          if (_datas) {
            this.formItem = JSON.parse(JSON.stringify(_datas));
            this.initareas = JSON.parse(JSON.stringify(this.formItem.initareas));
            this.formItem.provinceId = this.initareas.provinceId;
            this.formItem.province = this.initareas.province;
            this.formItem.areaId = this.initareas.areaId;
            this.formItem.area = this.initareas.area;
            this.formItem.cityId = this.initareas.cityId;
            this.formItem.city = this.initareas.city;
            this.formItem.customerFrom = this.formItem.from;
            if (!this.formItem.kind && this.kindList.length > 0) {
              this.formItem.kind0 = this.kindList[0].value;
              this.formItem.kind = this.kindList1[0].value;
            }
            else {
              this.customerkinddata.forEach(e => {
                if (e.id == this.formItem.kind0) {
                  this.kindList1 = e.children.map(m => {
                    return {
                      label: m.title,
                      value: m.id
                    }
                  });
                }
              });
            }

            this.customerstatus = "success";
          }
          else if (_id) {
            this.customerstore.getcustomerdetail(_id).then(d => {
              if (d.code == 0) {
                let _d = d.datas;
                let _formItem = {
                  customerCreateUser: "",
                  kind0: "",
                  kind: "",
                  taskId: "",
                  ckindId: "",
                  customerPhone: {
                    id: "",
                    name: "",
                    remarkName: "",
                    phone: []
                  },
                  customerFrom: 0,
                  customerCode: "",
                  wxCode: "",
                  wxContactType: "",
                  wxContact: "",
                  acreage: 0,
                  acreage1: 0,
                  acreage2: 0,
                  acreage3: 0,
                  acreagetxt: "",
                  remark: "",
                  floor: "",
                  startTime: "",
                  area: "",
                  provinceId: "",
                  cityId: "",
                  areaId: "",
                  province: "",
                  city: "",
                  attr: "",
                  address: "",
                  isSpecial: 0,
                  source: ""
                };
                if (_d.startTime) {
                  _formItem.startTime = utils.formatObj(_d.startTime);
                }
                if (_d.customerAddressId) {
                  var _addressId = _d.customerAddressId.split('/');
                  _formItem.initareas = {
                    provinceId: _addressId[0],
                    cityId: _addressId[1],
                    areaId: _addressId[2] == 0 ? -1 : _addressId[2],
                  };
                  this.initareas = JSON.parse(JSON.stringify(_formItem.initareas));
                }

                _formItem.userName = _d.userName;
                _formItem.userId = _d.userId;
                _formItem.taskId = _d.taskId;
                _formItem.provinceId = _d.provinceId;
                _formItem.wxContactType = _d.wxContactType;
                _formItem.wxCode = _d.wxCode;
                _formItem.wxContactdisabled = _d.wxContact && _d.wxContact.length > 0;
                _formItem.floor = _d.floor;
                _formItem.customerCode = _d.code;
                _formItem.wxContact = _d.wxContact;
                _formItem.qqContact = _d.qqContact;
                _formItem.customerFrom = _d.from;
                _formItem.isSpecial = _d.isSpecial;
                _formItem.customerPhone.phone = [];
                _formItem.customerPhone.phone.push({
                  phone: _d.phone,
                  isDefault: true
                });
                _formItem.customerPhone.id = _d.id;
                _formItem.customerPhone.name = _d.name;
                _formItem.customerPhone.remarkName = _d.remarkName;
                _formItem.remark = _d.remark;
                _formItem.kind0 = _d.kind0;
                _formItem.kind = _d.kind;
                _formItem.source = _d.source;

                if (!_formItem.kind && this.kindList.length > 0) {
                  _formItem.kind0 = this.kindList[0].value;
                  _formItem.kind = this.kindList1[0].value;
                }
                else {
                  this.customerkinddata.forEach(e => {
                    if (e.id == _formItem.kind0) {
                      this.kindList1 = e.children.map(m => {
                        return {
                          label: m.title,
                          value: m.id
                        }
                      });
                    }
                  });
                }

                _formItem.ckindId = _d.ckindId;
                _formItem.isValid = _d.isValid;
                _formItem.attr = _d.attr;
                _formItem.acreage = _d.acreage;
                _formItem.acreage1 = _d.acreage1;
                _formItem.acreage2 = _d.acreage2;
                _formItem.acreage3 = _d.acreage3;
                _formItem.acreageTxt = _d.acreageTxt;
                _formItem.remark = _d.remark;
                _formItem.startTime = _d.startTime;
                _formItem.budget = _d.budget;
                _formItem.level = _d.level;
                _formItem.address = _d.province ? (_d.province + "/" + _d.city + "/" + _d.area) : "";

                this.formItem = JSON.parse(JSON.stringify(_formItem));
                this.customerstatus = "success";
                // if(_d.iLike){
                //     this.uploadList = [];
                //   _d.iLike.forEach(e=>{
                //     this.uploadList.push({
                //       url:e.split("@")[0]
                //     }); 
                //   });
                // }
              }
              else if (d.code == 90) {
                //客资已经被删除
                this.customerstatus = "delete";
              }
              else if (d.code == 1) {
                //获取客户失败或该客资因超时已被推送给其他销售,请及时响应!
                this.customerstatus = "timeout";
              }
            });
          }
        }, 400);
      }
      else {
        this.customerstatus = "loading";
      }
    },
    "comms.consultsendnotice.state": function () {
      let _state = this.comms.consultsendnotice.state;
      let _data = this.comms.consultsendnotice.data;
      if (_state && _data) {
        clearInterval(this.timer);
        this.timercount = 30;
        this.consultsenddata = {
          id: _data.id,
          bizId: _data.bizId,
          headImgUrl: _data.headImgUrl,
          customerFrom: _data.customerFrom,
          name: _data.name,
          txt: _data.txt,
          addTime: _data.addTime
        }
        this.timer = setInterval(() => {
          if (this.timercount > 0) {
            this.timercount--;
          }
          else {
            clearInterval(this.timer);
          }
        }, 1000);
      }
    },
    "timercount": function () {
      this.setcomms({ consultsendnotice: { time: this.timercount } });
    },
    "areas": {
      deep: true,
      handler: function () {
        this.formItem.provinceId = this.areas.provinceId;
        this.formItem.province = this.areas.province;
        this.formItem.areaId = this.areas.areaId;
        this.formItem.area = this.areas.area;
        this.formItem.cityId = this.areas.cityId;
        this.formItem.city = this.areas.city;
        this.formItem = JSON.parse(JSON.stringify(this.formItem));
      }
    }
  }
}
</script>

