<style lang="scss" scoped>

</style>
<template>
    <div style="width:180px">
        <Input v-model="customerPhone" @on-blur="checkuserphone(customerPhone)" :disabled='view' placeholder="请输入"
            :style="{ width: shake ? '150px' : '175px', float: 'left' }">
        <template #append>
            <Button icon="md-add" :disabled='view' @click="phonestate = true"></Button>
        </template>
        </Input>
        <div v-if="shake" class="findinput" :class="findinputshake ? ' findinput_shake' : ''"
            style="float:right;cursor:pointer;">
            <i class="iconfont icon-lianjie-tianchong" @click="showDetail"></i>
        </div>
        <Modal v-model="phonestate" width="310" closable>
            <template #header>
                <h3>电话号码</h3>
            </template>
            <div style="margin-bottom:10px;" v-for="(d, i) in updateContent.phone" :key="'updateprospectinorder' + i">
                <Input v-model="d.phone" :disabled="d.disable" placeholder="请输入" style="width:220px" />
                <Icon type="md-add" size="20" @click="addPhone" style="cursor:pointer;margin-right:5px;"></Icon>
                <Icon :type="d.isDefault?'ios-flag':'ios-flag-outline'" size="20" @click="defaultPhone(i)" style="cursor:pointer;margin-right:5px;"></Icon>
                <Icon type="md-close" size="20" @click="removePhone(i)" v-if="!d.disable && updateContent.phone.length > 1" style="cursor:pointer;"></Icon>
            </div>
            <template #footer>
                <Button @click="phonestate = false" icon="md-close">取消</Button>
                <Button type="info" @click="onOk" icon="md-checkmark">确定</Button>
            </template>
        </Modal>
    </div>
</template>

<script>
import { useCustomerStore } from "@/store/crm/customer";

export default {
    name: 'FormPhones',
    setup() {
        const customerstore = useCustomerStore();
        return {
            customerstore
        }
    },
    props: {
        modelValue: Boolean,
        shake: Boolean,
        modelVlaue: Object
    },
    data() {
        return {
            phonestate: false,
            test: "",
            customerPhone: "",
            findinputshake: false,
            updateContent: {
                phone: [{
                    phone: "",
                    isDefault: true,
                    disable: false
                }]
            }
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value);
            }
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            if (this.value.phone.length == 0) {
                this.updateContent.id = this.value.id;
                this.updateContent.remarkName = this.value.remarkName;
                return false;
            }
            this.customerPhone = this.value.phone[0].phone;

            this.value.phone.forEach((e) => {
                if (e.isDefault) {
                    this.customerPhone = e.phone;
                }
            });
            this.updateContent = JSON.parse(JSON.stringify(this.value));
        },
        addPhone() {
            if (this.updateContent.phone.length == 3) {
                this.$Message.error("客户至多只能添加3个电话号码");
                return false;
            }
            let _p = [{ phone: "", disable: false, isDefault: false }];
            this.updateContent.phone.forEach((v) => {

                _p.push(v);
            });
            this.updateContent.phone = _p;
        },
        removePhone(index) {
            this.updateContent.phone.forEach((v, i) => {
                if (i == index) {
                    this.updateContent.phone.splice(i--, 1);
                }
            });
        },
        defaultPhone(index) {
            this.updateContent.phone.forEach((v, i) => {
                if (i == index) {
                    v.isDefault = true;
                }
                else {
                    v.isDefault = false;
                }
            });
        },
        showDetail() {
            this.setcomms({ drawer: { state: true, path: 'drawer1', title: '客户记录' } })
        },
        onOk() {
            let phone = [];
            this.updateContent.phone.forEach((e) => {
                if (e.isDefault) {
                    this.customerPhone = e.phone;
                }
                if (e.phone) {
                    phone.push(e.phone);
                }
            });
            // this.customerstore.findcustomer({
            //     page: 1,
            //     row: 10,
            //     phone: phone.join('|')
            // }).then(d => {
            //     if (d.code == 0) {
            //         this.$emit("update:shake", true);
            //         // this.shake = true;
            //     }
            // });

            this.$emit("update:modelValue", this.updateContent);
            this.phonestate = false;
        },
        checkuserphone(phone) {
            if (phone) {
                var reg = /^1[0-9]{10}$/;
                if (!reg.test(phone)) {
                    this.customerPhone = "";
                    this.$Message.error("请填写正确的手机号码");
                    return false;
                }
            }
            this.updateContent.phone.forEach(d => {
                d.isDefault = false;
            });
            this.updateContent.phone.push({
                phone: this.customerPhone,
                isDefault: true,
                disable: false
            });
            this.$emit("update:modelValue", this.updateContent);
        }
    },
    watch: {
        "value": {
            deep: true,
            handler: function () {
                setTimeout(() => {
                    this.init();
                }, 0);
            }
        },
        "shake": function () {
            if (this.shake) {
                this.findinputshake = this.shake;
                this.timer = setInterval(() => {
                    this.findinputshake = !this.findinputshake;
                }, 1000);
            }
            else {
                clearInterval(this.timer);
            }
        }
    }
}
</script>

