<template>
    <div>
        <!-- 浏览界面 -->
        <div class="fastbox" ref="fastbox">
            <ul v-if="fastlink && fastlink.menuStr.indexOf(dd.id+',')>-1">
                <li @click="fastlinkstate=true">
                    <div class="set"><Icon type="md-add"></Icon></div>
                    <p>快捷方式</p>
                </li>
                <template v-for="(d,i) in fastlinkdata">
                    <li v-for="(dd,j) in d.children" :key="'fastlink_'+i+'_'+j"  @click="$router.push(dd.url)">
                        <div class="" :style="{'background-color':dd.bColor}">
                            <i class="iconfont " :class="dd.icon" style="color:white;"></i>
                        </div>
                        <p>{{dd.name}}</p>
                    </li>
                </template>
            </ul>
        </div>
        <!-- 编辑页面 -->
        <Modal v-model="fastlinkstate" width="660" :closable="false" :mask-closable="false">
            <p slot="header">
                <span>配置快捷方式</span>
            </p>
            <div>
                <div v-for="(d,i) in fastlinkdata" :key="'columns'+i" style="margin-bottom:10px;">
                    <h3 style="margin-bottom:5px;">{{d.name}}</h3>
                    <Checkbox v-model="dd.checked" v-for="(dd,j) in d.children"  :key="'column'+j">{{dd.name}}</Checkbox>
                </div>
            </div>
            <div slot="footer">
                <Button size="large" @click="fastlinkstate=false">关闭</Button>
                <Button type="info" size="large" @click="setFastLink">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
// import {mapGetters,mapActions} from 'vuex'
import util from '../../libs/util.js'
export default {
  name: 'FastBox',
  props:['bindElem','column'],
  data () {
    return {
        fastlinkstate:false,
        fastlinkdata:[],
        fastlink:""
    }
  },
  computed:{
    // ...mapGetters([
    //     'result',
    //     'user',
    //     'infomodal'
    // ])
  },
  mounted(){
    this.$nextTick(function(){  //页面加载完成后执行
        let $fastlink = document.getElementsByClassName(this.bindElem)[0];
        let $fastbox = this.$refs.fastbox;
      
        if($fastlink){
            $fastlink.addEventListener("mouseover",function(){
                $fastbox.style.display = "block";
            });
            $fastlink.addEventListener("mouseout",function(){
                $fastbox.style.display = "none";
            });
            $fastbox.addEventListener("mouseover",function(){
                $fastbox.style.display = "block";
            });
            $fastbox.addEventListener("mouseout",function(){
                $fastbox.style.display = "none";
            });
        }
      })
  },
  methods:{
    // ...mapActions([
    //     'setfastlink',
    //     'getfastlink'
    // ]),
    loadData(){
        this.getfastlink(this.user.id).then(d=>{
            if(d.code==0){
                this.fastlink="";
                this.fastlink = d.datas
                this.syncFunc();
            }
        });
    },
    setFastLink(){
        let _d = [];
        this.fastlinkdata.forEach((v)=>{
            v.children.forEach((k)=>{
                if(k.checked){
                    _d.push(k.id);
                }
            });
        });
        this.setfastlink({
            menuStr:_d.join(",")+",",
            userId:this.user.id
        }).then(d=>{
            if(d.code==0){
                this.loadData();
            }
        });
        this.fastlinkstate = false;
    },
    syncFunc(){
        setTimeout(()=>{
            if(this.fastlink && this.column){
                this.fastlinkdata = util.newObj(this.column).datas;
                if(this.fastlink && this.fastlink.menuStr){
                    this.fastlinkdata.forEach((v)=>{
                        v.children.forEach((k)=>{
                            this.fastlink.menuStr.split(',').forEach((m)=>{
                                if(k.id == m){
                                    k.checked = true;
                                }
                            });
                        });
                    });
                }
            }
        },0);
    }
  },
  watch:{
    "column":function(){
        this.loadData();
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./style.scss";
</style>

