<template>
    <div class="layout-content-main">
        <slot name="content"></slot>
    </div>
</template>

<script>
export default {
  name: 'DynamicContainerChildContainer',
  data () {
    return {
        
    }
  },
  computed:{
  },
  mounted(){
      
  },
  methods:{
    
  },
  watch:{
     
  }
}
</script>
