<style lang="scss" scoped>

</style>
<template>
    <div v-if="flowtype">
        <div v-if="userList.length == 0">无</div>
        <div v-else>
            <Select v-model="selected" @on-change="selectedChange" style="width:80%;display:inline-block;" filterable
                :disabled="disabled">
                <Option v-for="item in userList" :value="item.value" :key="item.label">{{ item.label }}</Option>
            </Select>
            <Icon type="md-help-circle" v-if="flowtype != 'alluser'" :size="20" style="cursor:pointer;"
                @click="setcomms({ flowbizmodal: { state: true, flowtype: flowtype } })" />
        </div>
    </div>
</template>

<script>
import { useUserStore } from "@/store/user/user";
import { storeToRefs } from "pinia";
export default {
    name: 'FormAuditor',
    setup() {
        const userstore = useUserStore();
        const { user } = storeToRefs(userstore);
        return {
            user,
            userstore
        }
    },
    props: ['value', 'flowtype', 'disabled', 'binduser', 'inorganize', 'initdatas'],
    data() {
        return {
            userList: [],
            selected: "",
            type: ""
        }
    },
    //   computed:{
    //     ...mapGetters([
    //         'user'
    //     ])
    //   },
    mounted() {
        if (this.initdatas && this.initdatas.length > 0) {
            this.userList = [];
            this.selected = "";

            this.initdatas.forEach((v, i) => {
                if (i == 0) {
                    this.selected = v.id;
                    this.$emit("update:modelValue", v.id);
                }
                this.userList.push({
                    label: v.name || v.userName,
                    value: v.id
                });
            });
        }
        else {
            this.init();
        }
    },
    methods: {
        // ...mapActions([
        //     'getauditor',
        //     'setcomms',
        //     'getmember'
        // ]),
        init() {
            //审核有两种模式
            //1、走审核流程
            //2、非审核流程,直接指定人去确定
            setTimeout(() => {
                if (!this.user.roleId) { return false; }
                if (this.flowtype == "alluser") {
                    this.userstore.getmember({ page: 1, row: 1000, flag: 3, orderby: "org" }, d => {
                        this.userList = [];
                        if (d.code == 0) {
                            d.forEach((v) => {
                                this.userList.push({
                                    label: v.name,
                                    value: v.id
                                });
                            });
                        }
                    });
                }
                else {
                    if (this.flowtype) {
                        this.userstore.getauditor({ type: this.flowtype, userId: (this.binduser ? this.binduser : this.user.roleId), inorganize: this.inorganize ? this.inorganize : false }, d => {
                            this.userList = [];
                            this.selected = "";
                            d.forEach((v, i) => {
                                if (i == 0) {
                                    this.selected = v.id;
                                    this.$emit("update:modelValue", v.id);
                                    if (v.id == this.user.id) {
                                        this.$emit("end", true);
                                    }
                                }
                                this.userList.push({
                                    label: v.userName,
                                    value: v.id
                                });
                            });
                        });
                    }
                }
            }, 200);
        },
        selectedChange() {
            this.$emit("update:modelValue", this.selected);
        }
    },
    watch: {
        "flowtype": function () {
            this.init();
        },
        "binduser": function () {
            this.init();
        },
        "initdatas": {
            deep: true,
            handler: function () {

            }
        }
    }
}
</script>

