<template>
  <div>
    <div id="box">
      <div id="calendar" v-if="pickDay != null">
        <div class="refresh" v-if="user.code=='SUPERADMIN'">
          <Icon type="md-refresh" size="20" color="#999" @click="refreshStatus=true;loadData()"/>
        </div>
        <div class="pickDay">
          <span>{{pickDay}}</span>
          <span>日</span>
        </div>
        <!-- 年份 月份 -->
        <div class="month">
          <ul>
            <li class="arrow" @click="pickPre(currentYear,currentMonth)">❮</li>
            <li class="year-month" @click="pickYear(currentYear,currentMonth)">
              <span class="choose-year">{{ currentYear }}</span>
              <span class="choose-month">&nbsp;年&nbsp;{{ currentMonth }}&nbsp;月</span>
            </li>
            <li class="arrow" @click="pickNext(currentYear,currentMonth)">❯</li>
          </ul>
        </div>
        <!-- 星期 -->
        <ul class="weekdays">
          <li>一</li>
          <li>二</li>
          <li>三</li>
          <li>四</li>
          <li>五</li>
          <li style="color:red">六</li>
          <li style="color:red">日</li>
        </ul>
        <!-- 日期 -->
        <ul class="days">
          <li @click="pick(day.day)" v-for="(day,i) in days" :key="'days'+i">
            <!--本月-->
            <span class="momo_mark" v-if="day.memo_type"></span>
            <!-- <span class="momo_rest" v-if="day.memo_type">休</span> -->
            <span
              v-if="day.day.getMonth()+1 != currentMonth"
              class="other-month"
            >{{ day.day.getDate() }}</span>
            <span v-else>
              <!--今天-->
              <span
                v-if="day.day.getFullYear() == new Date().getFullYear() && day.day.getMonth() == new Date().getMonth() && day.day.getDate() == new Date().getDate()"
                class="active"
              >{{ day.day.getDate() }}</span>
              <span v-else>{{ day.day.getDate() }}</span>
            </span>
          </li>
        </ul>
      </div>
      <!-- <div id="memo">
        <h4>
          备忘录
          <span class="memo_add" @click="addremindstate=true">
            <Icon type="md-add-circle" size="16" />
          </span>
        </h4>
        <ul class="memo_list" v-if="currentMemo.length>0">
          <li v-for="(memo,index) in currentMemo" :key="index">
            <div class="memo_list_s" @click="showRemind(memo)">
              <span class="circle"></span>
              <div class="memo_list_mont">{{memo.cont}}</div>
            </div>
          </li>
        </ul>
        <div v-else class="empty">
          <Icon type="logo-buffer" size="30" color="#999" />
          <div>没有任何记录</div>
        </div>
      </div> -->
    </div>
    <Modal v-model="addremindstate" width="450" :closable="false">
      <div slot="header" style="font-size:16px;font-weight:bold;">备忘录</div>
      <div style="margin-bottom:10px;">
        <DatePicker
          type="datetime"
          v-model="remindFormItem.remindTime"
          placeholder="请选择提醒时间"
          style="width:100%;margin-bottom:10px;"
        ></DatePicker>
        <Input type="textarea" v-model="remindFormItem.content" :rows="10" placeholder="请输入提醒内容" />
      </div>
      <div slot="footer">
        <Button @click="addremindstate=false" style="padding:5px 15px">取消</Button>
        <Button type="info" @click="remindOk" style="padding:5px 15px">确定</Button>
      </div>
    </Modal>
    
    <Modal v-model="remindstate" width="300" :closable="false">
      <div slot="header" style="font-size:16px;font-weight:bold;">{{remindFormItem1.remindTime}}&nbsp;的备忘录</div>
      <div style="padding:10px;">
        {{remindFormItem1.content}}
      </div>
      <div slot="footer">
        <Button @click="remindstate=false" style="padding:5px 15px">关闭</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import utils from "@/libs/util.js";
import { useUserStore } from "@/store/user/user";
import {storeToRefs} from "pinia";
export default {
  name: "Memorandum",
  setup() {
     const userstore = useUserStore();
     const {user} = storeToRefs(userstore);
     return {
       user
     }
  },
  props: ["value", "txt"],
  data() {
    return {
      refreshStatus:false,
      addremindstate: false,
      remindstate:false,
      remindFormItem: {
        remindTime: "",
        content: ""
      },
      remindFormItem1: {
        remindTime: "",
        content: ""
      },
      currentDay: 1,
      currentMonth: 1,
      currentYear: 1970,
      currentWeek: 1,
      days: [],
      pickDay: null,
      currentMemo: [],
      memoTemp:[],
      memo_list: [] //时间格式必须为xxxx-xx-xx;
    };
  },
  computed: {
    // ...mapGetters([ "user", "comms"])
  },
  mounted() {
    this.loadData();
  },
  methods: {
    // ...mapActions([
    //   "addremindme",
    //   "getremindme",
    //   'delremindme',
    //   'getremindmedetail'
    // ]),
    loadData(){
      // let date = new Date();
      // let _month = date.getMonth() + 1;
      //this.currentYear.toString()+(this.currentMonth>9?this.currentMonth:"0"+this.currentMonth).toString()
      // this.getremindme({month:_month,refreshCache:this.refreshStatus}).then(d=>{
      //   this.memoTemp = [];
      //   this.memo_list = [];
      //   if(d.code==0){
      //     d.datas.forEach(d1=>{
      //       this.memoTemp.push({
      //         id:d1.id,
      //         time:d1.remindTime.split(" ")[0],
      //         cont:d1.content
      //       });
      //       this.memo_list.push(d1.remindTime.split(" ")[0]);
      //     });
      //     if(this.refreshStatus){this.$Message.success("操作成功");}
      //     setTimeout(()=>{
      //     this.refreshStatus = false;},1000);
      //   }
      // });
      setTimeout(()=>{
        this.initCalendar(null);
      },2000);
    },
    initCalendar: function(cur) {
      let date = "";
      if (cur) {
        date = new Date(cur);
      } else {
        date = new Date();
        this.pickDay = date.getDate();
      }
      this.currentYear = date.getFullYear();
      this.currentMonth = date.getMonth() + 1;
      var str = this.formatDate(this.currentYear, this.currentMonth, 1);
      this.currentWeek =
        new Date(str).getDay() == 0 ? 7 : new Date(str).getDay();

    
      var hour=date.getHours();
      var minute=date.getMinutes();
      var second=date.getSeconds();
      var date1 = this.currentYear+"-"+this.currentMonth+"-"+this.pickDay + " " +hour+":"+minute+":"+second;
      this.remindFormItem.remindTime = new Date(date1);//this.currentYear+"-"+this.currentMonth+"-"+this.pickDay
      this.days.length = 0; //初始化数组
      for (let i = this.currentWeek - 1; i >= 0; i--) {
        let d = new Date(str);
        var memo_type = false;
        d.setDate(d.getDate() - i);
        for (var j = 0; j < this.memo_list.length; j++) {
          let memoTime = new Date(this.memo_list[j]).getTime();
          if (d.getTime() == memoTime) {
            memo_type = true;
          }
        }
        this.days.push({ day: d, memo_type: memo_type });
      }
      for (let i = 1; i <= 35 - this.currentWeek; i++) {
        let d = new Date(str);
        d.setDate(d.getDate() + i);
        let memo_type = false;
        for (let j = 0; j < this.memo_list.length; j++) {
          var memoTime = new Date(this.memo_list[j]).getTime();
          if (d.getTime() == memoTime) {
            memo_type = true;
          }
        }
        this.days.push({ day: d, memo_type: memo_type });
      }

      var curPickDay = this.formatDate(
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate()
      );

      this.getMemo(curPickDay);
    },
    getMemo(date){
      this.currentMemo = [];
      this.memoTemp.forEach(d=>{
        if(d.time==date){
          this.currentMemo.push(d);
        }
      });
    },
    pick: function(date) {
      this.pickDay = date.getDate();
      this.initCalendar(
        this.formatDate(date.getFullYear(), date.getMonth() + 1, 1)
      );
      var curPickDay = this.formatDate(
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate()
      );

      this.getMemo(curPickDay);
      this.$emit("input",curPickDay)
    },
    pickPre: function(year, month) {
      // setDate(0); 上月最后一天
      // setDate(-1); 上月倒数第二天
      // setDate(dx) 参数dx为 上月最后一天的前后dx天
      var d = new Date(this.formatDate(year, month, 1));
      d.setDate(0);
      this.initCalendar(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
    },
    pickNext: function(year, month) {
      var d = new Date(this.formatDate(year, month, 1));
      d.setDate(35);
      this.initCalendar(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
    },
    // 返回 类似 2016-01-02 格式的字符串
    formatDate: function(year, month, day) {
      var y = year;
      var m = month;
      if (m < 10) m = "0" + m;
      var d = day;
      if (d < 10) d = "0" + d;
      return y + "-" + m + "-" + d;
    },
    remindOk() {
        if(this.remindFormItem.remindTime && this.remindFormItem.content){
        this.remindFormItem.state = 0;
        this.remindFormItem.remindTime = utils.formatTime(this.remindFormItem.remindTime);
        this.addremindme(this.remindFormItem).then(d=>{
            if(d.code==0){
                this.$Message.success("操作成功");
                this.loadData();
            }
        });
        this.addremindstate=false;
      }else{
        this.$Message.error("备忘录时间和内容不能为空");
        return false;
      }
    },
    removeRemindOk(){
      let ids = [];
      ids.push(this.remindFormItem1.id);
      this.delremindme(ids).then(d=>{
        if(d.code==0){
          this.$Message.success("操作成功");
          this.loadData();
          // //删除currentMemo、memo_list
          // this.currentMemo.forEach((d1,i)=>{
          //   if(d1.time==this.remindFormItem1.remindTime){
          //     this.currentMemo.splice(i,1);
          //   }
          // });
          // this.memo_list.forEach((d1,i)=>{
          //   if(d1==this.remindFormItem1.remindTime){
          //     this.memo_list.splice(i,1);
          //   }
          // });
        }
        this.remindstate = false;
      });
    },
    showRemind(d){
      this.remindFormItem1.id=d.id;
      this.remindFormItem1.remindTime=d.time;
      this.remindFormItem1.content=d.cont;
      this.remindstate=true;
    }
  },
  watch:{
    "comms.remindmeview.state":function(){
      let id = this.comms.remindmeview.id;
      if(id){
        this.getremindmedetail({id:id}).then(d=>{
          if(d.code==0){
            let _d = d.datas;
            this.remindFormItem1.id=_d.id;
            this.remindFormItem1.remindTime=_d.remindTime;
            this.remindFormItem1.content=_d.content;
            this.remindstate = true;
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>
