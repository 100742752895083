<style lang="scss" scoped>
.formbtnflex,.formbtnfixed{
  background-color:#f5f5f5;
  border-top:1px solid #ddd;
  padding:5px;
  box-sizing: border-box;
  width:100%;
  z-index:998;
  text-align:right;
}
.formbtnfixed{
  position:absolute;
  bottom:0px;
  left:0px;
}
.formbtn{
  display:"inline-block";
}
//  :style="{'display':'inline-block','float':align?align:'left'}"
</style>
<template>
    <div :class="position=='fixed'?'formbtnfixed':(position=='flex'?'formbtnflex':'formbtn')">
        <Button :type="view?'info':'default'" @click="carray" icon="ios-undo" v-if="goback">{{view?'返回':'取消'}}</Button>
        <Button v-if="!view" :disabled="okdisabled" style="margin-left: 8px;" type="primary" @click="submit" :loading="loading" icon="ios-send">
            <span v-if="!loading">{{submittxt || '发布'}}</span>
            <span v-else>发布中...</span>
        </Button>
        <Button v-if="!view && submitagin==true" :disabled="okdisabled" style="margin-left: 8px;" type="warning" @click="submit('agin')" :loading="loading" icon="ios-send">
            <span v-if="!loading">发布并继续</span>
            <span v-else>发布中...</span>
        </Button>
    </div>
</template>

<script>
// import {mapGetters,mapActions} from 'vuex'
export default {
  name: 'FormBtn',
  props:['goback','submit','loading','view','okdisabled','carryparams','align','submitagin','submittxt','position'],
  data () {
    return {
        
    }
  },
  computed:{
    // ...mapGetters([
    //     'user'
    // ])
  },
  mounted(){
  },
  methods:{
    // ...mapActions([
    // ]),
    carray(){
      let _query = this.$route.query;
      let _new = {};
      if(this.carryparams && this.carryparams.length>0){
        for(let key in _query){
          this.carryparams.forEach(v=>{
            if(v==key){
              _new[key] = _query[key];
            }
          });
        }
      }

      this.$router.push({path:this.goback,query:_new})
    }
  },
  watch:{
   
  }
}
</script>

